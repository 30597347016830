export const PlumbLevelNegative = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
  <line x1="22.88" y1="26.39" x2="22.88" y2="3.5" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width="1.09"/>
  <g>
    <line x1="22.88" y1="26.39" x2="22.66" y2="26.15" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width=".44"/>
    <line x1="22.38" y1="25.83" x2="3.94" y2="5.31" fill="none" stroke="#231f20" stroke-dasharray="0 0 .64 .43" stroke-miterlimit="10" stroke-width=".44"/>
    <line x1="3.79" y1="5.15" x2="3.58" y2="4.91" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width=".44"/>
  </g>
  <g>
    <path d="m10.47,6.99c1.49-1.19,3.81-2.44,6.68-2.08,2.77.34,3.77,2.35,3.77,2.35" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width=".44"/>
    <path d="m8.61,8.85c1.21-.53,2.86-1.04,4.12-1.11l-1.98-.97-.91-2.01c-.11,1.26-.66,2.89-1.23,4.08Z" fill="#231f20"/>
  </g>
</svg>`;

export const PlumbLevelPositive = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
  <line x1="6.74" y1="26.5" x2="6.74" y2="3.61" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width="1.09"/>
  <g>
    <line x1="6.74" y1="26.5" x2="6.96" y2="26.26" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width=".44"/>
    <line x1="7.24" y1="25.94" x2="25.95" y2="5.48" fill="none" stroke="#231f20" stroke-dasharray="0 0 .65 .43" stroke-miterlimit="10" stroke-width=".44"/>
    <line x1="26.1" y1="5.32" x2="26.31" y2="5.08" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width=".44"/>
  </g>
  <g>
    <path d="m19.08,6.69c-1.59-1.06-4-2.11-6.84-1.51-2.73.58-3.56,2.66-3.56,2.66" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width=".44"/>
    <path d="m21.09,8.39c-.67-1.14-1.36-2.72-1.57-3.97l-.73,2.08-1.89,1.13c1.26-.04,2.95.33,4.2.75Z" fill="#231f20"/>
  </g>
</svg>`;
