import classNames from 'classnames';
import React from 'react';

import Icon from './Icon';

type StandardModalProps = {
  onClose: () => void;
  children: React.ReactNode;
  headerIcon?: string;
  headerText?: string;
  extraClassNames?: string;
};

const StandardModal: React.FC<StandardModalProps> = ({
  onClose,
  children,
  headerIcon,
  headerText,
  extraClassNames
}): JSX.Element => {
  return (
    <div
      className={classNames(
        extraClassNames,
        'standard-modal page-overlay flex align-center justify-center'
      )}
    >
      <dialog className="modal" open>
        <header className="flex justify-sb align-center">
          <div className="header-left flex align-center">
            {headerIcon && <Icon className="header-icon" name={headerIcon}></Icon>}
            {headerText && <h3>{headerText}</h3>}
          </div>

          <Icon name="x" className="close-icon" onClick={onClose}></Icon>
        </header>

        <div className="modal-content">{children}</div>
      </dialog>
    </div>
  );
};

export default StandardModal;
