/* eslint-disable max-len */
import {
  DimensionQuestionDefinition,
  MeasurementFormDefinition,
  MeasurementForms,
  MeasurementFormValues,
  OutOfPlumbOrLevelType,
  ShapeQuestionDefinition
} from '../definitions/measurement-form-models';
import { ComponentStates, SessionAccessory } from '../definitions/view-models';
import ApiGateway from './ApiGateway';
import ComponentConditionalManager from './ComponentConditionalManager';
import ModelUtilityService from './ModelUtilityService';

import {
  ComponentState,
  ICategory,
  IComponentConditional,
  IModel,
  sortByName,
  sortBySortOrder
} from '@ml/common';

class MeasurementService {
  GetMeasurementFormDefinitionsForModel(model: IModel): Array<MeasurementFormDefinition> {
    const formDefs: Array<MeasurementFormDefinition> = [];

    const shapeCat = ModelUtilityService.GetCategoryByName(model, this.ShapeCategoryName);
    if (shapeCat) {
      const modelDirectory = ModelUtilityService.GetPathToModelDirectory(model);
      const shapeQuestion = new ShapeQuestionDefinition({
        options: shapeCat.Components.sort(sortBySortOrder).map(c => ({
          name: c.Name,
          imageUrl: `${modelDirectory}/${c.PreviewFilename}`
        })),
        defaultValue: shapeCat.Components[0].Name
      });
      formDefs.push(shapeQuestion);
    }
    // Clean up after release

    const formForSku = this.forms?.find(f => f.sku === model?.Sku);

    if (formForSku)
      formDefs.push(...formForSku.formDefinitions.map(d => new DimensionQuestionDefinition(d)));

    return formDefs;
  }

  async GetUpdatedModelData(
    formValues: { [k: string]: string | number },
    modelId: number,
    componentStates: ComponentStates,
    sessionAccessories: SessionAccessory[]
  ) {
    // get fresh copy of model with original accessories since some may have been removed
    const originalModel = await ApiGateway.GetModelById(modelId);
    let model = structuredClone(originalModel) as IModel;

    const updatedComponentStates = await this.GetUpdatedComponentStates(
      formValues,
      model,
      componentStates
    );

    const updatedSessionAccessories = this.GetUpdatedSessionAccessories(
      formValues,
      model,
      sessionAccessories
    );

    model = await this.GetUpdatedModel(formValues, model, updatedComponentStates);

    return {
      model: model,
      sessionAccessories: updatedSessionAccessories,
      componentStates: updatedComponentStates
    };
  }

  GetUpdatedSessionAccessories(
    formValues: { [k: string]: string | number },
    model: IModel,
    currentSessionAccessories: SessionAccessory[]
  ): SessionAccessory[] {
    // Support Bar
    let updatedAccessories = this.addOrRemoveAccessorySupportBar(
      formValues,
      model,
      currentSessionAccessories
    );

    // Custom Door Width
    updatedAccessories = this.addOrRemoveCustomDoorWidth(formValues, model, updatedAccessories);

    return updatedAccessories;
  }

  private addOrRemoveCustomDoorWidth(
    formValues: { [k: string]: string | number },
    model: IModel,
    currentSessionAccessories: SessionAccessory[]
  ): SessionAccessory[] {
    const customDoorWidthAccessory = ModelUtilityService.GetAccessoryByName(
      this.CustomDoorWidthAccessoryName,
      model
    );

    if (!customDoorWidthAccessory) return currentSessionAccessories;

    const doorWidth = formValues['DoorWidth'] as number;

    if (doorWidth !== this.StandardDoorWidth) {
      if (
        !currentSessionAccessories.find(a => a.AccessoryId === customDoorWidthAccessory.AccessoryId)
      )
        currentSessionAccessories.push(
          SessionAccessory.FromAccessory(customDoorWidthAccessory, true)
        );
    } else {
      currentSessionAccessories = currentSessionAccessories.filter(
        a => a.AccessoryId !== customDoorWidthAccessory.AccessoryId
      );
    }
    return currentSessionAccessories;
  }

  //TODO: Remove if no longer used by FGI.
  private addOrRemoveAccessorySupportBar(
    formValues: { [k: string]: string | number },
    model: IModel,
    currentSessionAccessories: SessionAccessory[]
  ): SessionAccessory[] {
    const supportBarAccessory = ModelUtilityService.GetAccessoryByName(
      this.SupportBarAccessoryName,
      model
    );

    if (!supportBarAccessory) return currentSessionAccessories;

    const height = formValues['Height'] as number;
    const topOpeningWidth = formValues['TopOpeningWidth'] as number;
    const bottomOpeningWidth = formValues['BottomOpeningWidth'] as number;
    const ninetyDegreeReturnPanelWidth = formValues['NinetyDegreeReturnPanelWidth'] as number;
    const rightSidePanel = formValues['RightSidePanel'] as number;
    const leftSidePanel = formValues['LeftSidePanel'] as number;

    if (
      (topOpeningWidth >= 40 ||
        bottomOpeningWidth >= 40 ||
        ninetyDegreeReturnPanelWidth >= 40 ||
        rightSidePanel >= 40 ||
        leftSidePanel >= 40) &&
      height >= 80
    ) {
      if (!currentSessionAccessories.find(a => a.AccessoryId === supportBarAccessory.AccessoryId))
        currentSessionAccessories.push(SessionAccessory.FromAccessory(supportBarAccessory));
    } else {
      currentSessionAccessories = currentSessionAccessories.filter(
        a => a.AccessoryId !== supportBarAccessory.AccessoryId
      );
    }
    return currentSessionAccessories;
  }

  async GetUpdatedComponentStates(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ): Promise<ComponentStates> {
    // Clear any rules that may have previously been programmatically created based on measurements
    model.ComponentConditionals = model.ComponentConditionals.filter(
      c => c.ComponentConditionalId !== -1
    );

    // Shape
    let newStates = await this.SelectShapeComponent(formValues, model, componentStates);

    // Size
    newStates = await this.SelectSizeComponent(formValues, model, newStates);

    // Clips
    newStates = this.HideExtraClipsComponents(formValues, model, newStates);

    // Combo handle with Towel Bar
    newStates = this.HideExtraComboTowelBarComponents(formValues, model, newStates);

    //Support Bar
    newStates = await this.ConditionallySetComponentsByFormValues(formValues, model, newStates);

    // 1/2” Glass
    newStates = await this.DisableNonHalfInchGlassComponentsIfRequired(
      formValues,
      model,
      newStates
    );

    // Set support bar here
    return newStates;
  }

  // Using Conditional Rules in case future enhancements are needed for more conditional options
  // based on measurement form values.
  async ConditionallySetComponentsByFormValues(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ): Promise<ComponentStates> {
    let defs = this.forms.find(x => x.sku === model.Sku)?.formDefinitions;

    const allModelCats = ModelUtilityService.GetAllCategories(model);
    // All Categories that match the form value names
    // A bit overkill now but would allow to easily add more form conditionals

    const formConditionalCategories = defs
      .map(d => {
        const effCat = allModelCats.find(cat => {
          if (cat.Name.toLowerCase().includes('_rule')) {
            const splitCatName = cat.Name.split('_');
            return splitCatName[0] === d.name ? true : false;
          }
          return false;
        });
        if (effCat) return effCat;
      })
      .filter(x => !!x);
    const compsToTurnOn = [];

    if (formConditionalCategories.length) {
      formConditionalCategories.forEach(cat => {
        // Verify there are only 2 components in the form conditional category one On and one Off
        if (cat.Components.length === 2) {
          const catName = cat.Name.split('_')[0];
          const onComp = cat.Components.find(comp => comp.Name === catName);
          const offComp = cat.Components.find(comp => comp.Name === `${catName}Off`);
          // Check that both comps are found with the proper naming
          if (onComp && offComp) {
            const splitCatName = cat.Name.split('_');
            const breakPoint =
              +splitCatName.at(-1) > 0 ? +splitCatName.at(-1) : this.FormValueBreakpoint;
            // Check condition here.  Right now hard coded to 64 but could be made dynamic in the future
            if ((formValues[catName] as number) >= breakPoint) {
              compsToTurnOn.push(onComp);
            } else {
              compsToTurnOn.push(offComp);
            }
          }
        }
      });
    }

    // Check to see if there are any comps to turn on
    if (compsToTurnOn.length) {
      return await ComponentConditionalManager.SelectMultipleComponents(
        compsToTurnOn,
        model,
        componentStates
      );
    }

    return componentStates;
  }

  private async SelectShapeComponent(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ) {
    const shapeName = formValues['Shape'] as string;
    const selectedShapeComponent = ModelUtilityService.GetComponentByName(shapeName, model);
    const shapeCategory = ModelUtilityService.GetCategoryByName(model, this.ShapeCategoryName);

    if (!shapeName || !selectedShapeComponent || !shapeCategory) return componentStates;

    const componentIdsToDisable = shapeCategory.Components.filter(
      c => c.ComponentId !== selectedShapeComponent.ComponentId
    ).map(c => c.ComponentId);

    const rulesToDisableComponents: IComponentConditional[] = componentIdsToDisable.map(
      componentId => ({
        ComponentConditionalId: -1,
        ModelId: model.ModelId,
        Target: {
          ComponentConditionalPartId: -1,
          ComponentConditionalId: -1,
          ComponentId: componentId,
          State: ComponentState.DISABLED
        },
        Sources: []
      })
    );

    model.ComponentConditionals.push(...rulesToDisableComponents);

    const newStates = await ComponentConditionalManager.SelectComponent(
      selectedShapeComponent,
      model,
      componentStates
    );

    return newStates;
  }

  private async SelectSizeComponent(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ) {
    const sizeCategory = ModelUtilityService.GetCategoryByName(model, this.SizeCategoryName);
    if (!sizeCategory) return componentStates;

    let size: string = this.getSizeForMeasurements(model, formValues);

    if (!size) {
      console.log(`Unable to find size for ${model.Sku}:`, formValues);
      return componentStates;
    }

    const selectedSizeComponent = ModelUtilityService.GetComponentByName(size, model);

    if (!selectedSizeComponent) return componentStates;

    const componentIdsToDisable = sizeCategory.Components.filter(
      c => c.ComponentId !== selectedSizeComponent.ComponentId
    ).map(c => c.ComponentId);

    const rulesToDisableComponents: IComponentConditional[] = componentIdsToDisable.map(
      componentId => ({
        ComponentConditionalId: -1,
        ModelId: model.ModelId,
        Target: {
          ComponentConditionalPartId: -1,
          ComponentConditionalId: -1,
          ComponentId: componentId,
          State: ComponentState.DISABLED
        },
        Sources: []
      })
    );

    model.ComponentConditionals.push(...rulesToDisableComponents);

    const newStates = await ComponentConditionalManager.SelectComponent(
      selectedSizeComponent,
      model,
      componentStates
    );

    return newStates;
  }

  private getSizeForMeasurements(model: IModel, formValues: { [k: string]: string | number }) {
    let size: string;

    let height = formValues['Height'] as number;
    let topOpeningWidth = formValues['TopOpeningWidth'] as number;
    let bottomOpeningWidth = formValues['BottomOpeningWidth'] as number;
    let doorWidth = formValues['DoorWidth'] as number;
    let ninetyDegreeReturnPanelWidth = formValues['NinetyDegreeReturnPanelWidth'] as number;
    let doorSideOverallOpeningWidth = formValues['DoorSideOverallOpeningWidth'] as number;
    const shape = formValues['Shape'] as string;

    // Frameless Swing Shower Door
    if (model.Sku === 'MRCFLSD') {
      height = formValues['LeftHeight'] as number;
      topOpeningWidth = formValues['TopWidth'] as number;
      bottomOpeningWidth = formValues['BottomWidth'] as number;

      if (height <= 72 && topOpeningWidth <= 28 && bottomOpeningWidth <= 28) size = '1';
      else if (height <= 80 && topOpeningWidth <= 28 && bottomOpeningWidth <= 28) size = '2';
      else if (height <= 72 && topOpeningWidth <= 36 && bottomOpeningWidth <= 36) size = '3';
      else size = '4';
    }
    // Frameless Swing Door with Return Panel
    else if (model.Sku === 'MRCSDRP') {
      height = formValues['LeftDoorHeight'] as number;
      ninetyDegreeReturnPanelWidth = formValues['NinetyDegreeReturnBottomPanelWidth'] as number;
      doorWidth = formValues['BottomDoorWidth'] as number;

      if (
        height <= 72 &&
        ninetyDegreeReturnPanelWidth <= 48 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '1';
      else size = '2';
    }
    // Frameless Swing Door with Inline Panel
    else if (model.Sku === 'MRCSDNP') {
      height = formValues['Height'] as number;
      topOpeningWidth = formValues['TopWidth'] as number;
      bottomOpeningWidth = formValues['BottomWidth'] as number;
      doorWidth = formValues['DoorWidth'] as number;
      if (shape.toLowerCase().includes('no notch')) {
        doorWidth = bottomOpeningWidth;
      }

      if (
        height <= 74 &&
        topOpeningWidth <= 48 &&
        bottomOpeningWidth <= 48 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '1';
      else if (
        height <= 78 &&
        topOpeningWidth <= 48 &&
        bottomOpeningWidth <= 48 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '2';
      else if (
        height <= 74 &&
        topOpeningWidth <= 60 &&
        bottomOpeningWidth <= 60 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '3';
      else if (
        height <= 78 &&
        topOpeningWidth <= 60 &&
        bottomOpeningWidth <= 60 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '4';
      else size = 'X';
    }
    // Frameless Swing Door with Inline Panel and 90° Return Panel
    else if (model.Sku === 'MRCDNPR') {
      height = formValues['Height'] as number;
      ninetyDegreeReturnPanelWidth = formValues['NinetyDegreeReturnPanelBottomWidth'] as number;
      doorSideOverallOpeningWidth = formValues['BottomWidth'] as number;
      doorWidth = formValues['DoorWidth'] as number;

      if (
        height <= 74 &&
        ninetyDegreeReturnPanelWidth <= 48 &&
        doorSideOverallOpeningWidth <= 48 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '1';
      else if (
        height <= 78 &&
        ninetyDegreeReturnPanelWidth <= 48 &&
        doorSideOverallOpeningWidth <= 48 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '2';
      else if (
        height <= 74 &&
        ninetyDegreeReturnPanelWidth <= 48 &&
        doorSideOverallOpeningWidth <= 60 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '3';
      else if (
        height <= 78 &&
        ninetyDegreeReturnPanelWidth <= 48 &&
        doorSideOverallOpeningWidth <= 60 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '4';
      else size = 'X';
    }
    // Frameless Panel-Door-Panel
    else if (model.Sku === 'MRCPDNP') {
      if (
        (formValues['Shape'] as string).toLowerCase().includes('left') ||
        (formValues['Shape'] as string).toLowerCase().startsWith('nonotch')
      ) {
        height = formValues['RightPanelHeight'] as number;
      } else {
        height = formValues['LeftPanelHeight'] as number;
      }
      topOpeningWidth = formValues['TopWidth'] as number;
      bottomOpeningWidth = formValues['BottomWidth'] as number;
      doorWidth = formValues['DoorWidth'] as number;

      if (
        height <= 74 &&
        topOpeningWidth <= 60 &&
        bottomOpeningWidth <= 60 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '1';
      else if (
        height <= 78 &&
        topOpeningWidth <= 60 &&
        bottomOpeningWidth <= 60 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '2';
      else if (
        height <= 74 &&
        topOpeningWidth <= 84 &&
        bottomOpeningWidth <= 84 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '3';
      else if (
        height <= 78 &&
        topOpeningWidth <= 84 &&
        bottomOpeningWidth <= 84 &&
        doorWidth === this.StandardDoorWidth
      )
        size = '4';
      else size = 'X';
    }
    // Frameless Neo Angle Door
    else if (model.Sku === 'MRCNEO') {
      // Change to scoped vars before release
      if ((formValues['Shape'] as string).toLowerCase().includes('no cut out')) {
        height = +formValues['LeftPanelHeight'];
      } else {
        height = +formValues['LeftOverallHeight'] || +formValues['RightOverallHeight'];
      }

      if (height <= 72) size = '72';
      else size = '80';
    }
    // Frameless French Door
    else if (model.Sku === 'MRCFRND') {
      height = formValues['LeftDoorHeight'] as number;

      if (height <= 72) size = '1';
      else size = '2';
    }
    // Frameless Ultra Sliding Door with Inline Panel
    else if (model.Sku === 'MRCULTR') {
      if ((formValues['Shape'] as string).toLowerCase().startsWith('return')) {
        height = formValues['RightHeight'] as number;
      } else {
        height = formValues['LeftHeight'] as number;
      }
      topOpeningWidth = formValues['TopWidth'] as number;
      bottomOpeningWidth = formValues['BottomWidth'] as number;
      ninetyDegreeReturnPanelWidth = formValues['NinetyDegreeReturnBottomPanelWidth'] as number;

      if (
        height <= 76 &&
        topOpeningWidth <= 60 &&
        bottomOpeningWidth <= 60 &&
        ninetyDegreeReturnPanelWidth === 0
      )
        size = '1';
      else if (
        height <= 76 &&
        topOpeningWidth <= 84 &&
        bottomOpeningWidth <= 84 &&
        ninetyDegreeReturnPanelWidth === 0
      )
        size = '2';
      else if (
        height <= 76 &&
        topOpeningWidth <= 60 &&
        bottomOpeningWidth <= 60 &&
        ninetyDegreeReturnPanelWidth <= 48
      )
        size = '3';
      else if (
        height <= 76 &&
        topOpeningWidth <= 84 &&
        bottomOpeningWidth <= 84 &&
        ninetyDegreeReturnPanelWidth <= 48
      )
        size = '4';
      else size = 'X';
    }

    return size;
  }

  private async DisableNonHalfInchGlassComponentsIfRequired(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ) {
    let height = formValues['Height'] as number;
    if (!height) height = +formValues['LeftOverallHeight'] || +formValues['RightOverallHeight'];
    const glassCategory = this.GetGlassCategory(model);
    const halfInchComponent = this.GetHalfInchGlassComponent(glassCategory);

    if (!height || height <= 80 || !glassCategory || !halfInchComponent) return componentStates;

    const componentIdsToDisable = glassCategory.Components.filter(
      c => c.ComponentId !== halfInchComponent.ComponentId
    ).map(c => c.ComponentId);

    const rulesToDisableComponents: IComponentConditional[] = componentIdsToDisable.map(
      componentId => ({
        ComponentConditionalId: -1,
        ModelId: model.ModelId,
        Target: {
          ComponentConditionalPartId: -1,
          ComponentConditionalId: -1,
          ComponentId: componentId,
          State: ComponentState.DISABLED
        },
        Sources: []
      })
    );

    model.ComponentConditionals.push(...rulesToDisableComponents);

    let newStates = await ComponentConditionalManager.SelectComponent(
      halfInchComponent,
      model,
      componentStates
    );

    return newStates;
  }

  private HideExtraComboTowelBarComponents(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ) {
    let doorWidth = formValues['DoorWidth'] as number;
    const handleCategory = ModelUtilityService.GetCategoryByName(model, this.HandleCategoryName);

    const comboBarComponents = handleCategory?.Components.filter(c =>
      c.Name.toLowerCase().includes(this.ComboTowelBarComponentPrefix)
    );

    if (!handleCategory || !comboBarComponents.length) return componentStates;

    if (!doorWidth) {
      const topOpeningWidth = formValues['TopOpeningWidth'] as number;
      const bottomOpeningWidth = formValues['BottomOpeningWidth'] as number;

      if (topOpeningWidth) doorWidth = topOpeningWidth;
      if (bottomOpeningWidth && bottomOpeningWidth < topOpeningWidth)
        doorWidth = bottomOpeningWidth;
    }

    if (!doorWidth || !handleCategory || !comboBarComponents.length) return componentStates;

    const componentsAndWidths = comboBarComponents
      .sort(sortByName)
      .reverse()
      .map(c => ({ Width: +/\d+/.exec(c.Name), Component: c }));

    const maxSizeThatFits = doorWidth - 6;
    const uniqueWidths = componentsAndWidths
      .map(c => c.Width)
      .filter((x, i, a) => a.indexOf(x) == i);
    const twoWidthsThatFit = uniqueWidths.filter(width => width <= maxSizeThatFits).slice(0, 2);
    const componentIdsToDisable = componentsAndWidths
      .filter(c => !twoWidthsThatFit.includes(c.Width))
      .map(c => c.Component.ComponentId);

    const rulesToDisableComponents: IComponentConditional[] = componentIdsToDisable.map(
      componentId => ({
        ComponentConditionalId: -1,
        ModelId: model.ModelId,
        Target: {
          ComponentConditionalPartId: -1,
          ComponentConditionalId: -1,
          ComponentId: componentId,
          State: ComponentState.HIDDENINMENU
        },
        Sources: []
      })
    );

    model.ComponentConditionals.push(...rulesToDisableComponents);

    // rerun the rules
    let newStates = ComponentConditionalManager.ProcessNewStates(
      componentStates?.SelectedIds,
      model
    );

    return newStates;
  }

  private HideExtraClipsComponents(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ) {
    const shapeName = formValues['Shape'] as string;
    const clipsCategory = ModelUtilityService.GetCategoryByName(model, this.ClipsCategoryName);

    const clipsComponents = clipsCategory?.Components.filter(c =>
      c.Name.toLowerCase().includes('clip')
    );

    if (!shapeName || !clipsCategory || !clipsComponents.length) return componentStates;

    const componentsAndClipAmounts = clipsComponents.map(c => ({
      Clips: +/\d+/.exec(c.Name),
      Component: c
    }));

    const amountOfClipsNeeded = this.getAmountOfClipsForShape(model.Sku, shapeName);

    const componentIdsToDisable = componentsAndClipAmounts
      .filter(c => c.Clips !== amountOfClipsNeeded)
      .map(c => c.Component.ComponentId);

    const rulesToDisableComponents: IComponentConditional[] = componentIdsToDisable.map(
      componentId => ({
        ComponentConditionalId: -1,
        ModelId: model.ModelId,
        Target: {
          ComponentConditionalPartId: -1,
          ComponentConditionalId: -1,
          ComponentId: componentId,
          State: ComponentState.HIDDENINMENU
        },
        Sources: []
      })
    );

    model.ComponentConditionals.push(...rulesToDisableComponents);

    // rerun the rules
    let newStates = ComponentConditionalManager.ProcessNewStates(
      componentStates?.SelectedIds,
      model
    );

    return newStates;
  }

  private getAmountOfClipsForShape(sku: string, shape: string): number {
    let clips = 0;

    // Frameless Swing Shower Door
    if (sku === 'MRCFLSD') {
      clips = 0;
    }
    // Frameless Swing Door with Return Panel
    else if (sku === 'MRCSDRP') {
      clips = 4;
    }
    // Frameless Swing Door with Inline Panel
    else if (sku === 'MRCSDNP') {
      if (shape.toLowerCase().includes('notch')) clips = 8;
      else clips = 2;
    }
    // Frameless Swing Door with Inline Panel and 90° Return Panel
    else if (sku === 'MRCDNPR') {
      if (shape.toLowerCase().includes('notched panel')) clips = 10;
      else clips = 6;
    }
    // Frameless Panel-Door-Panel
    else if (sku === 'MRCPDNP') {
      if (shape.toLowerCase().includes('notch on both sides')) clips = 16;
      else if (shape.toLowerCase().includes('notch')) clips = 12;
      else clips = 8;
    }
    // Frameless Neo Angle Door
    else if (sku === 'MRCNEO') {
      if (shape.toLowerCase().includes('notch on both sides')) clips = 16;
      else if (shape.toLowerCase().includes('notch')) clips = 12;
      else clips = 8;
    }
    // Frameless French Door
    else if (sku === 'MRCFRND') {
      clips = 0;
    }
    // Frameless Ultra Sliding Door with Inline Panel
    else if (sku === 'MRCULTR') {
      if (shape.toLowerCase().includes('notched return')) clips = 10;
      else if (shape.toLowerCase().includes('no notch')) clips = 5;
      else clips = 2;
    }
    return clips;
  }

  private splitNumberIntoWholeandFractionValues(value: number): {
    formValue: number;
    fractionValue: number;
  } {
    const hasFraction = value % 1 !== 0;
    const formValue = hasFraction ? Math.floor(value) : value;
    const fractionValue = hasFraction ? value % 1 : 0;
    return { formValue, fractionValue };
  }

  async GetUpdatedModel(
    formValues: { [k: string]: string | number },
    model: IModel,
    componentStates: ComponentStates
  ): Promise<IModel> {
    // TODO - move this into FgiComponentPricingManager?
    let updatedModel = await ModelUtilityService.ApplyVariablePricing(
      model,
      componentStates?.SelectedIds
    );

    return updatedModel;
  }

  public GetTooltipTextForDisabledComponent(
    componentId: number,
    formValues: { [k: string]: string | number },
    model: IModel
  ): string[] {
    if (!formValues) return [];

    const height = formValues['Height'] as number;
    const glassCategory = this.GetGlassCategory(model);
    const halfInchComponent = this.GetHalfInchGlassComponent(glassCategory);

    if (
      !height ||
      height <= 80 ||
      !glassCategory ||
      !halfInchComponent ||
      !glassCategory.Components.find(c => c.ComponentId === componentId)
    )
      return [];

    return ['Height over 80"'];
  }

  public GetGlassCategory(model: IModel) {
    return ModelUtilityService.GetCategoryByName(model, this.GlassCategoryName);
  }

  public GetHalfInchGlassComponent(glassCategory: ICategory) {
    return glassCategory?.Components.find(c => c.Name.includes('½') || c.Name.includes('1/2'));
  }

  public GetSizeCategory(model: IModel) {
    return ModelUtilityService.GetCategoryByName(model, this.SizeCategoryName);
  }

  readonly SupportBarAccessoryName = 'Support Bar';
  readonly HeaderCategoryName = 'Header';
  readonly CustomDoorWidthAccessoryName = 'Custom Door Width';
  readonly StandardDoorWidth = 28;
  readonly FormValueBreakpoint = 64;
  readonly ComboTowelBarComponentPrefix = 'combo handle with towel bar';

  readonly ShapeCategoryName = 'Shape';
  readonly SizeCategoryName = 'Size';
  readonly GlassCategoryName = 'Glass';
  readonly HandleCategoryName = 'Handle';
  readonly ClipsCategoryName = 'Clips or U-channel';

  readonly TooltipForHeight = 'Measure for height and check if your base is level';
  readonly TooltipForWidth =
    'Measure top/bottom widths and check if your walls are plumb using a level';
  readonly TooltipForReturnPanelHeight =
    'Measurement calculated based off of Overall Height and Notch Height. Adjust Overall Height and/or Notch Height to change value.';

  readonly forms: MeasurementForms = [
    // Frameless Swing Shower Door
    {
      sku: 'MRCFLSD', //Model 20377 //Done // Done UAT
      formDefinitions: [
        {
          name: 'LeftHeight',
          getLabel: (shape: string) => 'Left Height',
          defaultValue: 72,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => 'A',
          isDimensionOnTheLeft: shape => {
            return true;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];
            const dynmaicMaxRef = 36;

            if (refVal + plumbLevelVal >= dynmaicMaxRef) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          defaultValue: 20,
          minValue: 20,
          maxValue: 36,
          isHidden: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicPlumbLevelDependents: () => ['LeftHeight', 'RightHeight'],
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return 36;
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) => 'Bottom Width',
          defaultValue: 20,
          minValue: 20,
          maxValue: 36,
          tooltip: this.TooltipForWidth,
          getDimensionIdentityLabel: (shape: string) => 'B',
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          flipNegPosPlumbLevel: (shape: string) => false,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return 36;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['LeftHeight'] + +formState.fractionValues['LeftHeight'];

            if (refVal + plumbLevelVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'RightHeight',
          getLabel: (shape: string) => 'Right Height',
          defaultValue: 0,
          maxValue: 95,
          isDisabled: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicPlumbLevelDependents: () => ['BottomWidth'],
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: () => false,
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['LeftHeight'] + +formState.fractionValues['LeftHeight'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'C',
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];
            const dynmaicMaxRef = 36;

            if (refVal + plumbLevelVal >= dynmaicMaxRef) return true;
            return false;
          }
        }
      ]
    },
    // Frameless Swing Door with Return Panel
    {
      sku: 'MRCSDRP', //Model 20385 //Done //Done UAT
      formDefinitions: [
        {
          name: 'Height',
          getLabel: (shape: string) => 'Height',
          defaultValue: 72,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'A',
          flipNegPosPlumbLevel: (shape: string) => false,

          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: shape => (shape.toLowerCase().startsWith('door') ? true : false),
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopDoorWidth'] + +formState.fractionValues['TopDoorWidth'];
            const dynmaicMaxRef = +formState.formValues['Height'] >= 80 ? 28.875 : 36;

            if (refVal + plumbLevelVal >= dynmaicMaxRef) return true;
            return false;
          }
        },
        {
          name: 'BottomDoorWidth',
          getLabel: (shape: string) => 'Bottom Door Width',
          defaultValue: this.StandardDoorWidth,
          maxValue: 36,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'B',
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            return +formState.formValues['Height'] >= 80 ? 28.875 : 36;
          },
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('door') ? false : true,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (refVal + refPlVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'NinetyDegreeReturnBottomPanelWidth',
          getLabel: (shape: string) => '90° Return Bottom Panel Width',
          defaultValue: 48,
          maxValue: 60,
          getDimensionIdentityLabel: (shape: string) => 'C',
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          flipNegPosPlumbLevel: (shape: string) => {
            return shape.toLowerCase().startsWith('door') ? false : true;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (refVal + refPlVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'ReturnPanelHeight',
          getLabel: (shape: string) => 'Return Panel Height',
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'D',
          isDisabled: (shape: string) => (shape.toLowerCase().startsWith('door') ? true : false),
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            let value = +formState.formValues['Height'] + +formState.fractionValues['Height'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          flipNegPosPlumbLevel: (shape: string) => false,
          dynamicPlumbLevelDependents: () => [
            'NinetyDegreeReturnBottomPanelWidth',
            'BottomDoorWidth'
          ],
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: shape => {
            return !shape.toLowerCase().startsWith('door');
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['NinetyDegreeReturnBottomPanelWidth'] +
              +formState.fractionValues['NinetyDegreeReturnBottomPanelWidth'];

            if (refVal + plumbLevelVal >= 60) return true;
            return false;
          }
        },
        {
          name: 'TopDoorWidth',
          getLabel: (shape: string) => 'Top Door Width',
          maxValue: 36,
          isHidden: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['Height'],
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return +formState.formValues['Height'] >= 80 ? 28.875 : 36;
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['BottomDoorWidth'] +
              +formState.fractionValues['BottomDoorWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'NinetyDegreeReturnTopPanelWidth',
          getLabel: (shape: string) => '90° Return Top Panel Width',
          maxValue: 60,
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: () => ['ReturnPanelHeight'],
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['NinetyDegreeReturnBottomPanelWidth'] +
              +formState.fractionValues['NinetyDegreeReturnBottomPanelWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        }
      ]
    },
    // Frameless Swing Door with Inline Panel
    {
      sku: 'MRCSDNP', //Model 20390 //Done // Done UAT
      formDefinitions: [
        {
          name: 'Height',
          getLabel: (shape: string) => 'Height',
          defaultValue: 74,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'A',
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: (shape: string) =>
            shape.toLowerCase().startsWith('door') ? true : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          defaultValue: 36,
          maxValue: 95,
          tooltip: this.TooltipForWidth,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            if (shape.toLowerCase().includes('notch')) {
              value +=
                +formState.formValues['ReturnPanelWidth'] +
                +formState.fractionValues['ReturnPanelWidth'];
            }

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => {
            return ['Height', 'ReturnPanelHeight', 'NotchHeight'];
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) => 'Bottom Width',
          defaultValue: 28,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;

            if (shape.toLowerCase().includes('no notch')) {
              return +formState.formValues['Height'] >= 80 ? 28.875 : 36;
            }

            return (
              95 -
              +formState.formValues['ReturnPanelWidth'] +
              +formState.fractionValues['ReturnPanelWidth']
            );
          },
          tooltip: this.TooltipForWidth,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'B',
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('panel') ? true : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];
            if (
              shape.toLowerCase().startsWith('panel') &&
              shape.toLowerCase().includes('cut out')
            ) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }

            if (refVal + refPlVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'DoorWidth',
          getLabel: (shape: string) => 'Door Width',
          defaultValue: this.StandardDoorWidth,
          maxValue: 36,
          showWhen: (shape: string) => !shape.toLowerCase().includes('no notch'),
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return +formState.formValues['Height'] >= 80 ? 28.875 : 36;
          },
          getDimensionIdentityLabel: (shape: string) => 'C'
        },
        {
          name: 'ReturnPanelHeight',
          getLabel: (shape: string) => {
            return 'Inline Panel Height';
          },
          defaultValue: 74,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => {
            return 'D';
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: (shape: string) =>
            shape.toLowerCase().startsWith('panel') ? true : false,
          dynamicPlumbLevelDependents: (shape: string) => {
            return ['BottomWidth', 'ReturnPanelWidth'];
          },
          isDisabled: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value = 0;
            value = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (shape.toLowerCase().includes('notch')) {
              value -=
                +formState.formValues['NotchHeight'] + +formState.fractionValues['NotchHeight'];
            }
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'NotchHeight',
          getLabel: (shape: string) => {
            if (shape.toLowerCase().includes('left')) return 'Left Notch Height';

            if (shape.toLowerCase().includes('right')) return 'Right Notch Height';

            if (shape.toLowerCase().endsWith('no notch')) {
              if (shape.toLowerCase().startsWith('panel')) return 'Left Notch Height';
              return 'Right Notch Height';
            }
            return 'Notch Height';
          },
          defaultValue: 0,
          maxValue: 95,
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('notch');
          },
          getDimensionIdentityLabel: (shape: string) => 'E',
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          flipNegPosPlumbLevel: (shape: string) => false,
          isDimensionOnTheLeft: (shape: string) =>
            shape.toLowerCase().startsWith('panel') ? true : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 95) return true;
            return false;
          }
        },
        {
          name: 'ReturnPanelWidth',
          getLabel: (shape: string) => {
            if (shape.toLowerCase().includes('left')) return 'Left Panel Width';

            if (shape.toLowerCase().includes('right')) return 'Right Panel Width';

            if (shape.toLowerCase().endsWith('no notch')) {
              if (shape.toLowerCase().startsWith('panel')) return 'Left Panel Width';
              return 'Right Panel Width';
            }
            return 'Notch Width';
          },
          defaultValue: 0,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return (
              95 - (+formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'])
            );
          },
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('notch');
          },
          getDimensionIdentityLabel: (shape: string) => 'F',
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('panel') ? true : false
        }
      ]
    },
    // Frameless Swing Door with Inline Panel and 90° Return Panel
    {
      sku: 'MRCDNPR', //Model 20391 //Done // Done UAT
      formDefinitions: [
        {
          name: 'Height',
          getLabel: (shape: string) => 'Height',
          defaultValue: 74,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => 'A',
          isDimensionOnTheLeft: shape => (shape.toLowerCase().startsWith('return') ? false : true),
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal > 95) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          defaultValue: 0,
          maxValue: 95,
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['Height', 'NotchHeight'],
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            value += +formState.formValues['NotchWidth'] + +formState.fractionValues['NotchWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'DoorWidth',
          getLabel: (shape: string) => 'Door Width',
          defaultValue: this.StandardDoorWidth,
          maxValue: 36,
          getDimensionIdentityLabel: (shape: string) =>
            shape.toLowerCase().includes('no notch') ? 'Z' : 'B',
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return +formState.formValues['Height'] >= 80 ? 28.875 : 36;
          },
          showWhen: (shape: string) => (shape.toLowerCase().includes('no notch') ? false : true)
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) =>
            shape.toLowerCase().includes('no notch') ? 'Door Width' : 'Bottom Width',
          defaultValue: 28,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            if (shape.toLowerCase().includes('no notch')) {
              const height = +formState.formValues['Height'] + +formState.fractionValues['Height'];

              return height >= 80 ? 28.875 : 36;
            }
            return 95;
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) =>
            shape.toLowerCase().includes('no notch') ? 'B' : 'C',
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('return') ? true : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (shape.toLowerCase().startsWith('return')) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }
            if (refVal + refPlVal > 95) return true;
            return false;
          }
        },
        {
          name: 'NinetyDegreeReturnPanelTopWidth',
          getLabel: (shape: string) => '90° Return Panel Top Width',
          defaultValue: 0,
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: () => ['ReturnPanelHeight'],
          useDynamicValue: (shape: string) => true,
          getDimensionIdentityLabel: () => 'Z',
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['NinetyDegreeReturnPanelBottomWidth'] +
              +formState.fractionValues['NinetyDegreeReturnPanelBottomWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          }
        },
        {
          name: 'NinetyDegreeReturnPanelBottomWidth',
          getLabel: (shape: string) => '90° Return Panel Bottom Width',
          defaultValue: 48,
          maxValue: 60,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => {
            return 'D';
          },
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('return') ? true : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refVal =
              +formState.formValues['ReturnPanelHeight'] +
              +formState.fractionValues['ReturnPanelHeight'];
            let refPlValue = plumbLevelVal;
            if (shape.toLowerCase().includes('notch')) {
              refVal +=
                +formState.formValues['NotchHeight'] + +formState.fractionValues['NotchHeight'];
            }
            if (shape.toLowerCase().startsWith('return')) {
              refPlValue = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }
            if (refVal + refPlValue > 95) return true;

            return false;
          }
        },
        {
          name: 'ReturnPanelHeight',
          getLabel: (shape: string) => 'Return Panel Height',
          defaultValue: 0,
          maxValue: 95,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => {
            return 'E';
          },
          tooltip: this.TooltipForReturnPanelHeight,
          dynamicPlumbLevelDependents: () => [
            'NinetyDegreeReturnPanelBottomWidth',
            'BottomWidth',
            'NotchWidth'
          ],
          isDisabled: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (shape.toLowerCase().includes('notch')) {
              value -=
                +formState.formValues['NotchHeight'] + +formState.fractionValues['NotchHeight'];
            }
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isDimensionOnTheLeft: shape => {
            return shape.toLowerCase().startsWith('return');
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal =
              +formState.formValues['NinetyDegreeReturnPanelTopWidth'] +
              +formState.fractionValues['NinetyDegreeReturnPanelTopWidth'];

            if (refVal + plumbLevelVal > 60) return true;
            return false;
          }
        },
        {
          name: 'NotchHeight',
          getLabel: (shape: string) => 'Notch Height',
          defaultValue: 0,
          maxValue: 95,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => 'F',
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('notch');
          },
          isDimensionOnTheLeft: shape => {
            return shape.toLowerCase().startsWith('return');
          },
          flipNegPosPlumbLevel: (shape: string) => false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];
            if (refVal + refPlVal > 95) return true;
            return false;
          }
        },
        {
          name: 'NotchWidth',
          getLabel: (shape: string) =>
            shape.toLowerCase().includes('no notch') ? 'Panel Width' : 'Notch Width',
          defaultValue: 0,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'G',
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('notch');
          },
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return (
              95 - (+formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'])
            );
          },
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('return') ? true : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (shape.toLowerCase().startsWith('return')) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }
            if (refVal + refPlVal > 95) return true;
            return false;
          }
        }
      ]
    },
    // Frameless Panel-Door-Panel
    {
      sku: 'MRCPDNP', //Model 20389 //Done // Done UAT
      formDefinitions: [
        {
          name: 'Height',
          getLabel: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? 'Right Panel Height'
              : 'Left Panel Height',
          defaultValue: 74,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'A',
          isDisabled: (shape: string) => false,
          useDynamicValue: (shape: string) => false,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? false
              : true,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refVal = +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal > 95) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          minValue: 48,
          maxValue: 95,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            if (shape.toLowerCase().includes('notch')) {
              value +=
                +formState.formValues['NotchWidth'] + +formState.fractionValues['NotchWidth'];
            }

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['Height', 'PanelHeight', 'NotchHeight'],
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) => 'Bottom Width',
          defaultValue: 48,
          minValue: 48,
          maxValue: 95,
          tooltip: this.TooltipForWidth,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'B',
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? true
              : false,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return (
              95 - (+formState.formValues['NotchWidth'] + +formState.fractionValues['NotchWidth'])
            );
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal =
              +formState.formValues['PanelHeight'] + +formState.fractionValues['PanelHeight'];

            if (shape.toLowerCase().includes('notch')) {
              refVal +=
                +formState.formValues['NotchHeight'] + +formState.fractionValues['NotchHeight'];
            }

            if (shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }
            if (refVal + refPlVal > 95) return true;
            return false;
          }
        },
        {
          name: 'DoorWidth',
          getLabel: (shape: string) => 'Door Width',
          defaultValue: this.StandardDoorWidth,
          maxValue: 36,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return +formState.formValues['Height'] >= 80 ? 28.875 : 36;
          },
          getDimensionIdentityLabel: (shape: string) => 'C'
        },
        {
          name: 'PanelHeight',
          getLabel: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? 'Left Panel Height'
              : 'Right Panel Height',
          defaultValue: 74,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'D',
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? true
              : false,
          dynamicPlumbLevelDependents: (shape: string) => ['BottomWidth', 'NotchWidth'],
          isDisabled: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;

            let value = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            if (shape.toLowerCase().includes('notch')) {
              value -=
                +formState.formValues['NotchHeight'] + +formState.fractionValues['NotchHeight'];
            }
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal > 95) return true;
            return false;
          }
        },
        {
          name: 'NotchHeight',
          getLabel: (shape: string) => {
            if (shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch'))
              return 'Left Notch Height';
            return 'Right Notch Height';
          },
          defaultValue: 0,
          maxValue: 95,
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('notch');
          },
          getDimensionIdentityLabel: (shape: string) => 'E',
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          flipNegPosPlumbLevel: (shape: string) => false,
          isDimensionOnTheLeft: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? true
              : false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal > 95) return true;
            return false;
          }
        },
        {
          name: 'NotchWidth',
          getLabel: (shape: string) => {
            if (shape.toLowerCase().includes('left')) return 'Left Notch Width';
            if (shape.toLowerCase().endsWith('nonotch')) return 'Right Panel Width';
            if (shape.toLowerCase().startsWith('nonotch')) return 'Left Panel Width';
            return 'Right Notch Width';
          },
          defaultValue: 0,
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('notch');
          },
          getDimensionIdentityLabel: (shape: string) => 'F',
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')
              ? true
              : false,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            return (
              95 - (+formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'])
            );
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal =
              +formState.formValues['PanelHeight'] + +formState.fractionValues['PanelHeight'];

            if (shape.toLowerCase().includes('notch')) {
              refVal +=
                +formState.formValues['NotchHeight'] + +formState.fractionValues['NotchHeight'];
            }

            if (shape.toLowerCase().includes('left') || shape.toLowerCase().startsWith('nonotch')) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }

            if (refVal + refPlVal > 95) return true;
            return false;
          }
        }
      ]
    },
    // Frameless Neo Angle Door
    {
      sku: 'MRCNEO', //Model 20386 //Done // TODO:  update after client supplies Neo Angle calculations
      formDefinitions: [
        {
          name: 'LeftOverallHeight',
          getLabel: (shape: string) => 'Left Overall Height',
          defaultValue: 72,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          getDimensionIdentityLabel: (shape: string) => 'Z',
          showWhen: (shape: string) =>
            shape.toLowerCase().includes('no cut out') ||
            (!shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on right side')) ||
            shape.toLowerCase().endsWith('no notch on both sides')
              ? false
              : true,
          dynamicPlumbLevelDependents: (shape: string) => {
            if (
              shape.toLowerCase().endsWith('no notch on left side') ||
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return [];
            return [
              'LeftNotchWidth',
              'LeftPanelWidth',
              'DoorWidth',
              'RightPanelWidth',
              'RightNotchWidth'
            ];
          },
          isHidden: (shape: string) => true,
          isDisabled: (shape: string) => false,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['LeftPanelHeight'] +
              +formState.fractionValues['LeftPanelHeight'] +
              +formState.formValues['LeftNotchHeight'] +
              +formState.fractionValues['LeftNotchHeight'];

            if (
              shape.toLowerCase().endsWith('no notch on right side') ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              value =
                +formState.formValues['RightPanelHeight'] +
                +formState.fractionValues['RightPanelHeight'] +
                +formState.formValues['RightNotchHeight'] +
                +formState.fractionValues['RightNotchHeight'];
            }

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDimensionOnTheLeft: () => true
        },
        {
          name: 'LeftPanelHeight',
          getLabel: (shape: string) => 'Left Panel Height',
          defaultValue: 72,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDisabled: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              shape.toLowerCase().endsWith('no notch on right side')
            )
              return true;
            return false;
          },
          isDimensionOnTheLeft: () => true,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let maxVal =
              95 -
              (+formState.formValues['LeftNotchHeight'] +
                +formState.fractionValues['LeftNotchHeight']);

            if (
              shape.toLowerCase().endsWith('no notch on right side') ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              maxVal = 95;
            }

            return maxVal;
          },
          getDimensionIdentityLabel: (shape: string) => {
            if (
              shape.toLowerCase().endsWith('notch on left side') &&
              !shape.toLowerCase().endsWith('no notch on left side')
            )
              return 'G';
            if (shape.toLowerCase().endsWith('no notch on right side')) return 'H';
            return 'A';
          },
          useDynamicValue: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              shape.toLowerCase().endsWith('no notch on right side')
            )
              return true;
            return false;
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const { formValues, fractionValues } = formState;
            const shape = formValues['Shape'] as string;
            let value =
              +formValues['RightPanelHeight'] +
              +fractionValues['RightPanelHeight'] +
              +formValues['RightNotchHeight'] +
              +fractionValues['RightNotchHeight'] -
              (+formValues['LeftNotchHeight'] + +fractionValues['LeftNotchHeight']);

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          dynamicPlumbLevelDependents: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return ['RightPanelWidth', 'DoorWidth', 'LeftPanelWidth', 'LeftNotchWidth'];
            }
            return [];
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const maxOverallWidth = 108;
            const maxWidth = 76;

            const refPanelVal =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'];

            const refOverallVal =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'] +
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'] +
              +formState.formValues['DoorWidth'] +
              +formState.fractionValues['DoorWidth'];

            if (
              refOverallVal + plumbLevelVal > maxOverallWidth ||
              refPanelVal + plumbLevelVal > maxWidth
            ) {
              return true;
            }
            return false;
          }
        },
        {
          name: 'LeftPanelWidth',
          getLabel: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                (shape.toLowerCase().endsWith('notch on left side') ||
                  shape.toLowerCase().endsWith('notch on both sides')))
            ) {
              return 'Left Notch Bottom Width';
            }

            return 'Left Panel Width';
          },
          defaultValue: 36,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const { formValues, fractionValues } = formState;
            const maxVal = 76 - +formValues['LeftNotchWidth'] + +fractionValues['LeftNotchWidth'];
            const maxOverallVal = 108;

            const refOverallVal =
              +formValues['RightPanelWidth'] +
              +fractionValues['RightPanelWidth'] +
              +formValues['RightNotchWidth'] +
              +fractionValues['RightNotchWidth'] +
              +formValues['DoorWidth'] +
              +fractionValues['DoorWidth'] +
              +formValues['LeftNotchWidth'] +
              +fractionValues['LeftNotchWidth'];

            let refVal = 0;
            const valDiff = maxOverallVal - refOverallVal;

            if (valDiff >= maxVal) {
              refVal = maxVal;
            } else {
              refVal = valDiff;
            }

            return refVal;
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on both sides'))
            )
              return 'D';
            if (shape.toLowerCase().endsWith('no notch on right side')) return 'E';
            return 'B';
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal =
              +formState.formValues['RightOverallHeight'] +
              +formState.fractionValues['RightOverallHeight'];
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
              refVal =
                +formState.formValues['LeftOverallHeight'] +
                +formState.fractionValues['LeftOverallHeight'];
            }

            if (refVal + refPlVal > 95) return true;
            return false;
          },
          flipNegPosPlumbLevel: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return true;
            }
            return false;
          }
        },
        {
          name: 'DoorWidth',
          getLabel: (shape: string) => 'Door Width',
          defaultValue: this.StandardDoorWidth,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const { formValues, fractionValues } = formState;
            const maxVal = 36;
            const maxOverallVal = 108;

            const refOverallVal =
              +formValues['RightPanelWidth'] +
              +fractionValues['RightPanelWidth'] +
              +formValues['RightNotchWidth'] +
              +fractionValues['RightNotchWidth'] +
              +formValues['LeftPanelWidth'] +
              +fractionValues['LeftPanelWidth'] +
              +formValues['LeftNotchWidth'] +
              +fractionValues['LeftNotchWidth'];

            let refVal = 0;
            const valDiff = maxOverallVal - refOverallVal;

            if (valDiff >= maxVal) {
              refVal = maxVal;
            } else {
              refVal = valDiff;
            }

            return refVal;
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => {
            if (
              shape.toLowerCase().includes('no notch on left') ||
              shape.toLowerCase().endsWith('no notch on right side') ||
              shape.toLowerCase().endsWith('no notch on both sides')
            )
              return 'D';
            if (
              !shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return 'E';
            return 'C';
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal =
              +formState.formValues['RightOverallHeight'] +
              +formState.fractionValues['RightOverallHeight'];
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
              refVal =
                +formState.formValues['LeftOverallHeight'] +
                +formState.fractionValues['LeftOverallHeight'];
            }

            if (refVal + refPlVal > 95) return true;
            return false;
          },
          flipNegPosPlumbLevel: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return true;
            }
            return false;
          }
        },
        {
          name: 'RightPanelWidth',
          getLabel: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                (shape.toLowerCase().endsWith('notch on right side') ||
                  shape.toLowerCase().endsWith('notch on both sides')))
            ) {
              return 'Right Notch Bottom Width';
            }

            return 'Right Panel Width';
          },
          defaultValue: 36,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const { formValues, fractionValues } = formState;
            const shape = formValues['Shape'] as string;
            const maxVal = 76 - +formValues['RightNotchWidth'] + +fractionValues['RightNotchWidth'];
            const maxOverallVal = 108;

            let refOverallVal =
              +formValues['LeftPanelWidth'] +
              +fractionValues['LeftPanelWidth'] +
              +formValues['LeftNotchWidth'] +
              +fractionValues['LeftNotchWidth'] +
              +formValues['DoorWidth'] +
              +fractionValues['DoorWidth'] +
              +formValues['RightNotchWidth'] +
              +fractionValues['RightNotchWidth'];

            let refVal = 0;
            const valDiff = maxOverallVal - refOverallVal;

            if (valDiff >= maxVal) {
              refVal = maxVal;
            } else {
              refVal = valDiff;
            }

            return refVal;
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              shape.toLowerCase().endsWith('no notch on right side')
            )
              return 'B';

            if (shape.toLowerCase().includes('no notch on left')) return 'E';
            if (shape.toLowerCase().endsWith('notch on both sides')) return 'F';
            return 'D';
          },
          dynamicPlumbLevelDependents: (shape: string) => [],
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refPlVal = plumbLevelVal;
            let refVal =
              +formState.formValues['RightOverallHeight'] +
              +formState.fractionValues['RightOverallHeight'];
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
              refVal =
                +formState.formValues['LeftOverallHeight'] +
                +formState.fractionValues['LeftOverallHeight'];
            }

            if (refVal + refPlVal > 95) return true;
            return false;
          },
          flipNegPosPlumbLevel: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return true;
            }
            return false;
          }
        },
        {
          name: 'LeftDoorHeight',
          getLabel: (shape: string) => 'Left Door Height',
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          isHidden: () => true,
          getDimensionIdentityLabel: (shape: string) => 'Z',
          useDynamicValue: () => true,
          dynamicPlumbLevelDependents: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return ['DoorWidth', 'RightPanelWidth'];
            }
            return ['LeftPanelWidth', 'LeftNotchWidth'];
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['LeftPanelHeight'] +
              +formState.fractionValues['LeftPanelHeight'] +
              +formState.formValues['LeftNotchHeight'] +
              +formState.fractionValues['LeftNotchHeight'];

            if (
              shape.toLowerCase().endsWith('no notch on right side') ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              value =
                +formState.formValues['RightPanelHeight'] +
                +formState.fractionValues['RightPanelHeight'] +
                +formState.formValues['RightNotchHeight'] +
                +formState.fractionValues['RightNotchHeight'];
            }

            return this.splitNumberIntoWholeandFractionValues(value);
          }
        },
        {
          name: 'RightDoorHeight',
          getLabel: (shape: string) => 'Right Door Height',
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          isHidden: () => true,
          getDimensionIdentityLabel: (shape: string) => 'Z',
          useDynamicValue: () => true,
          dynamicPlumbLevelDependents: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return ['RightPanelWidth'];
            }

            return ['LeftPanelWidth', 'DoorWidth', 'LeftNotchWidth'];
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['LeftPanelHeight'] +
              +formState.fractionValues['LeftPanelHeight'] +
              +formState.formValues['LeftNotchHeight'] +
              +formState.fractionValues['LeftNotchHeight'];

            if (
              shape.toLowerCase().endsWith('no notch on right side') ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              value =
                +formState.formValues['RightPanelHeight'] +
                +formState.fractionValues['RightPanelHeight'] +
                +formState.formValues['RightNotchHeight'] +
                +formState.fractionValues['RightNotchHeight'];
            }

            return this.splitNumberIntoWholeandFractionValues(value);
          }
        },

        {
          name: 'RightPanelHeight',
          getLabel: (shape: string) => 'Right Panel Height',
          defaultValue: 72,
          maxValue: 95,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on right side') &&
                !shape.toLowerCase().includes('no notch')) ||
              shape.toLowerCase().endsWith('no notch on both sides')
            )
              return 'G';
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              shape.toLowerCase().endsWith('no notch on right side')
            )
              return 'A';
            if (shape.toLowerCase().includes('no notch on left')) return 'H';
            if (
              !shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return 'I';
            return 'E';
          },
          dynamicPlumbLevelDependents: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return [];
            }

            return [
              'RightPanelWidth',
              'DoorWidth',
              'LeftPanelWidth',
              'RightNotchWidth',
              'LeftNotchWidth'
            ];
          },
          isDisabled: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              shape.toLowerCase().endsWith('no notch on right side')
            )
              return false;
            return true;
          },
          useDynamicValue: (shape: string) => {
            if (
              (shape.toLowerCase().endsWith('notch on left side') &&
                !shape.toLowerCase().endsWith('no notch on left side')) ||
              shape.toLowerCase().endsWith('no notch on right side')
            )
              return false;
            return true;
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;

            let value =
              +formState.formValues['LeftPanelHeight'] +
              +formState.fractionValues['LeftPanelHeight'] +
              +formState.formValues['LeftNotchHeight'] +
              +formState.fractionValues['LeftNotchHeight'] -
              +formState.formValues['RightNotchHeight'] +
              +formState.fractionValues['RightNotchHeight'];

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isDimensionOnTheLeft: (shape: string) => false,

          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const maxOverallWidth = 108;
            const maxWidth = 76;

            const refPanelVal =
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'];

            const refOverallVal =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'] +
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'] +
              +formState.formValues['DoorWidth'] +
              +formState.fractionValues['DoorWidth'];

            if (
              refOverallVal + plumbLevelVal > maxOverallWidth ||
              refPanelVal + plumbLevelVal > maxWidth
            ) {
              return true;
            }
            return false;
          }
        },
        {
          name: 'RightOverallHeight',
          getLabel: (shape: string) => 'Right Overall Height',
          defaultValue: 72,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          isHidden: () => true,
          getDimensionIdentityLabel: (shape: string) => 'Z',
          useDynamicValue: () => true,
          showWhen: (shape: string) => {
            if (shape.toLowerCase().endsWith('no cut out')) return false;
            if (
              !shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on left side')
            )
              return false;
            if (shape.toLowerCase().endsWith('no notch on both sides')) return false;
            return true;
          },
          dynamicPlumbLevelDependents: (shape: string) => {
            if (shape.toLowerCase().endsWith('no notch on right side')) return [];

            return [
              'LeftPanelWidth',
              'LeftNotchWidth',
              'DoorWidth',
              'RightNotchWidth',
              'RightPanelWidth'
            ];
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;

            let value =
              +formState.formValues['LeftPanelHeight'] +
              +formState.fractionValues['LeftPanelHeight'] +
              +formState.formValues['LeftNotchHeight'] +
              +formState.fractionValues['LeftNotchHeight'];

            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              value =
                +formState.formValues['RightPanelHeight'] +
                +formState.fractionValues['RightPanelHeight'] +
                +formState.formValues['RightNotchHeight'] +
                +formState.fractionValues['RightNotchHeight'];
            }

            return this.splitNumberIntoWholeandFractionValues(value);
          }
        },
        {
          name: 'LeftNotchHeight',
          getLabel: (shape: string) => 'Left Notch Height',
          defaultValue: 0,
          showWhen: (shape: string) =>
            shape.toLowerCase().includes('notch on both') ||
            shape.toLowerCase().includes('notch on left') ||
            shape.toLowerCase().includes('no notch on right'),
          getDimensionIdentityLabel: (shape: string) => {
            if (
              shape.toLowerCase().includes('no notch on left') ||
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return 'C';
            if (shape.toLowerCase().endsWith('no notch on right side')) return 'F';
            return 'E';
          },
          plumbLevelType: (shape: string) => {
            if (
              shape.toLowerCase().endsWith('no notch on left side') ||
              shape.toLowerCase().endsWith('no notch on both sides')
            )
              return OutOfPlumbOrLevelType.None;
            return OutOfPlumbOrLevelType.Plumb;
          },
          isDimensionOnTheLeft: () => true,
          flipNegPosPlumbLevel: (shape: string) => {
            if (
              shape.toLowerCase().endsWith('no notch on right side') ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side')) ||
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return false;
            return true;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const maxOverallWidth = 108;
            const maxWidth = 76;

            const refPanelVal =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'];

            const refOverallVal =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'] +
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'] +
              +formState.formValues['DoorWidth'] +
              +formState.fractionValues['DoorWidth'];

            if (
              refOverallVal + plumbLevelVal > maxOverallWidth ||
              refPanelVal + plumbLevelVal > maxWidth
            ) {
              return true;
            }
            return false;
          },
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal =
              +formState.formValues['LeftPanelHeight'] +
              +formState.fractionValues['LeftPanelHeight'];

            if (shape.toLowerCase().endsWith('no notch on right side')) return 95;
            return 95 - refVal;
          }
        },
        {
          name: 'LeftNotchWidth',
          getLabel: (shape: string) => 'Left Notch Width',
          defaultValue: 0,
          showWhen: (selectedShape: string) => {
            return (
              (selectedShape.toLowerCase().includes('notch on left') &&
                !selectedShape.toLowerCase().includes('no notch')) ||
              (selectedShape.toLowerCase().includes('notch on both sides') &&
                !selectedShape.toLowerCase().includes('no notch')) ||
              selectedShape.toLowerCase().includes('no notch on right')
            );
          },
          getDimensionIdentityLabel: (shape: string) => {
            if (shape.toLowerCase().endsWith('no notch on right side')) return 'G';
            if (
              !shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return 'B';
            return 'F';
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const { formValues, fractionValues } = formState;
            const shape = formValues['Shape'] as string;
            const maxVal = 76 - +formValues['LeftPanelWidth'] + +fractionValues['LeftPanelWidth'];
            const maxOverallVal = 108;

            let refOverallVal =
              +formValues['LeftPanelWidth'] +
              +fractionValues['LeftPanelWidth'] +
              +formValues['DoorWidth'] +
              +fractionValues['DoorWidth'] +
              +formValues['RightPanelWidth'] +
              +fractionValues['RightPanelWidth'] +
              +formValues['RightNotchWidth'] +
              +fractionValues['RightNotchWidth'];

            let refVal = 0;
            const valDiff = maxOverallVal - refOverallVal;

            if (valDiff >= maxVal) {
              refVal = maxVal;
            } else {
              refVal = valDiff;
            }

            return refVal;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;

            let refPlVal = plumbLevelVal;
            let refVal =
              +formState.formValues['RightOverallHeight'] +
              +formState.fractionValues['RightOverallHeight'];
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
              refVal =
                +formState.formValues['LeftOverallHeight'] +
                +formState.fractionValues['LeftOverallHeight'];
            }
            if (refVal + refPlVal > 95) return true;
            return false;
          },
          flipNegPosPlumbLevel: (shape: string) => {
            if (
              (shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on right side')) ||
              (!shape.toLowerCase().includes('no notch') &&
                shape.toLowerCase().endsWith('notch on left side'))
            ) {
              return true;
            }
            return false;
          }
        },

        {
          name: 'RightNotchHeight',
          getLabel: (shape: string) => 'Right Notch Height',
          defaultValue: 0,
          showWhen: (shape: string) =>
            shape.toLowerCase().includes('notch on both') ||
            shape.toLowerCase().includes('notch on right') ||
            shape.toLowerCase().includes('no notch on left'),
          getDimensionIdentityLabel: (shape: string) => {
            if (shape.toLowerCase().includes('no notch on left')) return 'F';
            if (shape.toLowerCase().endsWith('no notch on right side')) return 'C';
            if (
              !shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return 'G';
            return 'E';
          },
          plumbLevelType: (shape: string) => {
            if (
              shape.toLowerCase().endsWith('no notch on right side') ||
              shape.toLowerCase().endsWith('no notch on both sides')
            ) {
              return OutOfPlumbOrLevelType.None;
            }
            return OutOfPlumbOrLevelType.Plumb;
          },
          isDimensionOnTheLeft: () => false,
          flipNegPosPlumbLevel: () => false,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let maxVal = 95;
            if (shape.toLowerCase().endsWith('no notch on right side')) {
              maxVal =
                maxVal -
                +formState.formValues['RightPanelHeight'] +
                +formState.fractionValues['RightPanelHeight'];
            } else {
              maxVal =
                +formState.formValues['LeftPanelHeight'] +
                +formState.fractionValues['LeftPanelHeight'] +
                +formState.formValues['LeftNotchHeight'] +
                +formState.fractionValues['LeftNotchHeight'];
            }

            return maxVal;
          },

          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const maxOverallWidth = 108;
            const maxWidth = 76;

            const refPanelVal =
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'];

            const refOverallVal =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'] +
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'] +
              +formState.formValues['DoorWidth'] +
              +formState.fractionValues['DoorWidth'];

            if (
              refOverallVal + plumbLevelVal > maxOverallWidth ||
              refPanelVal + plumbLevelVal > maxWidth
            ) {
              return true;
            }
            return false;
          }
        },
        {
          name: 'RightNotchWidth',
          getLabel: (shape: string) => 'Right Notch Width',
          defaultValue: 0,
          showWhen: (shape: string) => {
            return (
              (shape.toLowerCase().includes('notch on both') &&
                !shape.toLowerCase().includes('no notch')) ||
              (shape.toLowerCase().includes('notch on right') &&
                !shape.toLowerCase().includes('no notch')) ||
              shape.toLowerCase().includes('no notch on left')
            );
          },
          getDimensionIdentityLabel: (shape: string) => {
            if (shape.toLowerCase().includes('no notch on left')) return 'G';
            if (shape.toLowerCase().endsWith('no notch on right side')) return 'B';
            if (
              !shape.toLowerCase().includes('no notch') &&
              shape.toLowerCase().endsWith('notch on both sides')
            )
              return 'H';
            return 'F';
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          dynamicMaxValue: (formState: MeasurementFormValues) => {
            const { formValues, fractionValues } = formState;
            const shape = formValues['Shape'] as string;
            const maxVal = 76 - +formValues['RightPanelWidth'] + +fractionValues['RightPanelWidth'];
            const maxOverallVal = 108;

            let refOverallVal =
              +formValues['LeftPanelWidth'] +
              +fractionValues['LeftPanelWidth'] +
              +formValues['LeftNotchWidth'] +
              +fractionValues['LeftNotchWidth'] +
              +formValues['DoorWidth'] +
              +fractionValues['DoorWidth'] +
              +formValues['RightPanelWidth'] +
              +fractionValues['RightPanelWidth'];

            let refVal = 0;
            const valDiff = maxOverallVal - refOverallVal;

            if (valDiff >= maxVal) {
              refVal = maxVal;
            } else {
              refVal = valDiff;
            }

            return refVal;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;

            let refVal =
              +formState.formValues['RightOverallHeight'] +
              +formState.fractionValues['RightOverallHeight'];

            if (refVal + plumbLevelVal > 95) return true;
            return false;
          }
        },
        {
          name: 'LeftPanelTopWidth',
          getLabel: (shape: string) => '90° Return Top Left Width',
          maxValue: 76,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['LeftPanelWidth'] +
              +formState.fractionValues['LeftPanelWidth'] +
              +formState.formValues['LeftNotchWidth'] +
              +formState.fractionValues['LeftNotchWidth'];

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['LeftPanelHeight', 'LeftNotchHeight'],
          getDimensionIdentityLabel: (shape: string) => 'Z'
        },
        {
          name: 'RightPanelTopWidth',
          getLabel: (shape: string) => '90° Return Top Right Width',
          maxValue: 76,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value =
              +formState.formValues['RightPanelWidth'] +
              +formState.fractionValues['RightPanelWidth'] +
              +formState.formValues['RightNotchWidth'] +
              +formState.fractionValues['RightNotchWidth'];

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['RightPanelHeight', 'RightNotchHeight'],
          getDimensionIdentityLabel: (shape: string) => 'Z'
        }
      ]
    },
    // Frameless French Door
    {
      sku: 'MRCFRND', //Model 20388 //Done // Done UAT
      formDefinitions: [
        {
          name: 'LeftDoorHeight',
          getLabel: (shape: string) => 'Left Height',
          defaultValue: 72,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => 'A',
          isDimensionOnTheLeft: shape => {
            return true;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 72) return true;
            return false;
          }
        },
        {
          name: 'RightDoorHeight',
          getLabel: (shape: string) => 'Right Height',
          defaultValue: 0,
          maxValue: 95,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDisabled: (shape: string) => true,
          getDimensionIdentityLabel: (shape: string) => 'C',
          useDynamicValue: (shape: string) => true,
          isDimensionOnTheLeft: (shape: string) => false,
          dynamicPlumbLevelDependents: () => ['BottomWidth'],
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['LeftDoorHeight'] + +formState.fractionValues['LeftDoorHeight'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 72) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          minValue: 36,
          maxValue: 72,
          isHidden: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['LeftDoorHeight', 'RightDoorHeight'],
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) => 'Bottom Width',
          defaultValue: 36,
          minValue: 36,
          maxValue: 72,
          tooltip: this.TooltipForWidth,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'B',
          flipNegPosPlumbLevel: (shape: string) => false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['LeftDoorHeight'] + +formState.fractionValues['LeftDoorHeight'];

            if (refVal + plumbLevelVal >= 95) return true;
            return false;
          }
        }
      ]
    },
    // Frameless Single Panel
    {
      sku: 'MRCSHSH', // Model 20450 // Done  //Done UAT
      formDefinitions: [
        {
          name: 'LeftHeight',
          getLabel: (shape: string) => 'Left Height',
          defaultValue: 72,
          maxValue: 80,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => 'A',
          isDimensionOnTheLeft: shape => {
            return true;
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 48) return true;
            return false;
          }
        },
        {
          name: 'RightHeight',
          getLabel: (shape: string) => 'Right Height',
          defaultValue: 0,
          maxValue: 80,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          isDisabled: (shape: string) => true,
          getDimensionIdentityLabel: (shape: string) => 'C',
          useDynamicValue: (shape: string) => true,
          isDimensionOnTheLeft: (shape: string) => false,
          dynamicPlumbLevelDependents: () => ['BottomWidth'],
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['LeftHeight'] + +formState.fractionValues['LeftHeight'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 48) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          maxValue: 48,
          isHidden: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => ['LeftHeight', 'RightHeight'],
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) => 'Bottom Width',
          defaultValue: 36,
          minValue: 12,
          maxValue: 48,
          tooltip: this.TooltipForWidth,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'B',
          flipNegPosPlumbLevel: (shape: string) => false,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['LeftHeight'] + +formState.fractionValues['LeftHeight'];

            if (refVal + plumbLevelVal >= 80) return true;
            return false;
          }
        }
      ]
    },
    // Frameless Ultra Sliding Door with Inline Panel
    {
      sku: 'MRCULTR', // Model 20393 //Done // Done UAT
      formDefinitions: [
        {
          name: 'Height',
          getLabel: (shape: string) => 'Door Height',
          defaultValue: 76,
          maxValue: 76,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) => 'A',
          isDimensionOnTheLeft: shape => (shape.toLowerCase().endsWith('door') ? false : true),
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const refVal =
              +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];

            if (refVal + plumbLevelVal >= 84) return true;
            return false;
          }
        },
        {
          name: 'TopWidth',
          getLabel: (shape: string) => 'Top Width',
          defaultValue: 40,
          minValue: 40,
          maxValue: 84,
          tooltip: this.TooltipForWidth,
          isHidden: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicPlumbLevelDependents: (shape: string) => {
            const deps = ['Height'];
            if (!shape.toLowerCase().includes('return')) deps.push('PanelHeight');
            return deps;
          },
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['BottomWidth'] + +formState.fractionValues['BottomWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          },
          getDimensionIdentityLabel: () => 'Z'
        },
        {
          name: 'BottomWidth',
          getLabel: (shape: string) => 'Bottom Width',
          defaultValue: 40,
          minValue: 40,
          maxValue: 84,
          tooltip: this.TooltipForWidth,
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'B',
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('door') ? false : true,
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];
            let refPlVal = plumbLevelVal;
            if (!shape.toLowerCase().startsWith('door')) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }
            if (refVal + refPlVal >= 76) return true;
            return false;
          }
        },
        {
          name: 'NinetyDegreeReturnBottomPanelWidth',
          getLabel: (shape: string) => '90° Return Bottom Panel Width',
          defaultValue: 40,
          maxValue: 60,
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('return');
          },
          plumbLevelType: () => OutOfPlumbOrLevelType.Level,
          getDimensionIdentityLabel: (shape: string) => 'C',
          flipNegPosPlumbLevel: (shape: string) =>
            shape.toLowerCase().startsWith('return') ? true : false,

          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            const refVal = +formState.formValues['Height'] + +formState.fractionValues['Height'];
            let refPlVal = plumbLevelVal;
            if (shape.toLowerCase().startsWith('return')) {
              refPlVal = plumbLevelVal > 0 ? -Math.abs(plumbLevelVal) : Math.abs(plumbLevelVal);
            }
            if (refVal + refPlVal >= 76) return true;
            return false;
          }
        },
        {
          name: 'NinetyDegreeReturnTopPanelWidth',
          getLabel: (shape: string) => '90° Return Top Panel Width',
          maxValue: 60,
          showWhen: (selectedShape: string) => {
            return selectedShape.toLowerCase().includes('return');
          },
          isHidden: (shape: string) => true,
          dynamicPlumbLevelDependents: () => ['PanelHeight'],
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const value =
              +formState.formValues['NinetyDegreeReturnBottomPanelWidth'] +
              +formState.fractionValues['NinetyDegreeReturnBottomPanelWidth'];
            return this.splitNumberIntoWholeandFractionValues(value);
          }
        },
        {
          name: 'PanelHeight',
          getLabel: (shape: string) =>
            shape.toLowerCase().includes('return')
              ? 'Return Panel Height'
              : shape.toLowerCase().startsWith('door')
              ? 'Right Height'
              : 'Left Height',
          defaultValue: 76,
          maxValue: 76,
          tooltip: this.TooltipForHeight,
          plumbLevelType: () => OutOfPlumbOrLevelType.Plumb,
          getDimensionIdentityLabel: (shape: string) =>
            shape.toLowerCase().includes('return') ? 'D' : 'C',
          dynamicPlumbLevelDependents: (shape: string) => {
            return ['BottomWidth', 'NinetyDegreeReturnBottomPanelWidth'];
          },
          isDisabled: (shape: string) => true,
          useDynamicValue: (shape: string) => true,
          dynamicValue: (formState: MeasurementFormValues) => {
            const shape = formState.formValues['Shape'] as string;
            let value = +formState.formValues['Height'] + +formState.fractionValues['Height'];

            return this.splitNumberIntoWholeandFractionValues(value);
          },
          isDimensionOnTheLeft: shape => (shape.toLowerCase().startsWith('door') ? false : true),
          isPlumbLevelDisabled: (formState: MeasurementFormValues, plumbLevelVal: number) => {
            const shape = formState.formValues['Shape'] as string;
            let refVal = +formState.formValues['TopWidth'] + +formState.fractionValues['TopWidth'];
            let refMax = 84;

            if (shape.toLowerCase().includes('return')) {
              refVal =
                +formState.formValues['NinetyDegreeReturnTopPanelWidth'] +
                +formState.fractionValues['NinetyDegreeReturnTopPanelWidth'];
              refMax = 60;
            }

            if (refVal + plumbLevelVal >= refMax) return true;
            return false;
          }
        }
      ]
    }
  ];
}

export default new MeasurementService();
