export class Guid {
  // https://gist.github.com/jed/982883
  public static Generate = function b(a?: any): string {
    return a
      ? // tslint:disable-next-line:no-bitwise
        (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
  };

  public static Empty = '00000000-0000-0000-0000-000000000000';
}
