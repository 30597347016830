import {
  Component,
  Configurator,
  Model,
  Series,
  SeriesExtendedData
} from '../definitions/configurator.types';
import {
  DimensionQuestionDefinition,
  InchFractions,
  OutOfPlumbOrLevelType,
  enumKeys,
  enumValues
} from '../definitions/measurement-form-models';
import { QueryParameters } from '../definitions/url-paramerter.types';
import {
  ModelHotspotVM,
  SessionAccessory,
  SessionComponent,
  SessionCustomOptionRequest,
  SessionModelInstance
} from '../definitions/view-models';
import { ICartApi, ICartItem } from '../services/ApiIntegrationFactoryService';
import ModelUtilityService from '../services/ModelUtilityService';
import { QueryParams } from '../services/QueryParams';
import SettingsService from '../services/SettingsService';
import ThemeService from '../services/ThemeService';

import { IAccessory, IModel, IModelCustomData, roundToPlace } from '@ml/common';
import MeasurementService from '../services/MeasurementService';
import { SessionModelInstanceItem } from '../v2/v2.view-models';

import { PlumbLevelNegative, PlumbLevelPositive } from '../assets/PlumbLevelSvgs';

export function WrapInLambda<T>(obj: T) {
  return () => obj;
}

export function SortModelsInSeries(series: Series): Model[] {
  if (!series.Json) return series.Models;

  const parsed: SeriesExtendedData = JSON.parse(series.Json);
  if (!parsed.ModelIdsInSortedOrder?.length) return series.Models;

  return series.Models.sort(
    (a, b) =>
      parsed.ModelIdsInSortedOrder.indexOf(a.ModelId) -
      parsed.ModelIdsInSortedOrder.indexOf(b.ModelId)
  );
}

export function IsOutOfStock(availabilty: string) {
  return availabilty?.toLowerCase() === 'out of stock';
}

export function IsBrowserIncompatible() {
  return !Array.prototype.flatMap || !IsWebGlAvailable();
}

export function IsWebGlAvailable() {
  return !!window.WebGLRenderingContext;
}

// Helpful refs: https://webglreport.com/?v=2
// https://registry.khronos.org/webgl/specs/latest/1.0/#5.2
export function HasMajorPerformanceCaveat() {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl', { failIfMajorPerformanceCaveat: true });

  if (!gl) return true;
  else return false;
}

export function LoadScript(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const newScript = document.createElement('script');
    newScript.onerror = error => {
      console.error(error);
      reject(error);
    };
    newScript.onload = () => resolve();
    document.body.appendChild(newScript);
    newScript.src = url;
  });
}

export function GetBlobFromCanvas(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    if (canvas) {
      if (canvas.toBlob) {
        canvas.toBlob((blob: Blob) => {
          resolve(blob);
        });
      } else if ((canvas as any).msToBlob) {
        const blob = (canvas as any).msToBlob();
        resolve(blob);
      }
    } else reject();
  });
}

export function GetAllAccessories(configurator: Configurator): IAccessory[] {
  const configAccessories = configurator.AccessoryCategories.flatMap(x => x.Accessories);
  const modelAccessories = configurator.Series.flatMap(s =>
    s.Models.flatMap(m => m.AccessoryCategories.flatMap(ac => ac.Accessories))
  );

  return [...configAccessories, ...modelAccessories];
}

export function GetAllSelectionsForEventData(
  model: Model,
  instance?: SessionModelInstance,
  cartApi?: ICartApi
): ICartItem[] {
  if (instance && cartApi) {
    if (cartApi.getCartItemsFromComponents) {
      const comps = cartApi.getCartItemsFromComponents(
        ModelUtilityService.GetAllComponents(model),
        instance.Components
      );
      const accessories = cartApi.getCartItemsFromAccessories(
        model.AccessoryCategories.flatMap(x => x.Accessories),
        instance.Accessories
      );
      return [...comps, ...accessories];
    }
  } else {
    const combo = ModelUtilityService.GetFeaturedCombination(model);
    if (combo)
      return ModelUtilityService.GetComponentsByIds(combo.ComponentIds, model).map(c => {
        return {
          Sku: c.Sku,
          Cost: c.Cost,
          Quantity: c.CustomData?.DefaultQuantity ?? c.CustomData?.MinimumQuantity ?? 1,
          Name: c.Name
        } as ICartItem;
      });
    else return [];
  }
}

export function GetAllOptionsCost(
  model: Model,
  selectedComponentIds: number[],
  customOptionRequests: SessionCustomOptionRequest[],
  sessionAccessories: SessionAccessory[],
  sessionComponents: SessionComponent[]
): number[] {
  const componentsCost = ModelUtilityService.GetComponentsCost(
    model,
    selectedComponentIds,
    sessionComponents
  );
  const requestsCost = customOptionRequests.reduce((sum, req) => sum + req.Cost, 0);

  const allAccessories = model.AccessoryCategories.flatMap(x => x.Accessories);
  const accessoriesCost = GetAccessoriesCost(sessionAccessories, allAccessories);

  const componentsOriginalPriceTotalDiff = ModelUtilityService.GetComponentsOriginalPriceTotalDiff(
    model,
    selectedComponentIds
  );
  const accessoriesOriginalPriceTotalDiff = GetAccessoriesOriginalPriceTotalDiff(
    sessionAccessories,
    allAccessories
  );

  return [
    componentsCost + requestsCost + accessoriesCost,
    componentsOriginalPriceTotalDiff + accessoriesOriginalPriceTotalDiff
  ];
}

export function GetAccessoriesCost(
  sessionAccessories: SessionAccessory[],
  accessories: IAccessory[]
) {
  return sessionAccessories
    .map(x => {
      const acc = accessories.find(a => x.AccessoryId === a.AccessoryId);
      return acc && acc.Cost ? acc.Cost * x.Quantity : 0;
    })
    .reduce((curr, sum) => curr + sum, 0);
}

export function GetAccessoriesOriginalPriceTotalDiff(
  sessionAccessories: SessionAccessory[],
  accessories: IAccessory[]
) {
  return sessionAccessories
    .map(x => {
      const acc = accessories.find(a => x.AccessoryId === a.AccessoryId);
      if (acc?.CustomData.OriginalPrice && acc?.Cost && acc.Cost < acc.CustomData.OriginalPrice)
        return (acc.CustomData.OriginalPrice - acc.Cost) * x.Quantity;
      else return 0;
    })
    .reduce((curr, sum) => curr + sum, 0);
}

export function GetPercentOff(original: number, current: number) {
  if (!original || !current || current > original) return 0;
  return roundToPlace((1 - current / original) * 100, 0);
}

export function getFirstCategorySelectedComponent(model: Model, selectedComponentIds: number[]) {
  const firstCat = model.Categories.sort((a, b) => a.SortOrder - b.SortOrder)[0];
  if (!firstCat) return;
  const comps = [...firstCat.Components, ...firstCat.ChildCategories.flatMap(x => x.Components)];
  const selectedComp = comps.find(x => selectedComponentIds.includes(x.ComponentId));
  return selectedComp;
}

const useBaseVariablePricingCostWithHalfInchGlass = (
  model: Model,
  selectedComponentIds: number[]
) => {
  const effectiveSKUs = ['MRCDNPR', 'MRCPDNP', 'MRCSDNP'];
  if (effectiveSKUs.includes(model.Sku)) {
    const glassCat = MeasurementService.GetGlassCategory(model);
    const halfClassComp = MeasurementService.GetHalfInchGlassComponent(glassCat);
    if (halfClassComp && selectedComponentIds.some(id => id === halfClassComp.ComponentId)) {
      return true;
    }
    return false;
  }
  return false;
};

export function getPricingInfo(
  model: Model,
  selectedComponentIds: number[],
  customOptionRequests: SessionCustomOptionRequest[],
  sessionAccessories: SessionAccessory[],
  sessionComponents: SessionComponent[]
) {
  if (!model) return null;

  const baseCombination = ModelUtilityService.GetBaseCombination(model);
  let baseCost = baseCombination ? baseCombination.Cost : 0;
  let [optionsCost, totalDiffFromOriginalPrices] = GetAllOptionsCost(
    model,
    selectedComponentIds,
    customOptionRequests,
    sessionAccessories,
    sessionComponents
  );

  if (ThemeService.UseLegacyModelTemplateAlternate1) {
    const selectedComp = getFirstCategorySelectedComponent(model, selectedComponentIds);
    if (selectedComp) {
      baseCost += +selectedComp.Cost;
      optionsCost -= +selectedComp.Cost;
    }
  }

  const variablePriceBaseCategory = model.Categories.find(
    c => c.CustomData?.IsVariablePriceBaseCategory
  );
  if (variablePriceBaseCategory) {
    let selectedComp = variablePriceBaseCategory.Components.find(c =>
      selectedComponentIds?.includes(c.ComponentId)
    );

    if (model.VariablePricingHasBeenAppliedUpfront) {
      // FGI specific.  Done here as moving the getPriceInfo function to the
      // pricing manager would create issues as it is used everywhere
      const useBaseCost = useBaseVariablePricingCostWithHalfInchGlass(model, selectedComponentIds);
      if (useBaseCost) {
        optionsCost -= +selectedComp.Cost;
        // Both naming conventions used so we need to check for both
        let finishCat = model.Categories.find(c => c.Name.toLowerCase() === 'finish');
        if (!finishCat) finishCat = model.Categories.find(c => c.Name.toLowerCase() === 'finishes');

        let selectedFinishComp;
        // find currently selected finish
        if (finishCat) {
          selectedFinishComp = finishCat.Components.find(c =>
            selectedComponentIds.some(id => id === c.ComponentId)
          );
        }
        // If comp found remove price from the optioncost
        if (selectedFinishComp) {
          const prevFinishPrice =
            +selectedFinishComp.CustomData?.CostWhenComponentSelected?.[selectedComp.ComponentId] ||
            0;
          optionsCost -= prevFinishPrice;
        }

        selectedComp = variablePriceBaseCategory.Components.find(c => c.Name === '1');
        // add base size finish cost to option cost
        optionsCost +=
          +selectedFinishComp.CustomData?.CostWhenComponentSelected?.[selectedComp.ComponentId] ||
          0;
        //  Add base size cost to option cost
        optionsCost += +selectedComp.Cost;
      }
    }

    if (selectedComp) {
      baseCost += +selectedComp.Cost;
      optionsCost -= +selectedComp.Cost;
    }
  }

  const totalCost: number = optionsCost + baseCost;
  return { totalCost, optionsCost, baseCost, totalDiffFromOriginalPrices };
}

export function isCompactScreen(): boolean {
  const size = window.innerWidth;
  return size <= 830 ? true : false;
}

export function linkGenerator(urlQuery?: string): string {
  let link = window.location.href;

  if (link.includes('?')) {
    link += `&${urlQuery}`;
  } else {
    link += `?${urlQuery}`;
  }
  // const link = `${window.location.href}?${urlQuery}`;
  return link;
}

export function generateARLinkforQrCode(selectedIds?: number[]): string {
  let arLink = linkGenerator('fromQr=true');
  if (selectedIds) arLink = `${arLink}&componentIds=${JSON.stringify(selectedIds)}`;

  return arLink;
}

export function getCurrentUrl(): string {
  const { protocol, host, pathname } = window.location;
  return `${protocol}//${host + pathname}`;
}

export async function copyLinkToClipboard() {
  const url = getCurrentUrl();
  const success = await copyTextToClipboard(url);

  if (success) return 'Link copied to clipboard';
  else return 'Issue occurred while trying to copy link to clipboard';
}

export async function copyTextToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch {
    return false;
  }
}

export function getModelInstanceIdParam(): string {
  const modelInstanceId: any = QueryParams.Parse(['miid']);
  return modelInstanceId.miid || '';
}

export function checkVersionAndSettingForArButton(model: Model): boolean {
  const version = +model.BuildVersion;
  if (SettingsService.Get('EnableViewInAR') && version >= 3.8) {
    return true;
  } else {
    return false;
  }
}

export function convertDecimalToInchFraction(num: number) {
  const splitNumber = num.toString().split('.');
  const fractionValues = enumKeys(InchFractions);
  const decimalValues = enumValues(InchFractions);
  const wholeNumber = splitNumber[0];
  let fraction = fractionValues[decimalValues.indexOf(+`0.${splitNumber[1]}`)] || '';
  if (fraction) fraction = ` ${fraction}`;
  return `${wholeNumber}${fraction}"`;
}

export function isUrl(value: string) {
  return value.startsWith('http:') || value.startsWith('https:');
}

export function parseQueryParams(): QueryParameters {
  const qParams: any = QueryParams.Parse([
    'miid',
    'configuratorId',
    'sessionId',
    'componentIds',
    'fromQr'
  ]);

  return {
    modelInstanceId: qParams.miid || '',
    configuratorId: qParams.configuratorId || 0,
    sessionId: qParams.sessionId || 0,
    componentIds: (qParams.componentIds && JSON.parse(qParams.componentIds)) || [],
    fromQr: qParams.fromQr || false
  };
}

export function makeUrlFullDomain(relativeUrl: string) {
  relativeUrl = relativeUrl.startsWith('/') ? relativeUrl.slice(1) : relativeUrl;

  // if on local then use RT (for sending HTML to server for converting to PDF)
  const origin = window.location.origin.includes('localhost')
    ? 'https://configurator.ml3ds-rt-iconstage.com'
    : window.location.origin;

  return `${origin}/${relativeUrl}`;
}

export const getMiComponentsWithCategoryNames = (
  model: Model,
  mi: SessionModelInstance
): SessionModelInstanceItem[] => {
  const compIds = mi.Components.map(c => c.ComponentId);
  const matchingModelComponents = ModelUtilityService.GetComponentsByIds(compIds, model);

  const selectedCategoryComponentVMs = model.Categories.map(category => {
    let foundComp: Component;
    if (category.IsVisibleInMenu) {
      if (category.ChildCategories && category.ChildCategories.length) {
        category.ChildCategories.forEach(childCat => {
          if (childCat.IsVisibleInMenu) {
            const comp = matchingModelComponents.find(x => x.CategoryId === childCat.CategoryId);
            if (comp) foundComp = comp;
          }
        });
      } else {
        const comp = matchingModelComponents.find(x => x.CategoryId === category.CategoryId);
        if (comp) foundComp = comp;
      }

      // Use session component if available for correct pricing
      if (foundComp) {
        const sessionComponent = mi.Components.find(c => c.ComponentId == foundComp?.ComponentId);
        return new SessionModelInstanceItem({
          Id: foundComp?.ComponentId,
          Title: category?.Name,
          ItemName: foundComp?.Name,
          Description: foundComp?.Description,
          Value: sessionComponent?.Cost || foundComp?.Cost
        });
      }
    }
  }).filter(x => !!x);

  return selectedCategoryComponentVMs;
};

export const getMiAccessories = (
  model: Model,
  mi: SessionModelInstance
): SessionModelInstanceItem[] => {
  const selectedAccIds = mi.Accessories.flatMap(sa => sa.AccessoryId);
  const modelAccessories = model.AccessoryCategories.flatMap(acCat => acCat.Accessories);

  return selectedAccIds.map(accId => {
    const foundModelAcc = modelAccessories.find(acc => acc.AccessoryId === accId);
    if (foundModelAcc) {
      return new SessionModelInstanceItem({
        Id: foundModelAcc.AccessoryId,
        ItemName: foundModelAcc.Name,
        Value: foundModelAcc.Cost
      });
    }
  });
};

export const getFinalValuesWithPlumbLevelIncluded = (
  model: IModel,
  formValues: { [k: string]: string | number },
  plumbLevelValues?: { [k: string]: number }
) => {
  let formDefs = MeasurementService.GetMeasurementFormDefinitionsForModel(model);
  const mappedValues = { ...formValues };

  Object.entries(mappedValues).forEach(([name, val]) => {
    const foundDef = formDefs.find(fd => fd.name === name);
    if (
      plumbLevelValues &&
      foundDef instanceof DimensionQuestionDefinition &&
      foundDef.dynamicPlumbLevelDependents
    ) {
      const effectivePlumbValues = foundDef.dynamicPlumbLevelDependents(
        mappedValues['Shape'] as string
      );

      if (effectivePlumbValues.length) {
        let plTotal = 0;
        let valToNumber = +val;
        effectivePlumbValues.forEach(plVal => {
          const plDef = formDefs.find(pld => pld.name === plVal) as DimensionQuestionDefinition;
          let plumbLevelValue = plumbLevelValues[plVal];
          if (typeof plumbLevelValue === 'number' && plumbLevelValue !== 0) {
            if (
              plDef instanceof DimensionQuestionDefinition &&
              plDef.flipNegPosPlumbLevel &&
              plDef.flipNegPosPlumbLevel(formValues['Shape'] as string)
            ) {
              if (plumbLevelValue < 0) {
                plTotal += Math.abs(plumbLevelValue);
              } else {
                plTotal += -Math.abs(plumbLevelValue);
              }
            } else {
              plTotal += plumbLevelValue;
            }
          }
        });
        mappedValues[name] = valToNumber += plTotal;
      }
    }
  });
  return mappedValues;
};

export const getMiMeasurements = (model: Model, mi: SessionModelInstance) => {
  const formFields = MeasurementService.GetMeasurementFormDefinitionsForModel(model);
  const finalFormValues = getFinalValuesWithPlumbLevelIncluded(
    model,
    mi.MeasurementFormValues,
    mi.PlumbLevelValues
  );
  const mappedMiMeasurements = [];

  Object.entries(finalFormValues).forEach(([name, val], idx) => {
    if (val) {
      let mappedValue = val;
      const foundDef = formFields[name];
      if (foundDef instanceof DimensionQuestionDefinition) {
        mappedValue = convertDecimalToInchFraction(+mappedValue);
      }
      mappedMiMeasurements.push(
        new SessionModelInstanceItem({
          Id: idx,
          Title: foundDef.label,
          Value: mappedValue.toString()
        })
      );
    }
  });
  return mappedMiMeasurements;
};

export const getPlumbLevelDisplayInfo = (
  field: DimensionQuestionDefinition,
  values: { [k: string]: string | number },
  plValues: { [k: string]: number }
): { oopSvg: SVGGraphicsElement; oopLabel: string; oopValue: string; levelClassName: string } => {
  let oopValue = '';
  let oopLabel = '';
  let oopSvg;
  let levelClassName = '';

  if (plValues?.[field.name]) {
    const shape = values['Shape'] as string;
    const plType = field.plumbLevelType(shape);
    let isLeft = false;

    if (field.isDimensionOnTheLeft) {
      isLeft = field?.isDimensionOnTheLeft(shape);
    }

    // Set proper SVG
    if (plValues?.[field.name] > 0) {
      oopSvg = PlumbLevelPositive;
      if (plType === OutOfPlumbOrLevelType.Plumb && isLeft) oopSvg = PlumbLevelNegative;
    } else {
      oopSvg = PlumbLevelNegative;
      if (plType === OutOfPlumbOrLevelType.Plumb && isLeft) oopSvg = PlumbLevelPositive;
    }

    // Set label 2 conditions as there is a none option in the enum
    if (plType === OutOfPlumbOrLevelType.Plumb) oopLabel = 'Out of Plumb';
    if (plType === OutOfPlumbOrLevelType.Level) {
      oopLabel = 'Out of Level';
      if (plValues[field.name] > 0) {
        levelClassName = 'level-positive';
      } else {
        levelClassName = 'level-negative';
      }
    }

    oopValue = convertDecimalToInchFraction(plValues[field.name]);
    if (oopValue) oopValue = oopValue.replace('0', '');
  }

  return { oopSvg, oopLabel, oopValue, levelClassName };
};

export const getHotspotsFromModel = (model: IModel): ModelHotspotVM[] => {
  const customData: IModelCustomData = model.CustomData ? JSON.parse(model.CustomData) : {};
  if (customData.Hotspots) {
    return customData.Hotspots.map(hs => new ModelHotspotVM(hs));
  } else {
    return [];
  }
};
