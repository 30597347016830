import { Configurator, Model } from '../definitions/configurator.types';
import { ICartApi } from '../services/ApiIntegrationFactoryService';
import { GetAllSelectionsForEventData } from './helpers';

/** EventName will get prefixed with "configurator." */
export function PushToGoogleTagManager(eventName: string, data: object) {
  let dataLayer = (window as any).dataLayer;
  if (!dataLayer) dataLayer = [];

  const eventData = {
    event: 'configurator.' + eventName,
    currentLocation: window.location.href,
    ...data
  };
  // posting for clients that iframe us in and manage their own analytics
  window.parent.postMessage(eventData, '*');
  dataLayer.push(eventData);
}

declare const window: any;
class GoogleTagManagerService {
  private model: Model;
  private configurationSessionId: number;
  private configurationId: number;
  private sessionModelInstance: any;
  private cartApi: ICartApi;
  private configurator: Configurator;

  private apiStart: string;
  private apiEnd: string;
  private initalPageLoadGTMAlreadySent = false;
  private dataLayerInit = false;

  constructor() {}

  setModel(
    model: Model,
    configurationSessionId: number,
    configuratorId: number,
    sessionModelInstance: any,
    cartApi: ICartApi
  ) {
    this.model = model;
    this.configurationSessionId = configurationSessionId;
    this.configurationId = configuratorId;
    this.sessionModelInstance = sessionModelInstance;
    this.cartApi = cartApi;

    this.initializeDataLayer();
  }

  setConfigurator(configurator: Configurator, configurationSessionId: number) {
    this.configurationSessionId = configurationSessionId;
    this.configurator = configurator;

    this.model = null;
    this.sessionModelInstance = null;
    this.cartApi = null;

    this.initializeDataLayer();
  }

  private initializeDataLayer() {
    if (!this.dataLayerInit) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(this.getGTMData());

      this.loadGTM();
      this.dataLayerInit = true;
    }
  }

  private getGTMData(): GTMData {
    const gtmData = new GTMData();
    gtmData.ClientName = this.model?.ClientName || this.configurator?.ClientName;
    gtmData.ClientId = this.model?.ClientId || this.configurator?.ClientId;
    gtmData.ProjectName = this.model?.ProjectName || this.configurator?.ProjectName;
    gtmData.ProjectId = this.model?.ProjectId || this.configurator?.ProjectId;
    gtmData.ModelId = this.model?.ModelId;
    gtmData.ModelName = this.model?.PublicName || this.model?.Name;
    gtmData.ModelSku = this.model?.Sku;
    gtmData.ConfigurationId = this.configurator?.ConfiguratorId;
    gtmData.ConfigurationSessionId = this.configurationSessionId;
    gtmData.currentLocation = window.location.href;

    return gtmData;
  }

  addEvent(eventName: string, data?: object) {
    const date = new Date();

    const gtmData = this.getGTMData();
    PushToGoogleTagManager(eventName, {
      ...gtmData,
      selections: GetAllSelectionsForEventData(this.model, this.sessionModelInstance, this.cartApi),
      time: this.getDate(date),
      ...data
    });
  }

  sendModelLoad() {
    this.addEvent('ModelLoad', {
      selections: GetAllSelectionsForEventData(this.model, this.sessionModelInstance, this.cartApi)
    });
  }

  sendPageRequestBeforeLoadTimeStamp() {
    if (!this.initalPageLoadGTMAlreadySent) {
      this.addEvent('PageRequestBeforeLoad', {
        time: window.initialPageRequestBeforeLoadTimeStamp
      });
      this.initalPageLoadGTMAlreadySent = true;
    }
  }

  sendApiStartRequestTimestamp() {
    this.addEvent('ApiStartRequest', { time: this.apiStart });
  }

  sendApiEndRequestTimestamp() {
    this.addEvent('ApiEndRequest', { time: this.apiEnd });
  }

  startInitialModelApiRequestTime() {
    const date = new Date();
    this.apiStart = this.getDate(date);
  }

  endInitialModelApiRequestTime() {
    const date = new Date();
    this.apiEnd = this.getDate(date);
  }

  private getDate(date: Date): string {
    // purposefully sending in date param to prevent any JS closure issues or date reuse
    // as this method is called numerously within short bursts of time

    // eslint-disable-next-line max-len
    return `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}.${date.getUTCMilliseconds()}`;
  }

  loadGTM() {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      const f: any = d.getElementsByTagName(s)[0],
        j: any = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-WBC5W58');
  }
}

export default new GoogleTagManagerService();

class GTMData {
  constructor() {}
  ClientName: string;
  ClientId: number;
  ProjectName: string;
  ProjectId: number;
  ModelId: number;
  ModelName: string;
  ModelSku: string;
  ConfigurationId: number;
  ConfigurationSessionId: number;
  currentLocation: string;
}
