export enum OverlayType {
  ConfirmModal
}

export interface OverlayOptions {
  IsShowing: boolean;
  Type: OverlayType;
  Content?: JSX.Element;
}

type OverlaySubscriber = (
  options: OverlayOptions,
  resolveFn?: (value?: any | PromiseLike<any>) => void
) => void;

// TODO: maybe call this ToastService instead??
class OverlayService {
  private subscribers = new Map<string, OverlaySubscriber>();

  Subscribe(name: string, callback: OverlaySubscriber) {
    this.subscribers.set(name, callback);
  }

  Unsubscribe(name: string) {
    this.subscribers.delete(name);
  }

  // TODO: add generic Show/Trigger function

  Confirm(customContent?: JSX.Element): Promise<boolean> {
    const options: OverlayOptions = {
      IsShowing: true,
      Type: OverlayType.ConfirmModal,
      Content: customContent
    };
    return new Promise<boolean>(resolve => {
      this.subscribers.forEach(cb => cb(options, resolve));
    });
  }
}

export default new OverlayService();
