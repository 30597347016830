export type MeasurementFormValues = {
  formValues: { [k: string]: string | number };
  fractionValues: { [k: string]: number };
  plumbLevelValues?: { [k: string]: number };
} | null;

export class DimensionQuestionDefinition {
  name: string;
  getLabel: (shape: string) => string;
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  tooltip?: string;
  isDisabled?: (shape: string) => boolean;
  isHidden?: (shape: string) => boolean;
  useDynamicValue?: (shape: string) => boolean;
  isHiddenInOverview?: (shape: string) => boolean;
  flipNegPosPlumbLevel?: (shape: string) => boolean;
  getDimensionIdentityLabel?: (shape: string) => string;
  dynamicPlumbLevelDependents?: (shape: string) => string[];
  dynamicMaxValue?: (formState: MeasurementFormValues) => number;
  isDimensionOnTheLeft?: (shape: string) => boolean;
  dynamicValue?: (formState: MeasurementFormValues) => { [k: string]: number };

  isPlumbLevelDisabled = (formState: MeasurementFormValues, plumbLevelVal: number) => {
    return false;
  };

  showWhen = (selectedShape: string) => {
    return true;
  };

  getMaxValue(formState: MeasurementFormValues) {
    return this.dynamicMaxValue ? this.dynamicMaxValue(formState) : this.maxValue;
  }

  plumbLevelType = (shape: string) => {
    return OutOfPlumbOrLevelType.None;
  };

  isDimensionQuestion = true; //instanceof MeasurementFormDimensionQuestionDefinition wasn't working
  isShapeQuestion = false;

  constructor(data: Partial<DimensionQuestionDefinition>) {
    Object.assign(this, data);
  }
}

export class ShapeQuestionDefinition {
  name = 'Shape';
  label = 'Shape';
  options: Array<{ name: string; imageUrl: string }>;
  defaultValue = '';

  isDimensionQuestion = false;
  isShapeQuestion = true;

  constructor(data: Partial<ShapeQuestionDefinition>) {
    Object.assign(this, data);
  }
}

export type MeasurementFormDefinition = DimensionQuestionDefinition | ShapeQuestionDefinition;

export type MeasurementForms = Array<{
  sku: string;
  formDefinitions: Array<Partial<DimensionQuestionDefinition>>;
}>;

export enum OutOfPlumbOrLevelType {
  Level = 'Level',
  Plumb = 'Plumb',
  None = 'None'
}

export enum InchFractions {
  '<sup>1</sup>/<sub>16</sub>' = 0.0625,
  '<sup>1</sup>/<sub>8</sub>' = 0.125,
  '<sup>3</sup>/<sub>16</sub>' = 0.1875,
  '<sup>1</sup>/<sub>4</sub>' = 0.25,
  '<sup>5</sup>/<sub>16</sub>' = 0.3125,
  '<sup>3</sup>/<sub>8</sub>' = 0.375,
  '<sup>7</sup>/<sub>16</sub>' = 0.4375,
  '<sup>1</sup>/<sub>2</sub>' = 0.5,
  '<sup>9</sup>/<sub>16</sub>' = 0.5625,
  '<sup>5</sup>/<sub>8</sub>' = 0.625,
  '<sup>11</sup>/<sub>16</sub>' = 0.6875,
  '<sup>3</sup>/<sub>4</sub>' = 0.75,
  '<sup>13</sup>/<sub>16</sub>' = 0.8125,
  '<sup>7</sup>/<sub>8</sub>' = 0.875,
  '<sup>15</sup>/<sub>16</sub>' = 0.9375
}

export enum NegativeInchFractions {
  '- <sup>1</sup>/<sub>16</sub>' = -0.0625,
  '- <sup>1</sup>/<sub>8</sub>' = -0.125,
  '- <sup>3</sup>/<sub>16</sub>' = -0.1875,
  '- <sup>1</sup>/<sub>4</sub>' = -0.25,
  '- <sup>5</sup>/<sub>16</sub>' = -0.3125,
  '- <sup>3</sup>/<sub>8</sub>' = -0.375,
  '- <sup>7</sup>/<sub>16</sub>' = -0.4375,
  '- <sup>1</sup>/<sub>2</sub>' = -0.5,
  '- <sup>9</sup>/<sub>16</sub>' = -0.5625,
  '- <sup>5</sup>/<sub>8</sub>' = -0.625,
  '- <sup>11</sup>/<sub>16</sub>' = -0.6875,
  '- <sup>3</sup>/<sub>4</sub>' = -0.75,
  '- <sup>13</sup>/<sub>16</sub>' = -0.8125,
  '- <sup>7</sup>/<sub>8</sub>' = -0.875,
  '- <sup>15</sup>/<sub>16</sub>' = -0.9375
}

// TODO - copied from Converge - move to JS library
export function enumEntries<T>(t: T): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
  const entries = Object.entries(t);
  const plainStringEnum = entries.every(([key, value]) => typeof value === 'string');
  return (plainStringEnum ? entries : entries.filter(([k, v]) => typeof v !== 'string')) as any;
}

export function enumKeys<T>(t: T): ReadonlyArray<keyof T> {
  return enumEntries(t).map(([key]) => key);
}

export function enumValues<T>(t: T): ReadonlyArray<T[keyof T]> {
  const values = Object.values(t);
  const plainStringEnum = values.every(x => typeof x === 'string');
  return plainStringEnum ? values : values.filter(x => typeof x !== 'string');
}
