import {
  ConfiguratorSession,
  SessionAccessory,
  SessionCustomOptionRequest,
  SessionModelInstance
} from '../definitions/view-models';

import { IConfigurator, IModel } from '@ml/common';

export class ModelState {
  configuratorId = 0;
  model: IModel | null = null;
  modelQuantity: number;
  sessionAccessories = new Array<SessionAccessory>();
  sessionModelInstance: SessionModelInstance;
  customOptionRequests = new Array<SessionCustomOptionRequest>();
  isDataLoading = true;
  isModelLoading = false;
  isOffline = false;
  errorDuringLoad?: boolean;
  measurementFormValues: { [k: string]: string | number } = null;
  userHasInteracted? = false;
  outOfPlumb?: boolean;
  plumbLevelValues?: { [k: string]: number };

  constructor(data?: ModelState) {
    if (data) Object.assign(this, data);
  }
}

export class AppState {
  clientId = 0;
  showLoading = true;
  configurator: IConfigurator;
}
export class SessionState {
  session = new ConfiguratorSession();
  allSessionModels: IModel[] = [];
}
