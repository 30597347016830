export class QueryParams {
  static Parse(keys: string[]) {
    const qParams = new URLSearchParams(window.location.search);

    const parsedParams = {};
    keys.forEach(key => {
      const rawVal = qParams.get(key);
      if (rawVal) {
        const parsed = +rawVal;
        parsedParams[key] = isNaN(parsed) ? rawVal : parsed;
      }
    });

    return parsedParams;
  }
}
