import SessionOverviewService from '../services/SessionOverviewService';
import SettingsService from '../services/SettingsService';
import { Component, Model } from './configurator.types';
import { ClientIds } from './enums';
import { Guid } from './guid';

import { IAccessory, IModelHotspot } from '@ml/common';

export class ComponentStates {
  SelectedIds = new Array<number>();
  DisabledIds = new Array<number>();
  HiddenIds = new Array<number>();

  constructor(selectedIds?: number[], disabledIds?: number[]) {
    if (selectedIds) this.SelectedIds = selectedIds;
    if (disabledIds) this.DisabledIds = disabledIds;
  }
}

export class ConfiguratorSession {
  ConfiguratorSessionId = 0;
  ClientId: number;
  ProjectId: number;
  ConfiguratorId?: number;
  Content = new ConfiguratorSessionContent();

  constructor(session?: Partial<ConfiguratorSession>) {
    if (session) {
      Object.assign(this, session);
      if (session.Content) this.Content = new ConfiguratorSessionContent(session.Content);
    }
  }

  prepareForSaveToApi() {
    this.Content.ModelInstances.forEach(x => (x.FullQualityImageUrl = null));
  }

  /** For dumping into view during dev */
  toPrettyString() {
    const toPrint = {
      ...this,
      Content: {
        ...this.Content,
        ModelInstances: this.Content.ModelInstances.map(mi => {
          return { ...mi, ImageUrl: '' };
        })
      }
    };

    return JSON.stringify(toPrint, null, 5);
  }

  getGrandTotal(modelByIdLookup: Map<number, Model>) {
    if (!this.Content.ModelInstances.length) return 0;
    let total = this.Content.ModelInstances.filter(mi => modelByIdLookup.has(mi.ModelId))
      .map(mi => {
        const model = modelByIdLookup.get(mi.ModelId)!;
        const singleItemCost = SessionOverviewService.SingleItemCost(model, mi);
        return singleItemCost * mi.Quantity;
      })
      .reduce((sum, current) => sum + current, 0);
    const shippingAndHandling =
      this.ClientId !== ClientIds.FGI ? SettingsService.Get('ShippingAndHandlingFee') : null;
    if (shippingAndHandling) total += shippingAndHandling;
    return total;
  }

  getTotalQuantity() {
    if (!this.Content.ModelInstances.length) return 0;
    return this.Content.ModelInstances.map(mi => mi.Quantity).reduce((sum, curr) => sum + curr, 0);
  }

  getGrandTotalByComponentsAndAccessories(components: Component[], accessories: IAccessory[]) {
    if (!this.Content.ModelInstances.length) return 0;
    // assumes model instance quantity is always 1 but maybe this will change
    const componentTotal = components
      .map(component => {
        const sessionComponent = this.Content.ModelInstances.flatMap(c => c.Components).find(
          c => c.ComponentId === component.ComponentId
        );
        return sessionComponent ? sessionComponent.Quantity * component.Cost : 0;
      })
      .reduce((sum, current) => sum + current, 0);

    const allSessionAccessories = this.getAllSessionAccessories();
    const accessoryTotal = accessories
      .map(acc => {
        const sessionAccessory = allSessionAccessories.find(a => a.AccessoryId === acc.AccessoryId);
        return sessionAccessory ? sessionAccessory.Quantity * acc.Cost : 0;
      })
      .reduce((sum, current) => sum + current, 0);

    return componentTotal + accessoryTotal;
  }

  anyComponentOrAccessoryHasSalePrice(components: Component[], accessories: IAccessory[]) {
    if (!this.Content.ModelInstances.length) return 0;

    return (
      this.Content.ModelInstances.flatMap(m => m.Components)
        .map(c => components.find(x => x.ComponentId === c.ComponentId))
        .filter(Boolean)
        .some(c => !!c.CustomData.OriginalPrice) ||
      this.Content.ModelInstances.flatMap(m => m.Accessories)
        .map(a => accessories.find(x => x.AccessoryId === a.AccessoryId))
        .filter(Boolean)
        .some(c => !!c.CustomData.OriginalPrice) ||
      this.Content.Accessories.map(a => accessories.find(x => x.AccessoryId === a.AccessoryId))
        .filter(Boolean)
        .some(c => !!c.CustomData.OriginalPrice)
    );
  }

  getTotalQuantityByComponentsAndAccessories() {
    if (!this.Content.ModelInstances.length) return 0;
    // assumes model instance quantity is always 1 but maybe this will change
    const componentTotal = this.Content.ModelInstances.flatMap(mi => mi.Components)
      .map(c => c.Quantity)
      .reduce((sum, curr) => sum + curr, 0);

    const accessoryTotal = this.getAllSessionAccessories()
      .map(c => c.Quantity)
      .reduce((sum, curr) => sum + curr, 0);

    return componentTotal + accessoryTotal;
  }

  getAllSessionAccessories() {
    return [
      ...this.Content.Accessories,
      ...this.Content.ModelInstances.flatMap(x => x.Accessories)
    ];
  }
}

export class ConfiguratorSessionContent {
  User: SessionUser;
  ModelInstances: SessionModelInstance[] = [];
  SessionName = '';
  SendToClient = true;
  Notes = '';
  Accessories: SessionAccessory[] = [];

  constructor(json?: Partial<ConfiguratorSessionContent>) {
    if (json) {
      Object.assign(this, json);
      if (json.User) this.User = new SessionUser(json.User);
      if (json.ModelInstances && json.ModelInstances.length)
        this.ModelInstances = json.ModelInstances.map(x => new SessionModelInstance(x));
      if (json.Accessories?.length)
        this.Accessories = json.Accessories.map(x => new SessionAccessory(x));
    }
  }
}

export class SessionUser {
  Name = '';
  Email = '';
  Phone = '';
  Address = '';
  City = '';
  State = '';
  Zip = '';
  StoreName = '';
  StoreNumber = '';
  StoreEmail = '';

  constructor(json?: any) {
    if (json) Object.assign(this, json);
  }
}

export class SessionModelInstance {
  ModelInstanceId = '';
  ModelId = 0;
  Nickname = '';
  Notes = '';
  Components = new Array<SessionComponent>();
  CustomOptionRequests = new Array<SessionCustomOptionRequest>();
  Quantity = 0;
  Accessories = new Array<SessionAccessory>();
  MeasurementFormValues: { [k: string]: string | number };
  Cost?: number;
  OutOfPlumb?: boolean;
  PlumbLevelValues?: { [k: string]: number };

  /**Be aware the image here was likely saved at lower quality for compressed size.
   * Maybe use FullQualityImage instead */
  ImageUrl: string;
  /**Note this will not save back to database. Intentionally left out for size. */
  FullQualityImageUrl: string;

  constructor(json?: Partial<SessionModelInstance>, setNewId = false) {
    if (json) {
      Object.assign(this, json);

      if (json.Components?.length) {
        this.Components = json.Components.map((c: any) => new SessionComponent(c));
      }

      if (json.CustomOptionRequests?.length)
        this.CustomOptionRequests = json.CustomOptionRequests.map(
          (x: any) => new SessionCustomOptionRequest(x)
        );

      if (json.Accessories?.length)
        this.Accessories = json.Accessories.map(x => new SessionAccessory(x));
    }

    if (!this.ModelInstanceId || this.ModelInstanceId === Guid.Empty || setNewId) {
      this.ModelInstanceId = Guid.Generate();
    }
  }

  getRequestsCost(): number {
    return this.CustomOptionRequests.reduce((sum, req) => sum + req.Cost, 0);
  }
}

export class SessionCustomOptionRequest {
  Title = '';
  Description = '';
  Cost = 0;

  constructor(json?: any) {
    if (json) Object.assign(this, json);
  }
}

export class SessionComponent {
  ComponentId: number;
  Quantity = 1;
  Cost?: number;

  constructor(json?: SessionComponent) {
    if (json) Object.assign(this, json);
  }

  static FromComponent(component: Component, existingQuantity?: number): SessionComponent {
    const sc = new SessionComponent();
    sc.ComponentId = component.ComponentId;
    sc.Quantity =
      existingQuantity ||
      component.CustomData?.DefaultQuantity ||
      component.CustomData?.MinimumQuantity ||
      1;
    sc.Cost = component.Cost;
    return sc;
  }

  static ConvertFromComponents(components: Component[], modelInstance?: SessionModelInstance) {
    return components.map(c =>
      SessionComponent.FromComponent(
        c,
        modelInstance?.Components.find(x => x.ComponentId === c.ComponentId)?.Quantity
      )
    );
  }
}

export class SessionAccessory {
  AccessoryId: number;
  Quantity: number;
  IsDisabled? = false;
  Cost?: number;
  Name?: string;

  constructor(json?: SessionAccessory) {
    if (json) Object.assign(this, json);
  }

  static FromAccessory(accessory: IAccessory, isDisabled = false) {
    const sa = new SessionAccessory();
    sa.AccessoryId = accessory.AccessoryId;
    sa.Quantity =
      accessory.CustomData?.DefaultQuantity || accessory.CustomData?.MinimumQuantity || 1;
    sa.IsDisabled = isDisabled;
    sa.Cost = accessory.Cost;
    sa.Name = accessory.Name;
    return sa;
  }
}

export class ModelHotspotVM implements IModelHotspot {
  Id: string;
  GeometryUUID: string;
  IsVideo: boolean;
  ExternalUrl: string;
  MediaUrl: string;
  X: number;
  Y: number;
  Z: number;

  constructor(hs?: IModelHotspot) {
    Object.assign(this, hs);
    if (!this.Id) {
      this.Id = Guid.Generate();
    }
  }
}
