import React from 'react';

type LoadingOverlayProps = {};
type LoadingOverlayState = {};

class LoadingOverlay extends React.Component<LoadingOverlayProps, LoadingOverlayState> {
  render() {
    return (
      <div className="page-overlay loading-container">
        <div className="threedotloader">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    );
  }
}
export default LoadingOverlay;
