export enum AppPage {
  SeriesList,
  ModelDetail,
  SessionOverview,
  IconList,
  ModelPrint,
  OverviewPrint,
  TestPrint,
  DefaultRoute
}

export enum ARCompatibility {
  InvalidBrowser = 'InvalidBrowser',
  InvalidOS = 'InvalidOS',
  ARCompatible = 'ARCompatible'
}

export enum ModelDetailStateToggle {
  IsOSVersionAlertOpen = 'isOSVersionAlertOpen',
  IsNativeBrowserAlertModalOpen = 'isNativeBrowserAlertModalOpen',
  IsQRModalOpen = 'isQRModalOpen',
  ShowExportDetailsModal = 'showExportDetailsModal',
  NoSessionStoredModal = 'noSessionStoredModal',
  PendingResetClear = 'pendingResetClear',
  IsMobileSessionMenuOpen = 'isMobileSessionMenuOpen',
  IsMobileComponentsMenuOpen = 'isMobileComponentsMenuOpen',
  IsMobileCategoriesMenuOpen = 'isMobileCategoriesMenuOpen',
  IsMobileResourcesMenuOpen = 'isMobileResourcesMenuOpen',
  PendingSaveAndCopy = 'pendingSaveAndCopy',
  PendingSaveAndAddNew = 'pendingSaveAndAddNew',
  IsPopularComboOverlayVisible = 'isPopularComboOverlayVisible',
  IsInFullscreen = 'isInFullscreen',
  ShowMeasurementFormModal = 'showMeasurementFormModal'
}

export enum ModelActionTypes {
  MODEL_ERROR = 'MODEL_ERROR',
  UPDATE_MODEL = 'UPDATE_MODEL',
  RESET_MODEL = 'RESET_MODEL',
  UPDATE_MODEL_AND_SESSION = 'UPDATE_MODEL_AND_SESSION'
}

export enum AppActionTypes {
  LOADING = 'LOADING',
  LOAD_CONFIGURATOR_DATA = 'LOAD_CONFIGURATOR_DATA',
  LOAD_ALL_DATA = 'LOAD_ALL_DATA'
}

export enum SessionActionTypes {
  LOAD_SESSION = 'LOAD_SESSION',
  UPDATE_MODEL_INSTANCES = 'UPDATE_MODEL_INSTANCES',
  UPDATE_SESSION = 'UPDATE_SESSION'
}

export enum ClientIds {
  MediaLab = 4,
  Sitmatic = 100,
  FGI = 154
}
