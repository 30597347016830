import { sortBySortOrder } from '@ml/common';
import { Category, Component, Model, Series } from '../definitions/configurator.types';
import ModelUtilityService from '../services/ModelUtilityService';

export class CategoryMenuItem {
  CategoryId: number;
  Name: string;
  ParentCategoryId: number;
  SortOrder: number;
  ChildCategories: CategoryMenuItem[] = [];
  Components: ComponentMenuItem[] = [];
  IsVisible = true;
  ThumbnailImageUrl?: string;

  constructor(category: Category, modelDirectory?: string) {
    this.CategoryId = category.CategoryId;
    this.Name = category.Name;
    this.ParentCategoryId = category.ParentCategoryId;
    this.SortOrder = category.SortOrder;
    this.IsVisible = category.IsVisibleInMenu;

    this.ChildCategories = category.ChildCategories.map(
      c => new CategoryMenuItem(c, modelDirectory)
    ).sort(sortBySortOrder);

    this.Components = category.Components.filter(x => !x.CustomData.HiddenFromMenu)
      .map(cp => new ComponentMenuItem(cp, modelDirectory))
      .sort(sortBySortOrder);

    if (category.CustomData?.ThumbnailImage) {
      this.ThumbnailImageUrl = `${modelDirectory}/${category.CustomData.ThumbnailImage}`;
    }
  }
}

export class ComponentMenuItem {
  ComponentId: number;
  Name: string;
  Description: string;
  Sku: string;
  Cost: number;
  SortOrder: number;
  ImageUrl: string;

  constructor(component: Component, modelDirectory?: string) {
    this.ComponentId = component.ComponentId;
    this.Name = component.Name;
    this.Description = component.Description;
    this.Sku = component.Sku;
    this.Cost = component.Cost;
    this.SortOrder = component.SortOrder;

    if (component.PreviewFilename && modelDirectory) {
      this.ImageUrl = `${modelDirectory}/${component.PreviewFilename}`;
    }
  }
}

/**Could represent a series or a model */
export class SeriesCardContent {
  // Properties shared by models and series
  Description?: string;
  ImageUrl?: string;
  Name?: string;
  // series specific properties
  ChildrenSeries?: SeriesCardContent[] = [];
  ParentSeriesId?: number;
  SeriesId?: number;
  Models?: SeriesCardContent[] = [];
  HeroModelId?: number;
  Json?: string;
  // model specific properties
  ModelId?: number;

  constructor() {}

  static FromModel(content: Model): SeriesCardContent {
    const vm = new SeriesCardContent();
    vm.Description = content.Description;
    vm.Name = content.PublicName ? content.PublicName : content.Name;
    vm.ModelId = content.ModelId;

    vm.ImageUrl = ModelUtilityService.GetFullPathToImage(content);

    return vm;
  }

  static FromSeries(content: Series, configuratorDirectoryPath: string): SeriesCardContent {
    const vm = new SeriesCardContent();
    vm.Description = content.Description;
    vm.Name = content.Name;
    vm.ParentSeriesId = content.ParentSeriesId;
    vm.SeriesId = content.SeriesId;
    vm.HeroModelId = content.HeroModelId;
    vm.Json = content.Json;

    vm.Models = content.Models.map(m => SeriesCardContent.FromModel(m));
    vm.ChildrenSeries = content.ChildrenSeries.map(s =>
      SeriesCardContent.FromSeries(s, configuratorDirectoryPath)
    );

    if (content.Json && JSON.parse(content.Json)?.ImagePath) {
      vm.ImageUrl = configuratorDirectoryPath + JSON.parse(content.Json).ImagePath;
    } else if (content.HeroModelId) {
      const foundModel = [
        ...content.Models,
        ...content.ChildrenSeries.flatMap(cs => cs.Models)
      ].find(m => m.ModelId === content.HeroModelId);

      if (foundModel) {
        vm.ImageUrl = ModelUtilityService.GetFullPathToImage(foundModel);
      }
    }

    return vm;
  }
}

export class SessionModelInstanceItem {
  Id: number;
  Title: string;
  ItemName: string;
  Description?: string;
  Value?: number | string; // price or measurement form value
  constructor(data) {
    Object.assign(this, data);
  }
}
