import React, { DOMAttributes } from 'react';

import '@ml/web-components';
import { MlIcon } from '@ml/web-components/types/ml-icon/ml-icon';

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any; class: string }>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'ml-icon': CustomElement<MlIcon>;
    }
  }
}

/** Wrapper for ml-icon from @ml/web-components */
export default function Icon(props: { name: string; className?: string; onClick?: () => void }) {
  return <ml-icon name={props.name} class={props.className} onClick={props.onClick}></ml-icon>;
}
