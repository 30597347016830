import { TinyColor } from '@ctrl/tinycolor';

import SvgIcons from '../assets/SvgIcons';
import { Lookup } from '../utility/lookup';
import { convertUnderscoresToBoldHtml } from '../v2/ModelDetailPage/helpers';
import SettingsService from './SettingsService';

import { SettingFieldType } from '@ml/common';
import { overrideLibraryIcon } from '@ml/web-components';
import parse from 'html-react-parser';

class ThemeService {
  UseLegacyTheme = false;
  LegacyThemeClientIds: number[] = [Lookup.ClientIds.Kohler, Lookup.ClientIds.FGI];

  UseLegacyModelTemplateAlternate1 = false;

  ShowExtraPrintSections = false;
  SpecialPrintClientId = Lookup.ClientIds.Sitmatic;
  SpecsShownInSideMenuDesktop = true;

  private customFonts = ['Helvetica Neue LT Pro', 'Gotham Narrow Light', 'Myriad', 'Roboto'];

  // Secondary fonts supplied by clients used along with primary fonts set in Converge
  // Fonts have to be included in the solution and customFonts array to be used as secondary fonts.
  ClientSpecificSecondaryFonts: { [k: string]: string[] } = {
    [Lookup.ClientIds.Sitmatic]: ['Myriad']
  };

  Labels = {
    MenuResources: 'Resources & Downloads',
    SaveCurrentView: '',
    SaveOriginalView: '',
    SavePdf: '',
    SeriesListLink: 'All Products',
    SessionOverviewLink: 'Session Overview',
    MenuInstructions: 'Instructions',
    SessionName: 'Session Name',
    SessionId: 'Session Id',
    Session: 'Session',
    SeriesListHeading: 'Select a Series to Design',
    SeriesModelListHeading: 'Select a Model to Design',
    SeriesCardNavigationButtonLabel: 'Select',
    ModelCardNavigationButtonLabel: 'Design',
    PopularCombinations: 'Popular Configurations',
    NextButton: 'Next',
    SaveModelButton: 'Add',
    SavedModelButton: 'Added',
    UpdateModelButton: 'Update'
  };

  Texts = {
    ProductInformationModalHeader: 'Product Information',
    NoModelSavedToSessionModal: '',
    SessionOverviewInstructions: '',
    SeriesInstructionsText: '',
    SessionEditModalText: ''
  };

  InitializeFromSettings(clientId: number) {
    this.setClientTemplateSettings(clientId);
    this.setAndLoadFonts(clientId);
    this.swapInIcons();
    this.setLabels();
    this.setTexts();
    this.applySettingsToCssVariables(clientId);
    this.loadExternalScripts();
  }

  private setClientTemplateSettings(clientId: number) {
    // Start using new theme in RT for all clients as we prepare for transtion
    this.UseLegacyTheme = this.LegacyThemeClientIds.includes(clientId);

    this.UseLegacyModelTemplateAlternate1 = clientId === Lookup.ClientIds.Kohler;
    this.ShowExtraPrintSections = this.SpecialPrintClientId === clientId;

    const specsLocation = SettingsService.Get('SpecsInfoLocation') as string;
    this.SpecsShownInSideMenuDesktop = specsLocation.includes('side menu');
  }

  private setLabels() {
    this.Labels.MenuResources = SettingsService.Get('MenuResourcesLabel');
    this.Labels.SaveCurrentView = SettingsService.Get('MenuDownloadViewLabel');
    this.Labels.SaveOriginalView = SettingsService.Get('MenuDownloadOriginalViewLabel');
    this.Labels.SavePdf = SettingsService.Get('MenuSavePDFLabel');
    this.Labels.SeriesListLink = SettingsService.Get('NavigateToSeriesListLabel');
    this.Labels.SessionOverviewLink = SettingsService.Get('NavigateToSessionOverviewLabel');
    this.Labels.MenuInstructions = SettingsService.Get('MenuInstructionsLabel');
    this.Labels.SessionName = SettingsService.Get('SessionNameLabel');
    this.Labels.SessionId = SettingsService.Get('SessionIdLabel');
    this.Labels.Session = SettingsService.Get('SessionLabel');
    this.Labels.SeriesListHeading = SettingsService.Get('SeriesListHeader');
    this.Labels.SeriesModelListHeading = SettingsService.Get('SeriesModelListHeader');
    this.Labels.SeriesCardNavigationButtonLabel = SettingsService.Get(
      'SeriesCardNavigationButtonLabel'
    );
    this.Labels.ModelCardNavigationButtonLabel = SettingsService.Get(
      'ModelCardNavigationButtonLabel'
    );
    this.Labels.PopularCombinations = SettingsService.Get('PopularConfigsLabel');
    this.Labels.NextButton = SettingsService.Get('NextButtonLabel');
    this.Labels.SaveModelButton = SettingsService.Get('SaveModelButtonText');
    this.Labels.SavedModelButton = SettingsService.Get('SavedModelButtonText');
    this.Labels.UpdateModelButton = SettingsService.Get('UpdateModelButtonText');
  }

  private setTexts() {
    this.Texts.ProductInformationModalHeader = SettingsService.Get(
      'ProductInformationModalHeaderText'
    );
    this.Texts.NoModelSavedToSessionModal = convertUnderscoresToBoldHtml(
      SettingsService.Get('NoModelSavedToSessionModalText')
    );
    this.Texts.SessionOverviewInstructions = SettingsService.Get('SessionOverviewInstructions');
    this.Texts.SeriesInstructionsText = SettingsService.Get('SeriesListInstructions');
    this.Texts.SessionEditModalText = SettingsService.Get('EditModelInstanceModalText');
  }

  GetCurrentTemplateClassName() {
    return this.UseLegacyModelTemplateAlternate1 ? 'alternate1-template' : '';
  }

  private setAndLoadFonts(clientId: number) {
    const relevantSettings = SettingsService.GetAllForFonts();
    const root = document.documentElement;
    relevantSettings.forEach(setting => {
      const familyName = setting.Value;
      root.style.setProperty(this.getSafeVariableName(setting.UIName), `'${familyName}'`);
      const link: HTMLLinkElement = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = this.getCssLink(familyName);
      document.head.appendChild(link);
    });

    if (this.ClientSpecificSecondaryFonts[clientId]) {
      this.ClientSpecificSecondaryFonts[clientId].forEach((fontFamily: string) => {
        const link: HTMLLinkElement = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = this.getCssLink(fontFamily);
        document.head.appendChild(link);
      });
    }
  }

  private getCssLink(familyName?: string) {
    if (familyName) {
      if (this.customFonts.includes(familyName)) {
        const linkSafeName = familyName.replace(/ /g, '');
        return `/fonts/${linkSafeName}.css`;
      } else {
        const linkSafeName = familyName.replace(/ /g, '+');
        return `https://fonts.googleapis.com/css?family=${linkSafeName}:400,700&display=swap`;
      }
    }
  }

  private swapInIcons() {
    const relevantSettings = SettingsService.GetAllForIcons();

    // Hack way of carrying over icon settings to the new icon-library
    // Ex - the setting states InfoTooltip but we now use circle_i from the library
    const svgIconNamesToLibraryNames = new Map<string, string>([
      ['InfoTooltip', 'circle_i'],
      ['ResetArrow', 'reset_arrow']
    ]);

    relevantSettings
      .filter(x => !!x.UIValue)
      .forEach(setting => {
        SvgIcons[setting.UIName] = parse(setting.UIValue);

        overrideLibraryIcon(svgIconNamesToLibraryNames.get(setting.UIName), setting.UIValue);
      });
  }

  private applySettingsToCssVariables(clientId: number) {
    const relevantSettings = SettingsService.GetAllForCssVariables();
    const root = document.documentElement;

    if (clientId === Lookup.ClientIds.Sitmatic) {
      root.style.setProperty('--logo-background', '#54565b');
    }

    relevantSettings.forEach(setting => {
      root.style.setProperty(this.getSafeVariableName(setting.UIName), setting.Value);

      if (setting.FieldType === SettingFieldType.ColorPicker) {
        const color = new TinyColor(setting.Value);
        ColorTransparencyModifiers.forEach(modifier => {
          root.style.setProperty(
            this.getSafeVariableName(setting.UIName + modifier.Suffix),
            color.setAlpha(modifier.Opacity).toRgbString()
          );
        });
        ColorLuminanceModifiers.forEach(modifier => {
          root.style.setProperty(
            this.getSafeVariableName(setting.UIName + modifier.Suffix),
            color.lighten(modifier.PercentLighter).setAlpha(1).toRgbString()
          );
        });
      }
    });
  }

  private getSafeVariableName(name: string): string {
    let varName = name.toLowerCase();
    varName = varName.startsWith('--') ? varName : `--${varName}`;
    return varName;
  }

  private loadExternalScripts() {
    const scriptSrcs: string[] = SettingsService.Get('ExternalScriptsToLoad');
    if (!scriptSrcs || !scriptSrcs.length) return;

    scriptSrcs.forEach(src => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      document.body.appendChild(script);
    });
  }
}

export interface IColorTransparencyModifier {
  Suffix: string;
  Opacity: number;
}

export const ColorTransparencyModifiers: IColorTransparencyModifier[] = [
  {
    Suffix: '-alpha-low',
    Opacity: 0.2
  },
  {
    Suffix: '-alpha-medium',
    Opacity: 0.5
  },
  {
    Suffix: '-alpha-high',
    Opacity: 0.9
  }
];

export interface IColorLuminanceModifier {
  Suffix: string;
  PercentLighter: number;
}

export const ColorLuminanceModifiers: IColorLuminanceModifier[] = [
  {
    Suffix: '-lightest',
    PercentLighter: 25
  },
  {
    Suffix: '-lighter',
    PercentLighter: 10
  },
  {
    Suffix: '-darker',
    PercentLighter: -10
  }
];

export default new ThemeService();
