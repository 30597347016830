import {
  IConfigurator,
  IModel,
  ISetting,
  SettingFieldType,
  SettingsCategoryTitle
} from '@ml/common';

class SettingsService {
  private settings: ISetting[] = [];
  private isOffline = process.env.REACT_APP_IS_OFFLINE === 'true';

  Initialize(configurator: IConfigurator, model: IModel) {
    if (configurator?.Settings?.length) {
      this.settings = configurator.Settings;
    } else if (model?.Settings?.length) {
      this.settings = model.Settings;
    }
  }

  /**
   * Get the value of the setting by setting name
   * @param settingName Name of the setting
   * @param category Only needed if the name is repeated across multiple categories
   */
  Get(settingName: string, category?: Category): any {
    const setting = this.getSettingByName(settingName, category);
    return setting ? this.getValue(setting) : undefined;
  }

  private getSettingByName(settingName: string, category: Category): ISetting | undefined {
    const settingsToSearch = category
      ? this.settings.filter(s => s.SettingsCategoryName === category)
      : this.settings;
    const setting = settingsToSearch.find(s => s.Name.toLowerCase() === settingName.toLowerCase());
    return setting;
  }

  private getValue(setting: ISetting): any {
    if (!setting || !setting.Value) return null;

    switch (setting.FieldType) {
      case SettingFieldType.FileUpload:
        const relativePath = JSON.parse(setting.Value)[0];
        if (!relativePath) return null;
        return `${process.env.REACT_APP_PRODUCT_DATA_BASE_URL}${relativePath}`;
      case SettingFieldType.CheckBox:
        return setting.Value.toLowerCase() === 'true';
      case SettingFieldType.DropDown:
        return setting.Value.toLowerCase() === 'none' ? null : setting.Value;
      case SettingFieldType.NumberBox:
        return Number(setting.Value);
      case SettingFieldType.Icon:
        return setting.UIValue;
      case SettingFieldType.ExpandingSet:
        return JSON.parse(setting.Value);
      default:
        return setting.Value;
    }
  }

  GetAllForFonts() {
    return this.settings.filter(x => x.UIName && x.FieldType === SettingFieldType.Font);
  }

  GetAllForIcons(): ISetting[] {
    return this.settings.filter(x => x.UIName && x.FieldType === SettingFieldType.Icon);
  }

  GetAllForCssVariables(): ISetting[] {
    return this.settings.filter(
      x =>
        x.UIName && x.FieldType !== SettingFieldType.Icon && x.FieldType !== SettingFieldType.Font
    );
  }

  IsOffline(): boolean {
    return this.isOffline;
  }
}

export type Category = SettingsCategoryTitle;
export default new SettingsService();
