export function convertUnderscoresToBoldHtml(text: string) {
  if (!text) return;
  let updated = '';
  let boldStarted = false;

  for (let i = 0; i < text.length; i++) {
    if (text[i + 1] && text[i] === '_' && text[i + 1] === '_') {
      i++;
      if (boldStarted) {
        updated += '</b>';
        boldStarted = false;
      } else {
        updated += '<b>';
        boldStarted = true;
      }
    } else {
      updated += text[i];
    }
  }

  return updated;
}

export const debounce = (func: Function, delay: number) => {
  let timeoutId: number;

  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};
