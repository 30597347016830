import { Component, Model } from '../definitions/configurator.types';
import {
  ConfiguratorSessionContent,
  SessionAccessory,
  SessionComponent,
  SessionModelInstance
} from '../definitions/view-models';
import { PushToGoogleTagManager } from '../utility/google-tag-manager';
import ModelUtilityService from './ModelUtilityService';

import { IAccessory } from '@ml/common';

export interface ICartApi {
  sendSessionByComponent: (
    sessionContent: ConfiguratorSessionContent,
    components?: Component[],
    accessories?: IAccessory[]
  ) => Promise<boolean>;

  getCartItemsFromComponents: (
    components: Component[],
    sessionComponents: SessionComponent[]
  ) => ICartItem[];

  getCartItemsFromAccessories: (
    accessories: IAccessory[],
    sessionAccessories: SessionAccessory[]
  ) => ICartItem[];

  sendSessionByModel: (
    sessionContent: ConfiguratorSessionContent,
    model: Model
  ) => Promise<boolean>;

  sendModelDataOnly?: (model: Model, selectedComponentIds: number[]) => Promise<boolean>;
}

export class BaseCartApi implements ICartApi {
  sendSessionByComponent(
    sessionContent: ConfiguratorSessionContent,
    components?: Component[],
    accessories?: IAccessory[]
  ): Promise<boolean> {
    throw Error('Not implemented');
  }

  getCartItemsFromComponents(
    components: Component[],
    sessionComponents: SessionComponent[]
  ): ICartItem[] {
    throw Error('Not implemented');
  }

  getCartItemsFromAccessories(
    accessories: IAccessory[],
    sessionAccessories: SessionAccessory[]
  ): ICartItem[] {
    throw Error('Not implemented');
  }

  sendSessionByModel(sessionContent: ConfiguratorSessionContent, model: Model): Promise<boolean> {
    throw Error('Not implemented');
  }

  sendModelDataOnly(model: Model, selectedComponentIds: number[]): Promise<boolean> {
    throw Error('Not implemented');
  }
}

export interface ICartItem {
  Quantity: number;
  Sku: string; // for analytics event only
  Cost: number; // for analytics event only
  Name: string;
}

interface IGenericSessionCartMessage {
  Url: string;
  SessionName: string;
  ModelName: string;
  SelectedSpecs: IGenericCartComponentSpec[];
  BasePrice: number;
  OptionsPrice: number;
  TotalModelPrice: number;
  TotalPrice: number;
  Quantity: number;
}

interface IGenericModelCartMessage {
  ModelName: string;
  SelectedSpecs: IGenericCartComponentSpec[];
  BasePrice: number;
  OptionsPrice: number;
  TotalPrice: number;
}

interface IGenericCartComponentSpec {
  SpecName: string;
  Cost: number;
}

class KohlerCartItem implements ICartItem {
  AtgProductId: string;
  AtgSkuId: string;
  Quantity: number;
  Sku: string; // for analytics event only
  Cost: number; // for analytics event only
  Name: string;
}

class KohlerCartApi extends BaseCartApi {
  constructor(public BaseApiUrl: string) {
    super();
    if (BaseApiUrl.endsWith('/')) BaseApiUrl = BaseApiUrl.slice(0, -1);
  }

  async sendSessionByComponent(
    sessionContent: ConfiguratorSessionContent,
    components: Component[],
    accessories: IAccessory[]
  ): Promise<boolean> {
    const sessionComponents = sessionContent.ModelInstances.flatMap(x => x.Components);
    const sessionAccessories = [
      ...sessionContent.Accessories,
      ...sessionContent.ModelInstances.flatMap(x => x.Accessories)
    ];
    const cartItems = [
      ...this.getCartItemsFromComponents(components, sessionComponents),
      ...this.getCartItemsFromAccessories(accessories, sessionAccessories)
    ];
    PushToGoogleTagManager('AddToCart', { cartItems });

    const itemParams = this.getAsQueryParam(cartItems);
    // not sure if valid case or the right way to handle it, but don't send if nothing to send
    if (!itemParams) return false;

    // Ex: 'https://ecom.qa2.us.kohler.com/us/cart/cart.jsp?item=424116,389560,1&item=420211,360666,1&op=add'
    const fullUrl = `${this.BaseApiUrl}/cart.jsp?${itemParams}&op=add`;
    window.top.location.href = fullUrl;

    // technically won't reach this cause of navigation above
    return true;
  }

  getCartItemsFromComponents(
    components: Component[],
    sessionComponents: SessionComponent[]
  ): KohlerCartItem[] {
    return components
      .map(component => {
        const sessionComponent = sessionComponents.find(
          c => c.ComponentId === component.ComponentId
        );
        if (
          !component.CustomData?.AtgProductId ||
          !component.CustomData?.AtgSkuId ||
          !sessionComponent?.Quantity
        )
          return undefined;

        return {
          AtgProductId: component.CustomData.AtgProductId,
          AtgSkuId: component.CustomData.AtgSkuId,
          Quantity: sessionComponent.Quantity,
          Sku: component.Sku,
          Cost: component.Cost,
          Name: component.Name
        } as KohlerCartItem;
      })
      .filter(Boolean);
  }

  getCartItemsFromAccessories(
    accessories: IAccessory[],
    sessionAccessories: SessionAccessory[]
  ): KohlerCartItem[] {
    return accessories
      .map(accessory => {
        const sessionComponent = sessionAccessories.find(
          x => x.AccessoryId === accessory.AccessoryId
        );
        if (
          !accessory.CustomData?.AtgProductId ||
          !accessory.CustomData?.AtgSkuId ||
          !sessionComponent?.Quantity
        )
          return undefined;

        return {
          AtgProductId: accessory.CustomData.AtgProductId,
          AtgSkuId: accessory.CustomData.AtgSkuId,
          Quantity: sessionComponent.Quantity,
          Sku: accessory.SKU,
          Cost: accessory.Cost,
          Name: accessory.Name
        } as KohlerCartItem;
      })
      .filter(Boolean);
  }

  private getAsQueryParam(cartItems: KohlerCartItem[]): string {
    // item={ATGProductId},{ATGSKUId},{QTY}
    return cartItems.map(x => `item=${x.AtgProductId},${x.AtgSkuId},${x.Quantity}`).join('&');
  }
}

class GenericCartApi extends BaseCartApi {
  constructor() {
    super();
  }

  async sendSessionByModel(sessionContent: ConfiguratorSessionContent, model: Model) {
    // Have to loop through the model instance to allow for multiples on mobile session
    const cartModels = sessionContent.ModelInstances.map((m: SessionModelInstance) => {
      const propComponents = ModelUtilityService.GetComponentsByIds(
        m.Components.map(c => c.ComponentId),
        model
      );

      const selectedComponents: IGenericCartComponentSpec[] = this.getAllSelectedCategoryComponents(
        propComponents,
        model
      ).map(c => ({ SpecName: c.Name, Cost: c.Cost }));

      const basePrice = ModelUtilityService.GetBaseCombination(model).Cost || 0;
      const optionsPrice = ModelUtilityService.GetComponentsCost(
        model,
        propComponents.map(c => c.ComponentId)
      );
      const message: IGenericSessionCartMessage = {
        Url: window.location.href,
        SessionName: sessionContent.SessionName,
        ModelName: model.Name,
        SelectedSpecs: selectedComponents,
        BasePrice: basePrice,
        OptionsPrice: optionsPrice,
        TotalModelPrice: basePrice + optionsPrice,
        TotalPrice: (basePrice + optionsPrice) * m.Quantity,
        Quantity: m.Quantity
      };
      return message;
    });

    window.parent.postMessage(cartModels, '*');
    return true;
  }

  getCartItemsFromComponents(
    components: Component[],
    sessionComponents: SessionComponent[]
  ): ICartItem[] {
    return components
      .map(component => {
        const sessionComponent = sessionComponents.find(
          c => c.ComponentId === component.ComponentId
        );
        if (!sessionComponent?.Quantity) return undefined;

        return {
          Quantity: sessionComponent.Quantity,
          Sku: component.Sku,
          Cost: component.Cost,
          Name: component.Name
        } as ICartItem;
      })
      .filter(Boolean);
  }

  getCartItemsFromAccessories(
    accessories: IAccessory[],
    sessionAccessories: SessionAccessory[]
  ): ICartItem[] {
    return accessories
      .map(accessory => {
        const sessionComponent = sessionAccessories.find(
          x => x.AccessoryId === accessory.AccessoryId
        );
        if (!sessionComponent?.Quantity) return undefined;

        return {
          Quantity: sessionComponent.Quantity,
          Sku: accessory.SKU,
          Cost: accessory.Cost,
          Name: accessory.Name
        } as ICartItem;
      })
      .filter(Boolean);
  }

  async sendModelDataOnly(model: Model, selectedComponentIds: number[]) {
    const propComponents = ModelUtilityService.GetComponentsByIds(selectedComponentIds, model);
    const selectedComponents: IGenericCartComponentSpec[] = this.getAllSelectedCategoryComponents(
      propComponents,
      model
    ).map(c => ({ SpecName: c.Name, Cost: c.Cost }));
    const basePrice = ModelUtilityService.GetBaseCombination(model).Cost || 0;
    const optionsPrice = ModelUtilityService.GetComponentsCost(
      model,
      propComponents.map(c => c.ComponentId)
    );
    const message: IGenericModelCartMessage = {
      ModelName: model.Name,
      SelectedSpecs: selectedComponents,
      BasePrice: basePrice,
      OptionsPrice: optionsPrice,
      TotalPrice: basePrice + optionsPrice
    };

    window.parent.postMessage(message, '*');
    return true;
  }

  private getAllSelectedCategoryComponents(components: Component[], model: Model) {
    const selectedComponents = model.Categories.map(category => {
      const categoryComponents: Component[] = [];
      if (category.ChildCategories && category.ChildCategories.length) {
        category.ChildCategories.forEach(childCat => {
          const comp = components.find(x => x.CategoryId === childCat.CategoryId);
          if (comp) categoryComponents.push(comp);
        });
      } else {
        const comp = components.find(x => x.CategoryId === category.CategoryId);
        if (comp) categoryComponents.push(comp);
      }
      return categoryComponents;
    }).flat();
    return selectedComponents;
  }
}

class ApiIntegrationFactoryService {
  getCartApi(apiUrl: string = ''): ICartApi {
    if (!apiUrl) return new GenericCartApi();

    apiUrl = apiUrl.toLowerCase();
    if (apiUrl.includes('kohler')) {
      return new KohlerCartApi(apiUrl);
    } else {
      return new GenericCartApi();
    }
  }
}

export default new ApiIntegrationFactoryService();
