/* eslint-disable max-len */
import {
  Category,
  Component,
  ComponentCombination,
  ComponentConditionalPart,
  Model
} from '../definitions/configurator.types';
import { ComponentStates } from '../definitions/view-models';
import ComponentActionService from './component-actions/ComponentActionService';
import ModelUtilityService from './ModelUtilityService';

import { ComponentState, sortBySortOrder } from '@ml/common';

/** Processes logic around Components and utilizes ComponentActionService to trigger visibility in actual WebGL model */
export default class ComponentConditionalManager {
  static async SelectComponent(
    sourceComponent: Component,
    model: Model,
    currentStates: ComponentStates,
    checkAutoselections = true
  ): Promise<ComponentStates> {
    // make copy right away so never modifying parameter
    let newSelectedIds = [...currentStates.SelectedIds];

    // already selected -- do nothing
    if (currentStates?.SelectedIds?.some(id => id === sourceComponent.ComponentId))
      return { ...currentStates };

    // turn off everything else from sourceComponent's category
    const category = this.getCategoryById(sourceComponent.CategoryId, model);
    if (category) {
      let componentsTurningOff = category.Components;
      if (category.ParentCategoryId) {
        const parentCat = this.getCategoryById(category.ParentCategoryId, model);
        if (parentCat) componentsTurningOff = parentCat.ChildCategories.flatMap(x => x.Components);
      }

      ComponentActionService.TurnOffComponents(componentsTurningOff);
      newSelectedIds = newSelectedIds.filter(
        id => !componentsTurningOff.some(x => x.ComponentId === id)
      );
    }

    // turn on sourceComponent
    await ComponentActionService.TurnOnComponent(sourceComponent, newSelectedIds, model);
    newSelectedIds.push(sourceComponent.ComponentId);

    let newStates = this.ProcessNewStates(newSelectedIds, model);

    if (checkAutoselections)
      newStates = await this.MakeNeededAutoselections(sourceComponent, model, newStates);

    newStates = this.SwapOutDisabledAndHiddenIfNeeded(newStates, model);
    newStates = await this.EnsureEveryCategoryHasSelection(model, newStates);

    return newStates;
  }

  static async SelectMultipleComponents(
    sourceComponents: Component[],
    model: Model,
    currentStates: ComponentStates
  ) {
    let newSelectedIds = [...currentStates.SelectedIds];
    const compsToTurnOn = [];

    sourceComponents.forEach(sComp => {
      const category = this.getCategoryById(sComp.CategoryId, model);
      if (category) {
        let componentsTurningOff = category.Components;

        ComponentActionService.TurnOffComponents(componentsTurningOff);
        newSelectedIds = newSelectedIds.filter(
          id => !componentsTurningOff.some(x => x.ComponentId === id)
        );
      }

      // Add src comp to turnOn array and add Id to the selectedIds
      compsToTurnOn.push(sComp);
      newSelectedIds.push(sComp.ComponentId);
    });
    // Turn on components
    await ComponentActionService.TurnOnComponents(compsToTurnOn);

    let newStates = this.ProcessNewStates(newSelectedIds, model);

    for (const comp of compsToTurnOn) {
      newStates = await this.MakeNeededAutoselections(comp, model, newStates);
    }

    newStates = this.SwapOutDisabledAndHiddenIfNeeded(newStates, model);
    return newStates;
  }

  private static async MakeNeededAutoselections(
    sourceComponent: Component,
    model: Model,
    states: ComponentStates
  ) {
    const componentIdsNeedingAutoselect = this.DetermineTargetIdsOfState(
      ComponentState.ON,
      states,
      model,
      sourceComponent.ComponentId
    ).filter(id => !states?.SelectedIds.includes(id));

    for (const compId of componentIdsNeedingAutoselect) {
      const comp = ModelUtilityService.GetComponentById(compId, model);
      states = await this.SelectComponent(comp, model, states, false);
    }
    return states;
  }

  private static SwapOutDisabledAndHiddenIfNeeded(states: ComponentStates, model: Model) {
    const needsSwap = [
      ...states.DisabledIds.filter(id => states?.SelectedIds.some(sId => sId === id)),
      ...states.HiddenIds.filter(id => states?.SelectedIds.some(sId => sId === id))
    ];

    if (needsSwap.length) {
      const newSelectedIds = states?.SelectedIds.filter(id => !needsSwap.some(nId => nId === id));

      const compsToSwap = ModelUtilityService.GetComponentsByIds(needsSwap, model);

      const compIdsHiddenFromMenu = ModelUtilityService.GetAllComponents(model)
        .filter(x => x.CustomData?.HiddenFromMenu)
        .map(x => x.ComponentId);

      const allCompIdsToAvoid = [
        ...states.DisabledIds,
        ...states.HiddenIds,
        ...compIdsHiddenFromMenu
      ];
      compsToSwap.forEach(comp => {
        const category = this.getCategoryById(comp.CategoryId, model);
        if (category) {
          // try to first fall back to a component with the same name
          const swapTarget =
            category.Components.find(
              c => c.Name === comp.Name && !allCompIdsToAvoid.some(id => id === c.ComponentId)
            ) ??
            category.Components.sort(sortBySortOrder).find(
              x => !allCompIdsToAvoid.some(id => id === x.ComponentId)
            );

          if (swapTarget) {
            ComponentActionService.TurnOnComponent(swapTarget, newSelectedIds, model);
            newSelectedIds.push(swapTarget.ComponentId);
          }
        }

        ComponentActionService.TurnOffComponents([comp]);
      });

      return this.ProcessNewStates(newSelectedIds, model);
    }

    return states;
  }

  private static async EnsureEveryCategoryHasSelection(model: Model, states: ComponentStates) {
    if (states.SelectedIds.length === model.Categories.length) return states;

    const newSelectedIds = [...states.SelectedIds];
    const hiddenAndDisabledIds = [...states.DisabledIds, ...states.HiddenIds];
    const awaitables = [];

    model.Categories.forEach(cat => {
      const comps = [
        ...cat.Components.sort(sortBySortOrder),
        ...cat.ChildCategories.sort(sortBySortOrder).flatMap(x =>
          x.Components.sort(sortBySortOrder)
        )
      ];
      if (!comps.some(x => states.SelectedIds.some(id => id === x.ComponentId))) {
        const availableComp = comps.filter(
          x => !hiddenAndDisabledIds.some(id => id === x.ComponentId)
        )[0];
        if (availableComp) {
          awaitables.push(
            ComponentActionService.TurnOnComponent(availableComp, states.SelectedIds, model)
          );
          newSelectedIds.push(availableComp.ComponentId);
        }
      }
    });

    await Promise.all(awaitables);

    return this.ProcessNewStates(newSelectedIds, model);
  }

  static ProcessNewStates(selectedComponentIds: number[], model: Model): ComponentStates {
    const newStates = new ComponentStates([...selectedComponentIds]);
    newStates.DisabledIds = this.DetermineTargetIdsOfState(
      ComponentState.DISABLED,
      newStates,
      model
    );
    newStates.HiddenIds = this.DetermineTargetIdsOfState(
      ComponentState.HIDDENINMENU,
      newStates,
      model
    );
    return newStates;
  }

  static DetermineTargetIdsOfState(
    state: ComponentState,
    currentStates: ComponentStates,
    model: Model,
    sourceComponentId?: number
  ): number[] {
    if (!model.ComponentConditionals) return [];

    const conditionalsOfState = model.ComponentConditionals.filter(
      x =>
        x.Target.State === state &&
        (!sourceComponentId || x.Sources.some(s => s.ComponentId === sourceComponentId))
    );
    const conditionalsTriggered = conditionalsOfState.filter(x =>
      this.AreConditionsMet(x.Sources, currentStates?.SelectedIds)
    );

    return conditionalsTriggered.map(x => x.Target.ComponentId);
  }

  private static AreConditionsMet(
    conditionalParts: ComponentConditionalPart[],
    selectedIds: number[]
  ): boolean {
    const onConditionals = conditionalParts.filter(x => x.State === ComponentState.ON);
    const offConditionals = conditionalParts.filter(x => x.State === ComponentState.OFF);
    return (
      onConditionals.every(x => selectedIds.some(id => id === x.ComponentId)) &&
      offConditionals.every(x => selectedIds.every(id => id !== x.ComponentId))
    );
  }

  /**
   *  Attempts to lookup Default combo and set it on. Falls back to Base combo if no Default found.
   *  Returns new ComponentStates
   */
  static async SetInitialCombination(model: Model): Promise<ComponentStates | null> {
    const initialCombo = this.GetInitialCombination(model);

    if (initialCombo) {
      return await ComponentConditionalManager.TurnOnCombination(initialCombo, model);
    }

    return null;
  }

  static async SetToBaseCombination(model: Model): Promise<ComponentStates | null> {
    const baseCombo = model.ComponentCombinations.find(x => x.IsBase);

    if (baseCombo) {
      return await ComponentConditionalManager.TurnOnCombination(baseCombo, model);
    }

    return null;
  }

  /** Triggers visibility of all components in the given combination AND hides all sibling components */
  static async TurnOnCombination(
    combination: ComponentCombination,
    model: Model
  ): Promise<ComponentStates> {
    const disabledIds = this.DetermineTargetIdsOfState(
      ComponentState.DISABLED,
      new ComponentStates([...combination.ComponentIds]),
      model
    );
    const hiddenIds = this.DetermineTargetIdsOfState(
      ComponentState.HIDDENINMENU,
      new ComponentStates([...combination.ComponentIds]),
      model
    );

    combination.ComponentIds = combination.ComponentIds.map(componentId => {
      if (!disabledIds.includes(componentId) && !hiddenIds.includes(componentId))
        return componentId;

      // else find first available component in that component's category to make sure category has a selection
      const category = ModelUtilityService.GetCategoryForComponent(model, componentId);
      const availableComponents = category.Components.filter(
        c => !disabledIds.includes(c.ComponentId) && !hiddenIds.includes(c.ComponentId)
      );
      return availableComponents.length ? availableComponents[0].ComponentId : null;
    }).filter(Boolean);

    return await this.TurnOnComponentSet(combination.ComponentIds, model);
  }

  /** Triggers visibility of all components in the given set of ids AND hides all sibling components */
  static async TurnOnComponentSet(componentIds: number[], model: Model): Promise<ComponentStates> {
    const allComponents = ModelUtilityService.GetAllComponents(model);

    // giggity
    const needsTurnOn = allComponents.filter(x => componentIds.some(id => id === x.ComponentId));
    const needsTurnOff = allComponents.filter(x => componentIds.every(id => id !== x.ComponentId));

    ComponentActionService.TurnOffComponents(needsTurnOff);
    await ComponentActionService.TurnOnComponents(needsTurnOn);

    return this.ProcessNewStates(componentIds, model);
  }

  /**
   *  Attempts to lookup Default combo. Falls back to Base combo if no Default found.
   */
  static GetInitialCombination(model: Model): ComponentCombination | null {
    let initialCombo = model.ComponentCombinations.find(x => x.IsDefault);
    if (!initialCombo) initialCombo = model.ComponentCombinations.find(x => x.IsBase);

    if (!initialCombo) {
      initialCombo = this.autogenerateBaseCombination(model, initialCombo);
    }

    return initialCombo || null;
  }

  // if no Combinations exist for this Model then autogenerate one by picking the first Component
  // from each Category
  private static autogenerateBaseCombination(
    model: Model,
    initialCombo: ComponentCombination | undefined
  ) {
    if (!model.Categories.length) return undefined;

    const componentIds = model.Categories.map(x => {
      const firstComp = x.Components.sort((a, b) => a.SortOrder - b.SortOrder)[0];
      return firstComp ? firstComp.ComponentId : 0;
    }).filter(Boolean);
    initialCombo = {
      ComponentCombinationId: 0,
      IsDefault: false,
      IsPopular: false,
      Cost: 0,
      Name: '',
      Description: '',
      PreviewFilename: '',
      ComponentIds: componentIds,
      IsBase: true,
      ModelId: model.ModelId
    };
    model.ComponentCombinations.push(initialCombo);
    return initialCombo;
  }

  static IsBaseCombinationCurrentlySet(model: Model, currentStates: ComponentStates): boolean {
    const baseCombo = model.ComponentCombinations.find(x => x.IsBase);
    if (baseCombo) return this.IsCombinationCurrentlySet(baseCombo, currentStates);
    else return false;
  }

  static IsInitialCombinationCurrentlySet(model: Model, currentStates: ComponentStates): boolean {
    const initialCombo = this.GetInitialCombination(model);
    if (initialCombo) return this.IsCombinationCurrentlySet(initialCombo, currentStates);
    else return false;
  }

  static IsCombinationCurrentlySet(
    combination: ComponentCombination,
    currentStates: ComponentStates
  ): boolean {
    return combination.ComponentIds.every(compId =>
      currentStates?.SelectedIds.some(id => id === compId)
    );
  }

  /** We get to assume categories are only nested 1 deep */
  private static getCategoryById(categoryId: number, model: Model): Category | undefined {
    const category = model.Categories.find(x => x.CategoryId === categoryId);
    if (category) return category;
    else {
      const allChildCategories = model.Categories.filter(x => x.ChildCategories?.length).flatMap(
        cat => cat.ChildCategories
      );

      return allChildCategories.find(x => x.CategoryId === categoryId);
    }
  }
}
