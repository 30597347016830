import { Component, Model } from '../../definitions/configurator.types';
import { IComponentActionServce } from './IComponentActionService';

/** Interface between Graphics and application. Pure actions ONLY, no rules logic applied */
class ComponentActionService implements IComponentActionServce {
  _realService: IComponentActionServce;

  get realService() {
    if (!this._realService)
      throw new Error('ComponentActionService not provided with real service');
    return this._realService;
  }

  Provide(service: IComponentActionServce) {
    this._realService = service;
  }

  TurnOffComponents(components: Component[]) {
    this.realService.TurnOffComponents(components);
  }

  TurnOnComponents(components: Component[]) {
    return this.realService.TurnOnComponents(components);
  }

  TurnOnComponent(component: Component, otherSelectedComponentIds?: number[], model?: Model) {
    if (this.realService.TurnOnComponent) {
      return this.realService.TurnOnComponent(component, otherSelectedComponentIds, model);
    } else return this.TurnOnComponents([component]);
  }

  UnloadResources() {
    this.realService.UnloadResources();
  }
}

export default new ComponentActionService();
