import structuredClone from '@ungap/structured-clone';

import { Category, Model } from '../definitions/configurator.types';
import { QueryParameters, UrlNumberParameters } from '../definitions/url-paramerter.types';
import { ConfiguratorSession, SessionModelInstance } from '../definitions/view-models';
import { ModelState } from '../state/initialStates';
import GoogleTagManagerService from '../utility/google-tag-manager';
import ApiGateway from './ApiGateway';
import ApiIntegrationFactoryService from './ApiIntegrationFactoryService';
import ModelUtilityService from './ModelUtilityService';
import SessionService from './SessionService';
import SettingsService from './SettingsService';
import ThemeService from './ThemeService';

import { IModel, IModelCustomData, ModelType } from '@ml/common';

class AppDataService {
  // SessionStorage for Configurator Sessions
  public localSessionStorageKey = 'configurator-session';
  private allowedAccessToSessionStorage = false;
  constructor() {
    try {
      if (window.sessionStorage) this.allowedAccessToSessionStorage = true;
    } catch {
      console.warn('Not allowed access to window.sessionStorage');
      this.allowedAccessToSessionStorage = false;
    }
  }

  async fetchModelData(modelId: number, queryparams: QueryParameters) {
    if (modelId) {
      const { modelInstanceId, configuratorId, sessionId } = queryparams;

      GoogleTagManagerService.startInitialModelApiRequestTime();
      const modelRequest = ApiGateway.GetModelById(modelId);

      // only getting Session for analytics data
      const sessionRequest = SessionService.Get(sessionId);
      try {
        let [model, session] = await Promise.all([modelRequest, sessionRequest]);

        GoogleTagManagerService.endInitialModelApiRequestTime();

        let sessionModelInstance;
        if (model && this.isModelDataValid(model)) {
          if (session && modelInstanceId) {
            sessionModelInstance = this.getSessionModelInstance(modelInstanceId, session);
          }

          const cartApi = ApiIntegrationFactoryService.getCartApi(
            SettingsService.Get('CartApiUrl')
          );

          GoogleTagManagerService.setModel(
            model,
            session?.ConfiguratorSessionId,
            configuratorId,
            sessionModelInstance,
            cartApi
          );

          GoogleTagManagerService.sendPageRequestBeforeLoadTimeStamp();
          GoogleTagManagerService.sendApiStartRequestTimestamp();
          GoogleTagManagerService.sendApiEndRequestTimestamp();

          return new ModelState({
            model,
            configuratorId,
            modelQuantity: sessionModelInstance ? sessionModelInstance.Quantity : 1,
            customOptionRequests: sessionModelInstance
              ? sessionModelInstance.CustomOptionRequests
              : [],
            sessionAccessories: sessionModelInstance ? sessionModelInstance.Accessories : [],
            isDataLoading: false,
            isModelLoading: true,
            isOffline: SettingsService.IsOffline(),
            sessionModelInstance: sessionModelInstance,
            measurementFormValues: sessionModelInstance
              ? sessionModelInstance.MeasurementFormValues
              : null,
            outOfPlumb: sessionModelInstance ? sessionModelInstance.OutOfPlumb : null,
            plumbLevelValues: sessionModelInstance ? sessionModelInstance.PlumbLevelValues : null
          });
        } else {
          const errorState = new ModelState();
          errorState.errorDuringLoad = true;
          return errorState;
        }
      } catch (error) {
        console.error(error);
        const errorState = new ModelState();
        errorState.errorDuringLoad = true;
        return errorState;
      }
    }
  }

  async fetchInitialData(urlParams: UrlNumberParameters, queryParams: QueryParameters) {
    let configuratorId = queryParams.configuratorId
      ? queryParams.configuratorId
      : urlParams.configuratorId;
    const sessionId = queryParams.sessionId ? queryParams.sessionId : urlParams.sessionId;
    let { modelId } = urlParams;
    const { modelInstanceId } = queryParams;

    if (SettingsService.IsOffline()) {
      if (!configuratorId) configuratorId = await ApiGateway.GetConfiguratorIdByConfigFile();
      if (!modelId) modelId = await ApiGateway.GetModelIdByConfigFile();
    }

    try {
      // Get all data based on url
      let [model, configurator, session] = await Promise.all([
        modelId && this.getModel(modelId),
        configuratorId && ApiGateway.GetConfiguratorById(configuratorId),
        this.GetSession(sessionId)
      ]);

      if (!model && session?.Content.ModelInstances.length) {
        model = await this.getModel(session.Content.ModelInstances[0].ModelId);
      }

      SettingsService.Initialize(configurator, model);

      if (model) {
        // HACK -- need to call prepareModel AFTER settings have initialized
        // so pricing multiplier can be applied... its also called inside ApiGateway :(
        model = this.prepareModel(model);
        ApiGateway.storedModels.set(model.ModelId, model);
      }

      const clientId = model ? model.ClientId : configurator.ClientId;
      if (clientId) ThemeService.InitializeFromSettings(clientId);

      const sessionData = new ConfiguratorSession(session);
      sessionData.ClientId = clientId;
      //If model is fetched validate and end analytics for request time
      if (model) {
        sessionData.ProjectId = model.ProjectId;
        GoogleTagManagerService.endInitialModelApiRequestTime();
        let sessionModelInstance: SessionModelInstance;
        if (this.isModelDataValid(model)) {
          if (session && modelInstanceId) {
            sessionModelInstance = this.getSessionModelInstance(modelInstanceId, session);
          }

          const cartApi = ApiIntegrationFactoryService.getCartApi(
            SettingsService.Get('CartApiUrl')
          );

          GoogleTagManagerService.setModel(
            model,
            session?.ConfiguratorSessionId,
            configuratorId,
            sessionModelInstance,
            cartApi
          );
        } else {
          throw new Error('Model is invalid');
        }
      }

      if (configurator?.ConfiguratorId) {
        sessionData.ProjectId = configurator.ProjectId;
        sessionData.ConfiguratorId = configurator.ConfiguratorId;
      }

      // If session load session models to Gateway cache
      if (sessionData?.ConfiguratorId) {
        await this.prepareSessionModels(sessionData);
      }

      // Set service current for use in v1
      SessionService.SetCurrent(sessionData);

      GoogleTagManagerService.sendPageRequestBeforeLoadTimeStamp();
      GoogleTagManagerService.sendApiStartRequestTimestamp();
      GoogleTagManagerService.sendApiEndRequestTimestamp();

      return { model, configurator, session: sessionData };
    } catch (err) {
      console.error(err);
      return 'Failed to load Configurator Data';
    }
  }

  // Doing this just for logging purposes on fetching the model
  private getModel(modelId: number): Promise<IModel> {
    GoogleTagManagerService.startInitialModelApiRequestTime();
    return ApiGateway.GetModelById(modelId);
  }

  isModelDataValid(model: Model | undefined): boolean {
    return !!(
      model &&
      model.Categories &&
      model.ComponentCombinations &&
      (model.Type !== ModelType.Gltf || model.GltfFilename)
    );
  }

  getSessionModelInstance(
    instanceId: string,
    session: ConfiguratorSession
  ): SessionModelInstance | undefined {
    if (!instanceId) return;
    return session.Content.ModelInstances.find(x => x.ModelInstanceId === instanceId);
  }

  prepareModel(model: Model): Model {
    // need structuredClone polyfill pkg for browser used by PDF renderer in ApiCore
    let updatedModel = structuredClone(model) as Model;
    updatedModel = this.checkForMissingPresets(updatedModel);
    updatedModel = ModelUtilityService.ApplyPricingAdjustments(updatedModel);
    return updatedModel;
  }

  async prepareSessionModels(session: ConfiguratorSession): Promise<Model[]> {
    const modelIds = session.Content.ModelInstances.map(mi => mi.ModelId).filter(
      (id, i, array) => array.indexOf(id) === i
    );
    // grabbing all session models here with the prepare function instead of the component.
    return Promise.all(modelIds.map(id => ApiGateway.GetModelById(id)));
  }

  checkForMissingPresets(model: Model): Model {
    const updatedModel = { ...model };
    updatedModel.ComponentCombinations.forEach(combo => {
      // check to see if the lengths match between the categories and componentCombination ids
      if (combo.ComponentIds.length !== updatedModel.Categories.length) {
        updatedModel.Categories.forEach(cat => {
          const categoryComponents = [
            ...cat.Components,
            ...cat.ChildCategories.flatMap(x => x.Components)
          ];
          if (
            categoryComponents.length &&
            !categoryComponents.some(x => combo.ComponentIds.includes(x.ComponentId))
          )
            combo.ComponentIds.push(categoryComponents[0].ComponentId);
        });
      }
    });
    return updatedModel;
  }

  public SaveSessionToSessionStorage(session: ConfiguratorSession) {
    if (!this.allowedAccessToSessionStorage) return;
    sessionStorage.setItem(this.localSessionStorageKey, JSON.stringify(session));
  }

  public async GetSession(sessionId?: number): Promise<ConfiguratorSession> {
    let rawSession;
    if (this.allowedAccessToSessionStorage) {
      rawSession = sessionStorage.getItem(this.localSessionStorageKey);
    }
    if (rawSession) {
      rawSession = JSON.parse(rawSession);
    }
    if (rawSession && rawSession.ConfiguratorSessionId === sessionId) {
      return rawSession;
    } else if (sessionId) {
      return ApiGateway.GetSessionById(sessionId);
    } else {
      return new ConfiguratorSession();
    }
  }
}

export default new AppDataService();
