import { Model } from '../definitions/configurator.types';
import { ConfiguratorSession, SessionModelInstance } from '../definitions/view-models';
import { getPricingInfo } from '../utility/helpers';
import ApiGateway from './ApiGateway';
import { IPdf } from './PdfService';
import SettingsService from './SettingsService';
import ThemeService from './ThemeService';

import { IConfigurator } from '@ml/common';

class SessionOverviewService {
  public SingleItemCost(model: Model, mi: SessionModelInstance): number {
    return getPricingInfo(
      model,
      mi.Components.map(c => c.ComponentId),
      mi.CustomOptionRequests,
      mi.Accessories,
      mi.Components
    ).totalCost;
  }

  public GetTotalQuantity(session: ConfiguratorSession): number {
    if (session.Content.ModelInstances.length) {
      return session.Content.ModelInstances.flatMap(x => x.Quantity).reduce((a, b) => a + b);
    }
  }

  public GetTotalSessionCost(session: ConfiguratorSession, configurator: IConfigurator): number {
    const allModels = configurator.Series.flatMap(x => x.Models);
    const modelByIdLookup = new Map<number, Model>();
    allModels.forEach(m => modelByIdLookup.set(m.ModelId, m));

    return session.getGrandTotal(modelByIdLookup);
  }

  public async SendDetailsEmail(session: ConfiguratorSession, configuratorId?: number, pdf?: IPdf) {
    if (!session?.Content?.User) return;
    const recipients = [session.Content.User.Email];
    const salesEmail = SettingsService.Get('SalesEmailAddress');
    if (salesEmail) recipients.push(salesEmail);
    if (session.Content.User.StoreEmail) recipients.push(session.Content.User.StoreEmail);

    const mail = {
      recipients: recipients.join(','),
      topcontent: this.GetDetailsEmailContent(session, configuratorId),
      subject: `Configurator ${ThemeService.Labels.Session} Details`,
      logoImageUrl: this.GenerateLogoImageLink(),
      useTemplate: false,
      isHtml: true
    };

    if (pdf) {
      mail.useTemplate = true;
      const formData = new FormData();
      formData.set('mailDTO', JSON.stringify(mail));
      formData.set(pdf.name, pdf.blob, pdf.name);
      return ApiGateway.Post('/api/email/form-data', formData);
    } else {
      return ApiGateway.Post('/api/email/generic-email', mail);
    }
  }

  private GetDetailsEmailContent(session: ConfiguratorSession, configuratorId?: number): string {
    const tent = session.Content;
    const sessionLink = this.GenerateSessionLink(session, configuratorId);
    let html = `
    <h1>${tent.SessionName}</h1>
    <h4>${ThemeService.Labels.SessionId} ${session.ConfiguratorSessionId}</h4>
    <div style="display: block; width: 100%; text-align: center;">
      <a href="${sessionLink}" target="_blank" style="color:blue">Click here to view Configurator ${
      ThemeService.Labels.Session
    }</a>
    </div>
    <br />    
    ${tent.Notes ? `<h2>Notes: ${tent.Notes}</h2>` : ''}
   `;

    if (tent.User) {
      html += `
      <br />
      ${tent.User.Name ? `<h2>Name: ${tent.User.Name}</h2>` : ''}
      ${tent.User.Email ? `<h2>Email: ${tent.User.Email}</h2>` : ''}
      ${tent.User.Phone ? `<h2>Phone: ${tent.User.Phone}</h2>` : ''}
      ${tent.User.Address ? `<h2>Address: ${tent.User.Address}</h2>` : ''}
      ${tent.User.City ? `<h2>City: ${tent.User.City}</h2>` : ''}
      ${tent.User.State ? `<h2>State: ${tent.User.State}</h2>` : ''}
      ${tent.User.Zip ? `<h2>Zip: ${tent.User.Zip}</h2>` : ''}
      `;
    }

    if (tent.User.StoreName) {
      html += `
      <br/>
      <h1>Store Information</h1>
      <h2>Store Name: ${tent.User.StoreName}</h2>
      ${tent.User.StoreNumber ? `<h2>Store #: ${tent.User.StoreNumber}</h2>` : ''}
      ${tent.User.StoreEmail ? `<h2>Store Email: ${tent.User.StoreEmail}</h2>` : ''}
      `;
    }
    return html;
  }

  private GenerateSessionLink(session: ConfiguratorSession, configuratorId?: number): string {
    const { protocol, host, pathname } = window.location;
    if (configuratorId) {
      return `${protocol}//${host}/${configuratorId}/session/${session.ConfiguratorSessionId}`;
    } else {
      return `${protocol}//${host + pathname}`;
    }
  }

  private GenerateLogoImageLink(): string {
    const logo = SettingsService.Get('CompanyLogo');
    return logo ? logo : '';
  }
}

export default new SessionOverviewService();
