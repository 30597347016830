import React, { lazy, Suspense } from 'react';
import { matchPath } from 'react-router-dom';

import GlobalConfirmModal from './components/shared/GlobalConfirmModal';
import LoadingOverlay from './components/shared/LoadingOverlay';
import { InitialData } from './definitions/app.route.types';
import { UrlStringParameters } from './definitions/url-paramerter.types';
import AppDataService from './services/AppDataService';
import ThemeService from './services/ThemeService';
import {
  HasMajorPerformanceCaveat,
  IsBrowserIncompatible,
  parseQueryParams
} from './utility/helpers';

const App_v1 = lazy(() => import('./App_v1'));
const App_v2 = lazy(() => import('./App_v2'));

type AppState = {
  hasAcknowledgedPerformanceIssue: boolean;
  showLoading: boolean;
  initialData: InitialData;
  showError: boolean;
};

class App extends React.Component {
  state: AppState = {
    hasAcknowledgedPerformanceIssue: false,
    showLoading: true,
    initialData: null,
    showError: false
  };

  componentDidMount() {
    const urlParams = this.getUrlParams();
    AppDataService.fetchInitialData(urlParams, parseQueryParams())
      .then(data => {
        if (typeof data === 'string') this.setState({ showLoading: false, showError: true });
        else this.setState({ showLoading: false, initialData: data });
      })
      .catch(err => {
        this.setState({ showLoading: false, showError: true });
        console.error(err);
      });
  }

  handleOnAcknowledgeAndContinue = () => {
    this.setState({ hasAcknowledgedPerformanceIssue: true });
  };

  render() {
    if (IsBrowserIncompatible() && !location.href.includes('/print/')) {
      return (
        <div id="app-root">
          <div className="flex column justify-center align-center">
            <h2 style={{ maxWidth: '85%', textAlign: 'center', marginTop: '3rem' }}>
              It looks like the browser you are using is not compatible. Please use a modern browser
              and try again.
            </h2>
          </div>
        </div>
      );
    } else if (
      HasMajorPerformanceCaveat() &&
      !this.state.hasAcknowledgedPerformanceIssue &&
      !location.href.includes('/print/')
    ) {
      return (
        <div id="app-root">
          <div className="flex column justify-center align-center">
            <h2 style={{ maxWidth: '85%', textAlign: 'center', marginTop: '3rem' }}>
              It looks like your PC might not be able to handle 3D models so you may experience some
              performance issues.
            </h2>
            <h4
              style={{
                maxWidth: '85%',
                textAlign: 'center',
                marginTop: '1rem',
                marginBottom: '1rem'
              }}
            >
              Note: This could be because of an out of date browser version, please make sure your
              browser is up to date.
            </h4>
            <button
              style={{ border: '1px solid #afafaf', padding: '1px 6px', borderRadius: '3px' }}
              onClick={this.handleOnAcknowledgeAndContinue}
            >
              Acknowledge & Continue
            </button>
          </div>
        </div>
      );
    } else {
      if (this.state.showLoading) {
        return <LoadingOverlay />;
      }

      if (this.state.showError) {
        return (
          <div id="app-root">
            <div className="flex column justify-center align-center">
              <h2 style={{ maxWidth: '85%', textAlign: 'center', marginTop: '3rem' }}>
                Sorry, we ran into an issue trying to load this configurator.
              </h2>
            </div>
          </div>
        );
      }

      return (
        <div id="app-root" className={ThemeService.GetCurrentTemplateClassName()}>
          <Suspense fallback={<LoadingOverlay />}>
            {ThemeService.UseLegacyTheme ? (
              <App_v1 initialData={this.state.initialData} />
            ) : (
              <App_v2 initialData={this.state.initialData} />
            )}
          </Suspense>

          <GlobalConfirmModal />
        </div>
      );
    }
  }

  private getUrlParams(): {
    configuratorId?: number;
    modelId?: number;
    sessionId?: number;
    seriesId?: number;
  } {
    // TEMP - need to figure out way to grab these from App_v1 and App_v2 without wrecking code-splitting
    const allPaths = [
      '/:configuratorId/series/:seriesId?',
      '/models/:modelId',
      '/:configuratorId/session/:sessionId',
      '/print/models/:modelId/:sessionId',
      '/print/overview/:sessionId',
      '/print/test/:modelId/:sessionId'
    ];

    const match = matchPath<UrlStringParameters>(window.location.pathname, {
      path: allPaths,
      exact: true,
      strict: false
    });

    // models can be used in configurators (at least right now) that are in a different
    // project so need to check the configurator id in the query params as well
    const urlSearchParams = new URLSearchParams(window.location.search);

    return {
      configuratorId: +match?.params.configuratorId || +urlSearchParams?.get('configuratorId'),
      modelId: +match?.params.modelId || 0,
      sessionId: +match?.params.sessionId || +urlSearchParams?.get('sessionId'),
      seriesId: +match?.params.seriesId || 0
    };
  }
}

export default App;
