import React from 'react';

import OverlayService, { OverlayOptions, OverlayType } from '../../services/OverlayService';
import { PushToGoogleTagManager } from '../../utility/google-tag-manager';
import StandardModal from './StandardModal';

type GlobalConfirmModalProps = {};
type GlobalConfirmModalState = { isShowing: boolean; customContent: JSX.Element };

class GlobalConfirmModal extends React.Component<GlobalConfirmModalProps, GlobalConfirmModalState> {
  state: GlobalConfirmModalState = {
    isShowing: false,
    customContent: null
  };
  resolveFn: (value?: boolean | PromiseLike<boolean>) => void;

  componentDidMount() {
    OverlayService.Subscribe('ConfirmModal', this.handleOverlayTrigger);
  }

  componentWillUnmount() {
    OverlayService.Unsubscribe('ConfirmModal');
  }

  handleOverlayTrigger = (
    options: OverlayOptions,
    resolveFn: (value?: boolean | PromiseLike<boolean>) => void
  ) => {
    if (options.Type === OverlayType.ConfirmModal) {
      this.resolveFn = resolveFn;
      this.setState({ isShowing: options.IsShowing, customContent: options.Content });

      PushToGoogleTagManager('ConfirmModalTriggered', {});
    }
  };

  handleNo = () => {
    this.resolveFn(false);
    this.setState({ isShowing: false });
    PushToGoogleTagManager('ConfirmModalCanceled', {});
  };

  handleYes = () => {
    this.resolveFn(true);
    this.setState({ isShowing: false });
    PushToGoogleTagManager('ConfirmModalYes', {});
  };

  render() {
    const { isShowing, customContent } = this.state;

    return isShowing ? (
      <StandardModal onClose={this.handleNo} extraClassNames="global-confirm-modal">
        {customContent}
        <div className="btn-group">
          <button className="outline" onClick={this.handleNo}>
            <label>Cancel</label>
          </button>
          <button className="primary" onClick={this.handleYes}>
            <label>Yes</label>
          </button>
        </div>
      </StandardModal>
    ) : null;
  }
}
export default GlobalConfirmModal;
