import flatMap from 'array.prototype.flatmap';

import {
  Category,
  Component,
  ComponentCombination,
  Configurator,
  Model,
  ModelSupplement,
  Part
} from '../definitions/configurator.types';
import { ClientIds } from '../definitions/enums';
import {
  SessionAccessory,
  SessionComponent,
  SessionModelInstance
} from '../definitions/view-models';
import ApiGateway from './ApiGateway';
import MeasurementService from './MeasurementService';
import SettingsService from './SettingsService';

import { IAccessory } from '@ml/common';

export default class ModelUtilityService {
  static BaseProductDataUrl = process.env.REACT_APP_PRODUCT_DATA_BASE_URL;

  static GetAllComponents(model: Model): Component[] {
    const allComponents: Component[] = [];

    model.Categories.forEach((category: Category) => {
      if (category.ChildCategories && category.ChildCategories.length) {
        allComponents.push(...flatMap(category.ChildCategories, col => col.Components));
      } else {
        allComponents.push(...category.Components);
      }
    });

    return allComponents;
  }

  static GetAllAccessories(model: Model): IAccessory[] {
    return model.AccessoryCategories.flatMap(acc => acc.Accessories);
  }

  static GetAccessoryByName(name: string, model: Model): IAccessory {
    return this.GetAllAccessories(model).find(a => a.Name === name);
  }

  static GetCategoryNamesMap(model: Model): Map<number, string> {
    const categoryMap = new Map();
    model.Categories.forEach(category => {
      categoryMap.set(category.CategoryId, category.Name);
      category.ChildCategories.forEach(child => categoryMap.set(child.CategoryId, child.Name));
    });
    return categoryMap;
  }

  static GetAccessoryCategoryNamesMap(model: Model): Map<number, string> {
    const categoryMap = new Map();
    model.AccessoryCategories.forEach(category => {
      categoryMap.set(category.AccessoryCategoryId, category.Name);
    });
    return categoryMap;
  }

  static GetAllCategories(model: Model): Category[] {
    const categories: Category[] = [];
    model?.Categories.forEach(category => {
      categories.push(category);
      category.ChildCategories.forEach(child => categories.push(child));
    });
    return categories;
  }

  static GetCategoryById(model: Model, categoryId: number) {
    return this.GetAllCategories(model).find(x => x.CategoryId === categoryId);
  }

  static GetCategoryByName(model: Model, categoryName: string) {
    return this.GetAllCategories(model).find(x => x.Name === categoryName);
  }

  static GetCategoryForComponent(model: Model, componentId: number) {
    return this.GetAllCategories(model).find(x =>
      x.Components.some(c => c.ComponentId === componentId)
    );
  }

  static GetComponentsByIds(ids: number[], model: Model): Component[] {
    if (!model) return [];
    return this.GetAllComponents(model).filter(comp => ids.some(id => id === comp.ComponentId));
  }

  static GetComponentById(id: number, model: Model): Component {
    const componentIds = [id];
    return this.GetComponentsByIds(componentIds, model)[0];
  }

  static GetComponentByName(name: string, model: Model): Component {
    return this.GetAllComponents(model).find(comp => comp.Name === name);
  }

  static GetComponentNamesCommaSeparated(model: Model, componentIds: number[]): string {
    const allComponents = this.GetComponentsByIds(componentIds, model);

    return allComponents
      .filter(comp => comp.Name.toUpperCase() !== 'NONE')
      .map(x => x.Name)
      .sort((a, b) => (a < b ? -1 : 1))
      .join(', ');
  }

  static GetFullPathToGltf(model: Model): string {
    if (model.GltfFilename) {
      const pathToModel = this.GetPathToModelDirectory(model);
      const fullUrlToGltf = `${pathToModel}/${model.GltfFilename}`;

      return fullUrlToGltf;
    } else return '';
  }

  static GetFullPathToImage(model: Model, preferComboPreviewImage = false): string {
    const pathToModel = this.GetPathToModelDirectory(model);

    let image = model.ImageFilename;
    if (!image || preferComboPreviewImage) {
      const combo = this.GetFeaturedCombination(model);
      if (combo?.PreviewFilename) image = combo.PreviewFilename;
    }
    if (!image) return '';

    const fullUrlToImage = `${pathToModel}/${image}`;

    return fullUrlToImage;
  }

  static GetPathToModelDirectory(model: Model): string {
    if (model.ClientId && model.ClientName && model.Name) {
      const pathToModel = `/${model.ClientName}_${model.ClientId}/Configurator_Resources/Models/${model.Name}_${model.ModelId}`;
      const fullUrlToModelDirectory = SettingsService.IsOffline()
        ? `${window.location.protocol}//${window.location.host}/productdata${pathToModel}`
        : this.BaseProductDataUrl + pathToModel;

      return fullUrlToModelDirectory;
    } else return '';
  }

  static GetFullPathToSupplementGltf(supplement: ModelSupplement): string {
    if (supplement.GltfFilename) {
      const pathToModel = this.GetPathToSupplementDirectory(supplement);
      const fullUrlToGltf = `${pathToModel}/${supplement.GltfFilename}`;

      return fullUrlToGltf;
    } else return '';
  }

  static GetPathToSupplementDirectory(supplement: ModelSupplement): string {
    if (supplement.ClientId && supplement.ClientName && supplement.Name) {
      // eslint-disable-next-line max-len
      const path = `/${supplement.ClientName}_${supplement.ClientId}/Configurator_Resources/ModelSupplements/${supplement.Name}_${supplement.ModelSupplementId}`;
      const fullUrlToDirectory = SettingsService.IsOffline()
        ? `${window.location.protocol}//${window.location.host}/productdata${path}`
        : this.BaseProductDataUrl + path;

      return fullUrlToDirectory;
    } else return '';
  }

  static GetPathToConfiguratorDirectory(configurator: Configurator): string {
    if (configurator.ClientName && configurator.ClientId) {
      return (
        this.BaseProductDataUrl +
        // eslint-disable-next-line max-len
        `/${configurator.ClientName}_${configurator.ClientId}/Configurator_Resources/Configurators/${configurator.Name}_${configurator.ConfiguratorId}/`
      );
    } else {
      return '';
    }
  }

  static DoesCategoryHaveNoneComponent(model: Model, categoryId: number) {
    const category = this.GetCategoryById(model, categoryId);
    return category.Components.some(x => !x.Parts.length);
  }

  static GetParts(components: Component[]): Part[] {
    return flatMap(components, (comp: Component) => {
      return comp.Parts.map(x => x);
    });
  }

  static GetNodes(parts: Part[]): string[] {
    return flatMap(parts, (p: Part) => {
      return p.Nodes;
    });
  }

  static AreBaseAndFeaturedCombinationsDifferent(model: Model): boolean {
    const base = this.GetBaseCombination(model);
    const featured = this.GetFeaturedCombination(model);

    if (!base || !featured) return false;

    return base.ComponentCombinationId !== featured.ComponentCombinationId;
  }

  static GetBaseCombination(model: Model): ComponentCombination | undefined {
    if (model && model.ComponentCombinations)
      return model.ComponentCombinations.find(x => x.IsBase);

    return undefined;
  }

  static GetFeaturedCombination(model: Model): ComponentCombination | undefined {
    if (model && model.ComponentCombinations)
      return model.ComponentCombinations.find(x => x.IsDefault) || this.GetBaseCombination(model);

    return undefined;
  }

  static GetComponentsCost(
    model: Model,
    componentIds: number[],
    sessionComponents?: SessionComponent[]
  ): number {
    const variablePriceComponentId = this.GetSelectedComponentIdFromVariablePricingCategory(
      model,
      componentIds
    );

    return this.GetComponentsByIds(componentIds, model)
      .map(x => {
        const sessionComp = sessionComponents?.find(c => c.ComponentId === x.ComponentId);
        return this.InferComponentCost(sessionComp, x, variablePriceComponentId);
      })
      .filter(Boolean)
      .reduce((sum, curr) => sum + curr, 0);
  }

  static InferComponentCost(
    sessionComp: SessionComponent,
    component: Component,
    variablePriceComponentId: number
  ) {
    if (sessionComp?.Cost) return sessionComp.Cost;

    const variablePrice =
      component.CustomData.CostWhenComponentSelected?.[variablePriceComponentId];
    if (variablePrice && typeof +variablePrice === 'number') return +variablePrice;

    if (typeof component.Cost === 'number') return component.Cost;

    // return null not 0 so that we can distinguish between
    // a component that has no cost and a component that has a cost of 0
    return null;
  }

  static GetSelectedComponentIdFromVariablePricingCategory(
    model: Model,
    selectedComponentIds: number[]
  ) {
    const variablePriceCategory = model.Categories.find(
      c => c.CustomData?.IsVariablePriceBaseCategory
    );
    if (variablePriceCategory && !model.VariablePricingHasBeenAppliedUpfront) {
      const componentIdsFromVarCat = variablePriceCategory.Components.map(x => x.ComponentId);
      return selectedComponentIds.find(id => componentIdsFromVarCat.includes(id));
    }

    return 0;
  }

  // TODO - this function ignores sessionComponent.Cost which is for the variable pricing feature
  // but variable pricing is for FGI and this method is for Kohler
  static GetComponentsOriginalPriceTotalDiff(model: Model, componentIds: number[]): number {
    return this.GetComponentsByIds(componentIds, model)
      .filter(x => !!x.CustomData.OriginalPrice && !!x.Cost && x.Cost < x.CustomData.OriginalPrice)
      .map(x => x.CustomData.OriginalPrice - x.Cost)
      .reduce((sum, curr) => sum + curr, 0);
  }

  static GetTotalCost(model: Model, componentIds: number[]): number {
    let total = 0;

    const baseCombo = this.GetBaseCombination(model);
    if (baseCombo && baseCombo.Cost) total += baseCombo.Cost;

    total += this.GetComponentsCost(model, componentIds);

    // TODO - should this include accessories?

    return total;
  }

  static GetModelLabelBySession(model: Model, mi: SessionModelInstance) {
    if (!mi) return;
    const selectedComponentIds = mi.Components.map(x => x.ComponentId);

    return this.GetModelLabel(
      model,
      selectedComponentIds,
      mi.Accessories,
      !!mi.CustomOptionRequests.length
    );
  }

  static GetModelLabel(
    model: Model,
    selectedComponentIds: number[],
    sessionAccessories: SessionAccessory[],
    hasCustomOptions = false
  ): string {
    let modelName = model.PublicName || model.Name;

    if (model.ClientName === 'Sitmatic') {
      modelName = model.Sku || '';
      const selectedAccessoryIds = sessionAccessories.map(x => x.AccessoryId);
      let mech: string = '',
        allElse: string = '',
        armRest: string = '',
        armPad: string = '',
        fabric: string = '',
        acc: string = '';

      const categories = ModelUtilityService.GetAllCategories(model);

      const allComponents = ModelUtilityService.GetAllComponents(model);
      selectedComponentIds.forEach(selectedId => {
        const selectedComponent = allComponents.find(c => c.ComponentId === selectedId);

        if (selectedComponent && selectedComponent.Suffix) {
          const category = categories.find(x => x.CategoryId === selectedComponent.CategoryId);
          let catName = '';
          if (category && category.ParentCategoryId) {
            const foundCat = categories.find(x => x.CategoryId === category.ParentCategoryId);
            if (foundCat) catName = foundCat.Name;
          } else if (category) {
            catName = category.Name;
          }
          const regexMech = new RegExp('mech', 'i');
          const regexArms = new RegExp('arms', 'i');
          const regexPads = new RegExp('pads', 'i');
          const regexFabric = new RegExp('textiles', 'i');

          if (catName) {
            switch (true) {
              case regexMech.test(catName):
                mech += selectedComponent.Suffix;
                break;
              case regexArms.test(catName):
                armRest += selectedComponent.Suffix;
                break;
              case regexPads.test(catName):
                armPad += selectedComponent.Suffix;
                break;
              case regexFabric.test(catName):
                fabric += selectedComponent.Suffix;
                break;
              default:
                allElse += selectedComponent.Suffix;
            }
          }
        }
      });
      const accessories = ModelUtilityService.GetAllAccessories(model);
      selectedAccessoryIds.forEach(selectedAcc => {
        const suffix = accessories.find(x => x.AccessoryId === selectedAcc)?.CustomData?.Suffix;
        if (suffix) acc += suffix;
      });

      if (hasCustomOptions) allElse += 'ZZ';

      modelName += mech + allElse + acc + armRest + armPad + fabric;
    }
    return modelName;
  }

  static GetModelSku(model: Model, modelInstance: SessionModelInstance): string {
    const selectedComponentIds = modelInstance.Components.map(c => c.ComponentId);
    let modelSku = model.Sku || '';

    // TODO - can remove ML
    if (model.ClientId === ClientIds.FGI || model.ClientId === ClientIds.MediaLab) {
      let size: string = '',
        glass: string = '',
        finish: string = '',
        halfInchGlass: string = '';

      const categories = ModelUtilityService.GetAllCategories(model);

      const glassCategory = MeasurementService.GetGlassCategory(model);
      const halfInchGlassComponentId =
        MeasurementService.GetHalfInchGlassComponent(glassCategory)?.ComponentId;
      const halfInchGlassComponentIsSelected =
        selectedComponentIds.includes(halfInchGlassComponentId);
      const sizeCategory = MeasurementService.GetSizeCategory(model);
      const hasXSizeOption = sizeCategory?.Components.some(c => c.Name === 'X');

      const allComponents = ModelUtilityService.GetAllComponents(model);
      selectedComponentIds.forEach(selectedId => {
        const selectedComponent = allComponents.find(c => c.ComponentId === selectedId);

        if (selectedComponent && (selectedComponent.Suffix || selectedComponent.Sku)) {
          const category = categories.find(x => x.CategoryId === selectedComponent.CategoryId);
          let catName = '';
          if (category && category.ParentCategoryId) {
            const foundCat = categories.find(x => x.CategoryId === category.ParentCategoryId);
            if (foundCat) catName = foundCat.Name;
          } else if (category) {
            catName = category.Name;
          }
          const regexSize = new RegExp('size', 'i');
          const regexGlass = new RegExp('glass', 'i');
          const regexFinish = new RegExp('finish', 'i');

          if (catName) {
            switch (true) {
              case regexSize.test(catName):
                size = selectedComponent.Suffix || selectedComponent.Sku;
                // special rule - if X is an available size and 1/2" glass is selected, SKU should have X for size
                if (hasXSizeOption && halfInchGlassComponentIsSelected) size = 'X-';
                break;
              case regexGlass.test(catName):
                glass = selectedComponent.Suffix || selectedComponent.Sku;
                // special rule - 1/2" glass suffix is split from 'CL-05-' so that the CL is before finish and -05 is at the end
                if (halfInchGlassComponentIsSelected) {
                  const parts = glass.split('-');
                  if (parts.length <= 1) break;
                  glass = `${parts[0]}-`;
                  halfInchGlass = `-${parts[1]}`;
                }
                break;
              case regexFinish.test(catName):
                finish = selectedComponent.Suffix || selectedComponent.Sku;
                break;
            }
          }
        }
      });

      modelSku += size + glass + finish + halfInchGlass;
    }

    return modelSku;
  }

  static ApplyPricingAdjustments(model: Model): Model {
    model = ModelUtilityService.ApplyPricingMultipler(model);
    model = ModelUtilityService.ApplyShippingAndHandlingPricing(model);
    return model;
  }

  static ApplyShippingAndHandlingPricing(model: Model): Model {
    const shippingAndHandling =
      model.ClientId === ClientIds.FGI ? SettingsService.Get('ShippingAndHandlingFee') : null;
    if (!shippingAndHandling) return model;

    const updatedModel = { ...model };
    const sizeCategory = MeasurementService.GetSizeCategory(updatedModel);
    sizeCategory?.Components.forEach(comp => {
      comp.Cost += shippingAndHandling;
    });

    return updatedModel;
  }

  static ApplyPricingMultipler(model: Model): Model {
    const pricingMultiplier = +SettingsService.Get('PricingMultiplier');
    if (!pricingMultiplier) return model;

    const updatedModel = { ...model };
    updatedModel.Categories.forEach(cat => {
      cat.ChildCategories.forEach(ccat => {
        ccat.Components.forEach(comp => {
          if (comp.Cost !== null) comp.Cost *= pricingMultiplier;

          if (comp.CustomData?.CostWhenComponentSelected) {
            for (const key of Object.keys(comp.CustomData.CostWhenComponentSelected))
              if (comp.CustomData.CostWhenComponentSelected[key])
                comp.CustomData.CostWhenComponentSelected[key] =
                  comp.CustomData.CostWhenComponentSelected[key] * pricingMultiplier;
          }
        });
      });

      cat.Components.forEach(comp => {
        if (comp.Cost !== null) comp.Cost *= pricingMultiplier;

        if (comp.CustomData?.CostWhenComponentSelected) {
          for (const key of Object.keys(comp.CustomData.CostWhenComponentSelected))
            if (comp.CustomData.CostWhenComponentSelected[key])
              comp.CustomData.CostWhenComponentSelected[key] =
                comp.CustomData.CostWhenComponentSelected[key] * pricingMultiplier;
        }
      });
    });

    updatedModel.AccessoryCategories.forEach(cat => {
      cat.Accessories.forEach(a => {
        if (a.Cost !== null) a.Cost *= pricingMultiplier;
      });
    });
    return updatedModel;
  }

  // if using a variable price category, update costs based on selected component
  static async ApplyVariablePricing(model: Model, selectedComponentIds: number[]): Promise<Model> {
    const variablePriceCategory = model.Categories.find(
      c => c.CustomData?.IsVariablePriceBaseCategory
    );
    if (!variablePriceCategory) return model;

    const selectedComponentId = selectedComponentIds.find(id =>
      variablePriceCategory.Components.map(c => c.ComponentId).includes(id)
    );

    // get fresh copy of model with original component costs since they may have been modified
    const originalModel = await ApiGateway.GetModelById(model.ModelId);
    const tmpModel = structuredClone(originalModel) as Model;
    tmpModel.Categories.forEach(cat => {
      cat.ChildCategories.forEach(ccat => {
        ccat.Components.forEach(comp => {
          if (
            comp.CustomData?.CostWhenComponentSelected &&
            comp.CustomData?.CostWhenComponentSelected[selectedComponentId]
          ) {
            comp.Cost = +comp.CustomData?.CostWhenComponentSelected[selectedComponentId];
          }
        });
      });

      cat.Components.forEach(comp => {
        if (
          comp.CustomData?.CostWhenComponentSelected &&
          comp.CustomData?.CostWhenComponentSelected[selectedComponentId]
        ) {
          comp.Cost = +comp.CustomData?.CostWhenComponentSelected[selectedComponentId];
        }
      });
    });

    model.Categories = tmpModel.Categories;

    model.VariablePricingHasBeenAppliedUpfront = true;
    return model;
  }
}
