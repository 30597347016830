import { ConfiguratorSession, SessionModelInstance } from '../definitions/view-models';
import ApiGateway from './ApiGateway';

class SessionService {
  private localStorageKey = 'configurator-session';
  private allowedAccessToSessionStorage = false;

  // TODO: remove this property as the only source should now be from ReducerState
  private session: ConfiguratorSession;

  constructor() {
    try {
      if (window.sessionStorage) this.allowedAccessToSessionStorage = true;
    } catch {
      console.warn('Not allowed access to window.sessionStorage');
      this.allowedAccessToSessionStorage = false;
    }
  }

  async Get(sessionId?: number): Promise<ConfiguratorSession> {
    let session = this.session || this.GetFromLocalStorage();

    if (!sessionId) return session;

    if (!session || session.ConfiguratorSessionId !== sessionId) {
      session = await ApiGateway.GetSessionById(sessionId);
    }

    this.session = session;
    return this.session;
  }

  async Save(session?: ConfiguratorSession): Promise<ConfiguratorSession> {
    if (session) this.session = new ConfiguratorSession(session);
    const updatedSession = await ApiGateway.SaveSession(this.session);
    this.session = updatedSession;
    this.SaveToLocalStorage(updatedSession);

    return updatedSession;
  }

  SetCurrent(session: ConfiguratorSession) {
    this.session = session;
    this.SaveToLocalStorage(session);
  }

  GetCurrentSessionId(): number {
    return this.session ? this.session.ConfiguratorId : 0;
  }

  HasSessionSavedToApi(): boolean {
    return !!this.session?.ConfiguratorSessionId;
  }

  ClearCurrent() {
    this.session = new ConfiguratorSession();
    this.SaveToLocalStorage(null);
  }

  Delete(id: number) {
    return ApiGateway.DeleteSession(id);
  }

  DeleteModelInstanceById(id: string) {
    const session = this.session;
    const i = session.Content.ModelInstances.findIndex(x => x.ModelInstanceId === id);
    if (i > -1) session.Content.ModelInstances.splice(i, 1);
  }

  AddOrUpdateModelInstance(sessionModel: SessionModelInstance): SessionModelInstance[] {
    const session = this.session;

    const i = session.Content.ModelInstances.findIndex(
      x => x.ModelInstanceId === sessionModel.ModelInstanceId
    );
    if (i > -1) session.Content.ModelInstances[i] = sessionModel;
    else session.Content.ModelInstances.unshift(sessionModel);

    return session.Content.ModelInstances;
  }

  private GetFromLocalStorage(): ConfiguratorSession | null {
    if (!this.allowedAccessToSessionStorage) return null;

    let session: ConfiguratorSession | null = null;
    const rawSession = sessionStorage.getItem(this.localStorageKey);
    if (rawSession) session = new ConfiguratorSession(JSON.parse(rawSession));

    return session;
  }

  SaveToLocalStorage(session?: ConfiguratorSession) {
    if (!this.allowedAccessToSessionStorage) return;

    if (!session) session = this.session;
    sessionStorage.setItem(this.localStorageKey, JSON.stringify(session));
    return session;
  }

  async SaveAndStartNewSession(session: ConfiguratorSession) {
    await this.Save(session);
    this.ClearCurrent();
    return this.session;
  }
}

export default new SessionService();
