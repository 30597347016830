/* eslint-disable max-len */
import Axios, { InternalAxiosRequestConfig } from 'axios';

import { Configurator, ModelSupplement } from '../definitions/configurator.types';
import { ConfiguratorSession } from '../definitions/view-models';
import { SortModelsInSeries } from '../utility/helpers';
import AppDataService from './AppDataService';

import { getArgoHeaders, IModel, ISetting, SettingsCategoryTitle } from '@ml/common';
import { OfflineConfig } from '../v2/offline-config';
import SettingsService from './SettingsService';

class ApiGateway {
  private isOffline = SettingsService.IsOffline();
  baseUrl = `${window.location.protocol}//${process.env.REACT_APP_API_DOMAIN}/api`;
  storedConfigurator: Configurator | undefined;
  storedModels = new Map<number, IModel>();

  readonly SkipAuthHeader = 'x-ml-skipauth';

  constructor() {
    this.setupRequestInterceptors();

    if (this.isOffline) {
      this.baseUrl = `${window.location.protocol}//${window.location.host}/api`;
    }
  }

  private setupRequestInterceptors() {
    Axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      if (config.headers[this.SkipAuthHeader]) {
        delete config.headers[this.SkipAuthHeader];
        return config;
      }

      const authHeaders = getArgoHeaders({ url: config.url, method: config.method });
      // Hack because Axios types are wack
      config.headers = { ...config.headers, ...authHeaders } as any;
      return config;
    });
  }

  async GetConfiguratorById(id: number): Promise<Configurator> {
    if (this.storedConfigurator && this.storedConfigurator.ConfiguratorId === id)
      return this.storedConfigurator;

    try {
      let res;

      if (SettingsService.IsOffline()) {
        res = await Axios.get(`${this.baseUrl}/configurators/${id}.json`);
      } else {
        res = await Axios.get(`${this.baseUrl}/configurators/${id}?includeModels=true`);
      }

      this.storedConfigurator = res.data;
      this.storedConfigurator.Series.forEach(s => (s.Models = SortModelsInSeries(s)));
      return res.data;
    } catch (error) {
      throw new Error(`Failed to get configurator ${id} from API: ${error}`);
    }
  }

  async GetModelIdByConfigFile(): Promise<number> {
    let url = `${this.baseUrl}/config.json`;
    const res = await Axios.get<OfflineConfig>(url);
    return res.data.ModelId;
  }

  async GetConfiguratorIdByConfigFile(): Promise<number> {
    let url = `${this.baseUrl}/config.json`;
    const res = await Axios.get<OfflineConfig>(url);
    return res.data.ConfiguratorId;
  }

  async GetModelById(id: number): Promise<IModel> {
    try {
      if (this.storedModels.has(id)) return this.storedModels.get(id);

      let url = `${this.baseUrl}/models/${id}`;
      if (this.isOffline) url += '.json';

      const res = await Axios.get<IModel>(url);

      // This sucks that AppDataService is called here and has a dependency on settings (which might not be loaded yet)
      // Would love to remove this but too many places rely on GetModelById and it is a regression nightmare
      const model = AppDataService.prepareModel(res.data);

      this.storedModels.set(id, model);

      // HACK :(
      // many places need to pull model data out of configurator.Series
      if (this.storedConfigurator) {
        this.storedConfigurator.Series.forEach(s => {
          const i = s.Models.findIndex(x => x.ModelId === model.ModelId);
          if (i > -1) s.Models[i] = model;
        });
      }

      return model;
    } catch (error) {
      throw new Error(`Failed to get model ${id} from API: ${error}`);
    }
  }

  async GetModelByName(clientName: string, projectName: string, modelName): Promise<IModel> {
    try {
      const res = await Axios.get<IModel>(
        `${this.baseUrl}/models/name/${modelName}?clientName=${clientName}&projectName=${projectName}`
      );

      // This sucks that AppDataService is called here and has a dependency on settings (which might not be loaded yet)
      // Would love to remove this but too many places rely on GetModelById and it is a regression nightmare
      const model = AppDataService.prepareModel(res.data);

      // HACK :(
      // many places need to pull model data out of configurator.Series
      if (this.storedConfigurator) {
        this.storedConfigurator.Series.forEach(s => {
          const i = s.Models.findIndex(x => x.ModelId === model.ModelId);
          if (i > -1) s.Models[i] = model;
        });
      }

      return model;
    } catch (error) {
      throw new Error(`Failed to get model by name from API: ${error}`);
    }
  }

  async Get<T>(path: string): Promise<T> {
    if (!path.startsWith('/')) path = '/' + path;
    if (path.startsWith('/api')) path = path.slice(4);

    try {
      const res = await Axios.get<T>(`${this.baseUrl}${path}`);
      return res.data;
    } catch (error) {
      throw new Error(error as any);
    }
  }

  async Post(path: string, payload: any): Promise<any> {
    if (!path.startsWith('/')) path = '/' + path;
    if (path.startsWith('/api')) path = path.slice(4);

    try {
      const res = await Axios.post(`${this.baseUrl}${path}`, payload);
      return res.data;
    } catch (error) {
      throw new Error(error as any);
    }
  }

  async GetModelSupplementById(id: number): Promise<ModelSupplement> {
    try {
      let url = `${this.baseUrl}/modelsupplements/${id}`;
      if (this.isOffline) url += '.json';

      const res = await Axios.get(url);
      return res.data;
    } catch (error) {
      throw new Error(`Failed to get model supplement ${id} from API: ${error}`);
    }
  }

  async GetSessionById(id: number): Promise<ConfiguratorSession> {
    try {
      const res = await Axios.get(`${this.baseUrl}/configuratorsessions/${id}`);

      const session = new ConfiguratorSession(res.data);
      return session;
    } catch (error) {
      throw new Error(`Failed to get session ${id} from API: ${error}`);
    }
  }

  async SaveSession(session: ConfiguratorSession): Promise<ConfiguratorSession> {
    try {
      session.prepareForSaveToApi();

      let res;
      if (session.ConfiguratorSessionId) {
        res = await Axios.put(
          `${this.baseUrl}/configuratorsessions/${session.ConfiguratorSessionId}`,
          session
        );
      } else {
        res = await Axios.post(`${this.baseUrl}/configuratorsessions`, session);
      }

      const updatedSession = new ConfiguratorSession(res.data);
      return updatedSession;
    } catch (error) {
      throw new Error(`Failed to save session to API: ${error}`);
    }
  }

  async DeleteSession(id: number): Promise<void> {
    try {
      await Axios.delete(`${this.baseUrl}/configuratorsessions/${id}`);
    } catch (error) {
      throw new Error(`Failed to delete session ${id} from API: ${error}`);
    }
  }

  async GetSettingsByConfiguratorOrModelId(
    configuratorId?: number,
    modelId?: number
  ): Promise<ISetting[][]> {
    try {
      const convergeAppId = '42E6BCB5-7E2F-4D0D-B822-1CB9AE1D839C';
      const idType = configuratorId ? 'configurator' : 'model';
      const path = `settings/application/${convergeAppId}/rootcategoryname/${
        SettingsCategoryTitle.ConfiguratorApp
      }/${idType}/${configuratorId || modelId}`;
      const res = await Axios.get<ISetting[][]>(`${this.baseUrl}/${path}`);

      const settingsCategories = res.data;
      return settingsCategories;
    } catch (error) {
      throw new Error(`Failed to get settings from API: ${error}`);
    }
  }

  async SaveGltfForAR(filename: string, blob: Blob) {
    const formData = new FormData();
    formData.append(filename, blob, filename);
    return this.Post('/models/ar-export', formData).then((result: string) =>
      result.startsWith('/') ? `${this.baseUrl}${result}` : result
    );
  }

  async MakePassThruRequest<T>(url: string): Promise<T> {
    return this.Get(`/api/utility/pass-thru?url=${url}`);
  }

  async GetWithNoAuth<T>(url: string): Promise<T> {
    try {
      const res = await Axios.get(url, { headers: { [this.SkipAuthHeader]: 'true' } });
      return res.data;
    } catch (error) {
      throw new Error(`Failed to get url with no auth: ${error}`);
    }
  }

  async PostWithNoAuth(url: string, data?: any): Promise<any> {
    try {
      const res = await Axios.post(url, data, { headers: { [this.SkipAuthHeader]: 'true' } });
      return res.data;
    } catch (error) {
      throw new Error(`Failed to post url with no auth: ${error}`);
    }
  }
}

export default new ApiGateway();
